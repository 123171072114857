import {Column, Row} from "@components/Row";
import BulletPoints from "@components/BulletPoints";
import Center from "@components/Center";
import CompareSlider from "@components/CompareSlider";
import Container from "@components/Container";
import H1 from "@components/H1";
import LinkButton from "@components/LinkButton";
import React from "react";
import Reference from "@components/Reference";
import SectionHeader from "@components/SectionHeader";
import Spacer from "@components/Spacer";
import cmpLogo from "@images/cpm-logo.png";
import figure1 from "@images/Rheology.png";
import handle from "@images/cpm-treatment-comparison-handle.png";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";

import stage1 from "@images/cpm_section1_01.jpg";
import stage2 from "@images/cpm_section1_02.jpg";
import stage3 from "@images/cpm_section1_03.jpg";
import stage4 from "@images/cpm_section1_04.jpg";
import stage5 from "@images/cpm_section1_05.jpg";

import section2_stage1 from "@images/cpm_section2_01.jpg";
import section2_stage2 from "@images/cpm_section2_02.jpg";
import section2_stage3 from "@images/cpm_section2_03.jpg";
import section2_stage4 from "@images/cpm_section2_04.jpg";


const CpmImage = styled.img`
  //

  ${({theme}) => theme.breakpoints.xsMax} {
    margin-bottom: 2rem;
  }
`;

const H2 = styled.h2`
  margin-top: 0;
`;

const FigureImage = styled.img`
  margin: 0 auto;
`;

const BulletPointsWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const StageImgGrid = styled.div`
  display: flex;
  gap: 20px;
  row-gap: 35px;
  flex-wrap: wrap;
  justify-content: center;
`;

const StageImgGridItem = styled.div`
  flex: 0 0 calc(33.33% - 20px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    flex: 0 0 calc(50% - 20px);
  }

  @media screen and (max-width: 500px) {
    flex: 0 0 calc(100% - 20px);
  }
`;

const StageImgGridRow2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const StageImage = styled.img`
  width: 100%;
`;

const StageTitle = styled.h3`
  color: rgb(255, 121, 0);
  text-align: left;
  margin: 10px 0;
`;

const StageDescription = styled.div`
  text-align: left;
`;

const stageImageItemList = [
    {
        image: stage1,
        title: "階段一",
        description: "起始未進行交聯時的透明質酸分子鏈(單鏈、隨意的線圈結構)",
    },
    {
        image: stage2,
        title: "階段二",
        description: "透明質酸分子鏈被拉直, 隨意的線圈結構逐漸被解開",
    },
    {
        image: stage3,
        title: "階段三",
        description: "透明質酸分子鏈與BDDE(Butanediol Diglycidyl Ether 交叉鏈接劑)進行第一次交聯作用,產生網狀單相凝膠",
    },
    {
        image: stage4,
        title: "階段四",
        description: "凝膠在交聯過程中被擴張",
    },
    {
        image: stage5,
        title: "階段五",
        description: "再次加入透明質酸分子鏈會與剩餘的BDDE作第二次的交聯,凝膠在多密度凝聚技術(CPM)下產生",
    },
];

const stage2ImageItemList = [
    {
        image: section2_stage1,
        title: "相對照",
        description: "",
    },
    {
        image: section2_stage2,
        title: "凝聚性凝膠",
        description: "單相多密度",
    },
    {
        image: section2_stage3,
        title: "支離性凝膠",
        description: "單相單一密度",
    },
    {
        image: section2_stage4,
        title: "非凝聚性凝膠",
        description: "雙相",
    },
]

const Ref = styled(Reference)`
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const Stage2Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Stage2Item = styled.div`
  flex: 0 0 calc(25% - 20px);

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    flex: 0 0 calc(50% - 20px);
  }

  @media screen and (max-width: 500px) {
    flex: 0 0 calc(100% - 20px);
  }
`;

const Stage2Highlight = styled.div`
  margin-top: 10px;
  padding: 7px 10px;
  border: 1px #FF7900FF solid;
  
  text-align: left;
  color: #FF7900FF;
`;

export default function Cpm() {
    return (
        <Container $maxWidth="104rem">
            <H1>獨有CPM技術</H1>
            <Row>
                <Column $sm={4}>
                    <CpmImage src={cmpLogo} alt="CPM Logo" {...slideUp} />
                </Column>
                <Column $sm={8}>
                    <H2 {...slideUp}>提供超卓的肌膚融合度<sup>1</sup></H2>
                    <p {...slideUp}>BELOTERO® 透明質酸系列採用獨有CPM技術，令凝聚度、彈性及可塑性達至理想的均衡比例，
                        能無瑕地與肌膚高度融合<sup>^</sup>，提供理想的自然效果。</p>

                    <p {...slideUp}>BELOTERO® 透明質酸系列的優勝之處在於其四大特點（凝聚度、黏稠度、彈性及可塑性）的均衡比例，能與肌膚融合，提供可預測的自然效果。<sup>1-2</sup>
                    </p>
                    <Reference {...slideUp}>1 Tran C, et al. Dermatology. 2014;228(1):47-54</Reference>
                    <Reference {...slideUp}>2 Fischer TC, et al. Facial Plast Surg. 2016 Jun;32(3):283-8.</Reference>
                </Column>
            </Row>

            <FigureImage src={figure1} alt="CPM concept figure 1" {...slideUp} />
            <br/><br/><br/><br/>
            <Center>
                {/*                <SectionHeader {...slideUp}>
                    接受 BELOTERO® <br data-sm-display-none/>透明質酸療程<br data-xs-max-display-none/>前後皮膚比較
                </SectionHeader>*/}
                <H2 {...slideUp}>標準的交聯作用</H2>

                <p {...slideUp}>透過5步動態交聯技術，有助透明質酸與肌膚無縫融合<sup>1</sup>，同時降低透明質酸分散導致表面不均的風險，以滿足不同的治療需求，達到理想的自然效果。
                </p>

                <StageImgGrid>
                    {
                        stageImageItemList.map((item, index) => {
                            if (index <= 2) {
                                return (
                                    <StageImgGridItem  {...slideUp}>
                                        <StageImage src={item.image} alt=""/>
                                        <StageTitle>{item.title}</StageTitle>
                                        <StageDescription>{item.description}</StageDescription>
                                    </StageImgGridItem>
                                );
                            } else {
                                return <></>;
                            }
                        })
                    }
                </StageImgGrid>

                <Spacer $xsMax="2rem" $sm="4rem"/>
                <H2 {...slideUp}>動態的交聯技術<sup>3</sup></H2>
                <StageImgGridRow2>
                    {
                        stageImageItemList.map((item, index) => {
                            if (index > 2) {
                                return (
                                    <StageImgGridItem  {...slideUp}>
                                        <StageImage src={item.image} alt=""/>
                                        <StageTitle>{item.title}</StageTitle>
                                        <StageDescription>{item.description}</StageDescription>
                                    </StageImgGridItem>
                                );
                            } else {
                                return <></>;
                            }
                        })
                    }
                </StageImgGridRow2>

                <Spacer $xsMax="2rem" $sm="4rem"/>
                <br/>
                <H2 {...slideUp}>BELOTERO® 運用多密度凝聚技術(CPM),確保達到無瑕的肌膚融合</H2>
                <Stage2Container>
                    {
                        stage2ImageItemList.map((item, index) => {
                            return (
                                <Stage2Item {...slideUp}>
                                    <StageImage src={item.image} alt=""/>
                                    <StageTitle>{item.title}</StageTitle>
                                    <StageDescription>{item.description}</StageDescription>
                                    {
                                        index === 3 ?
                                            <Stage2Highlight>無暇融合到肌膚組織<sup>1</sup></Stage2Highlight>
                                            :
                                            null
                                    }
                                </Stage2Item>
                            )
                        })
                    }
                </Stage2Container>

            </Center>
            <Spacer $xsMax="2rem" $sm="4rem"/>


            <Spacer $all="2rem"/>
            <Center>
                <LinkButton to={routes.safety} {...slideUp}>了解BELOTERO®透明質酸的療程效果</LinkButton>
                <br/><br/>
                <Ref {...slideUp}>{
                    `1 Tran C, et al. Dermatology. 2014;228(1):47-54
2 Fischer TC, et al. Facial Plast Surg. 2016 Jun;32(3):283-8.
3 Patent family WO 2005/085329.`}
                </Ref>
            </Center>
        </Container>
    );
}
