import Container from "@components/Container";
import H1Component from "@components/H1";
import ProductInteractive from "./ProductInteractive";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Benefits from "./Benefits";

import banner from "@images/acid-hero.png";
import BannerTable from "./BannerTable";

import Center from "@components/Center";
import SectionHeader from "@components/SectionHeader";
import LinkButton from "@components/LinkButton";
import routes from "@config/routes";
import {slideUp} from "@config/sal";

const H1 = styled(H1Component)`
  position: static !important;
  color: black !important;
  
  margin-bottom: 4rem;

  ${({theme}) => theme.breakpoints.sm} {
    position: absolute;
    width: 100%;
    z-index: 9;
    color: ${({theme}) => theme.white};
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
`;

const Banner = styled.img`

`;

const MarginTopBottom = styled.div`
  margin: 50px auto;
`;

const AbsoluteSectionHeader = styled(SectionHeader)`
  position: absolute;
  z-index: 10;
  width: 100%;
  
  text-align: center;
  color: white;
`;

export default function BeloteroHyaluronicAcid() {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);
    return (
        <>
            <Container>
                <H1>認識 BELOTERO®無痕透明質酸系列</H1>
                <Container>
                    <Center>
                        <p {...slideUp}>要獲得真正改善肌膚的效果，就必須將透明質酸注射入皮膚的不同層面，才可達至即時、持久及明顯的效果。瑞士製造的BELOTERO®是優質的透明質酸產品系列，採用了獨有的CPM技術，其透明質酸針的優勝之處在於其四大特點 (凝聚度、黏稠度、彈性及可塑性) 的均衡比例，提供了極佳的肌膚融合配方<sup>1</sup>，效果自然持久，同時不影響您本來的天然輪廓。<sup>2#</sup></p>
                        <p {...slideUp}>透過施打BELOTERO® 透明質酸，有效消除法令紋、提升蘋果肌、優化下巴線條及改善輪廓比例等，令整體臉型線條更流暢。全面多元化的 BELOTERO® 產品系列能因應您臉上皮膚的厚度和不同治療部位的填充需要，來迎合肌膚的各種所需，以創新的技術讓您即時重獲年輕緊緻肌。無論何時何地，BELOTERO® 都可以令您呈現自信，時刻展現出最佳狀態。</p>
                        <br/><br/>
                        <LinkButton to={routes.cpm} {...slideUp}>認識BELOTERO® 獨有的CPM技術</LinkButton>
                    </Center>
                </Container>
            </Container>
            <MarginTopBottom>
                <AbsoluteSectionHeader {...slideUp}>發揮零距離魔力 | 淡化歲月痕跡</AbsoluteSectionHeader>
                {mounted ? <ProductInteractive/> : null}
            </MarginTopBottom>

            <FlexBox>
                <Banner src={banner} alt="" {...slideUp}/>
                <BannerTable/>
            </FlexBox>
            <Benefits/>
        </>
    );
}
