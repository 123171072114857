import {Column, Row} from "@components/Row";
import React, {useState} from "react";
import Center from "@components/Center";
import CompareSlider from "@components/CompareSlider";
import Container from "@components/Container";
import H1Component from "@components/H1";
import CountUp from "react-countup";
import BulletPoints from "@components/BulletPoints";
import LinkButton from "@components/LinkButton";
import ModalVideo from "react-modal-video";
import Reference from "@components/Reference";
import ScrollPoint from "@components/ScrollPoint";
import SectionHeader from "@components/SectionHeader";
import awards from "@images/more-awards1.png";
import awards2 from "@images/belotero-revive-awards2.png";
import newHero from "@images/revive-banner-compressed2.jpg";
import reviewHandle from "@images/success-stories-revive-handle.png";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";
import userReview1 from "@images/belotero-revive-user-review-1.png";
import userReview2 from "@images/belotero-revive-user-review-2.png";

import Icon1 from "@images/glow.png";
import Icon2 from "@images/droplet.png";
import newGraph from "@images/revive-graph.png";

import moisImage from "@images/Belotero-Revive-and-HA-Glycerol.jpg";
import case1Before from "@images/case1-before.jpg";
import case1After from "@images/case1-after.jpg";
import case2Before from "@images/case2-before.jpg";
import case2After from "@images/case2-after.jpg";
import case3Before from "@images/case3-before.jpg";
import case3After from "@images/case3-after.jpg";
import case4Before from "@images/case4-before.jpg";
import case4After from "@images/case4-after.jpg";

import stepZeroLogo from "@images/Step-zero-logo.png";
import allAspectProduct from "@images/Revive_cover_exports_clean.jpg";

const H1 = styled(H1Component)`

  ${({theme}) => theme.breakpoints.sm} {
    margin-bottom: 0;
  }
`;

const Section = styled.div`
  background-color: ${({$colored}) => $colored ? "#C0EFEC" : "inherit"};
  padding-top: 1rem;
  padding-bottom: 3rem;
  text-align: center;

  &:before {
    content: '';
    display: block;
  }
`;

const BulletItem = styled.div`
  text-align: left;
`;

const Statistics = {
    Title: styled.div`
      color: #00B7B7;
      font-size: 2.2rem;
      line-height: 1.36;
      font-weight: 700;

      ${({theme}) => theme.breakpoints.sm} {
        font-size: 3.6rem;
      }
    `,
    Content: styled.p``,
};

const Graph = styled.img`
  //
  
  ${({theme}) => theme.breakpoints.xsMax} {
    margin-bottom: 3rem;
  }
`;

const AwardsImage = styled.img`
  margin: 0 auto;
`;

const AwardsImage2 = styled.img`
  margin: 0 auto;

`;

const AdvantageBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  width: fit-content;
  margin: 50px auto;
`;

const AdvantageBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 250px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(82, 168, 160);
  padding: 10px;
`;

const Icon = styled.img`
  width: 80%;
`;

const AdvantageHeader = styled.div`
  color: rgb(96, 99, 103);
  text-align: center;
  font-weight: bolder;
  margin: 15px 0 5px;
`;

const AdvantageDescription = styled.div`
  color: rgb(96, 99, 103);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`;

const UserReview = {
    Item: styled.div`
      display: flex;
      text-align: left;
      align-items: flex-start;
      margin-bottom: 2rem;

      ${({theme}) => theme.breakpoints.xsMax} {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
      }
    `,
    Image: styled.img`
      display: inline-block;
      vertical-align: middle;

      ${({theme}) => theme.breakpoints.xsMax} {
        margin-bottom: 3rem;
      }
    `,
    Text: styled.div`
      position: relative;
      top: 2rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding-left: 3rem;
    `,
    Comment: styled.div`
      position: relative;
      font-size: 2rem;
      line-height: 1.3;
      color: ${({theme}) => theme.black};
      padding-right: 2rem;
      margin-bottom: 2rem;

      &:before, &:after {
        content: '';
        position: absolute;
        width: 2rem;
        height: 6rem;
      }

      &:before {
        top: -2rem;
        left: -2rem;
        border-top: 1px solid ${({theme}) => theme.black};
        border-left: 1px solid ${({theme}) => theme.black};
      }

      &:after {
        bottom: 0;
        right: 0;
        border-bottom: 1px solid ${({theme}) => theme.black};
        border-right: 1px solid ${({theme}) => theme.black};
      }
    `,
    Title: styled.div`
      font-size: 2rem;
      font-weight: 700;
    `,
};

const Note = styled(Reference)`
  text-align: center;
  color: ${({theme}) => theme.gray700};
`;

const AlignLeftNote = styled(Note)`
  text-align: left;
`;

const BannerContainer = styled.img`
  width: 100%;;
`;

const SquareContainer = styled.div`
  margin: 25px auto;
`;


const MarginTop = styled.div`
  margin-top: 50px;

  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;

const HighlightWord = styled.span`
  font-weight: bolder;
  color: #28a39b;
`;

const MoistureImage = styled.img`

`;

const SmallNoteContainer = styled.div`
  margin: 10px 0;
  
  @media (max-width: 576px) {
    margin: 0 0 25px;
  }
`;

const AlignLeftSmallNote = styled.p`
  margin: 0;
  text-align: left;
  font-size: 10px;
  font-weight: bolder;
  color: #28a39b;
`;

const StepZeroLogo = styled.img`
  max-width: 300px;
  margin: 40px auto;
`;

const MyColumn = styled(Column)`
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const MyPaddingColumn = styled(Column)`
  padding: 0 20px;
`;

const MyRow = styled(Row)`

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const MyContainer = styled(Container)`
  @media (max-width: 576px) {
    padding: 0;
  }
`;


const AllAspectProduct = styled.img`
  //max-width: 340px;
  margin: 0 auto;
  
  @media (max-width: 576px) {
    max-width: 90%;
  }
`;

const RowWithGap = styled(Row)`
  gap: 5%;
  justify-content: center;
`;

const AlignCenterRow = styled(Row)`
  align-items: center;
  
  @media (max-width: 576px) {
    gap: 50px;
  }
`;

const Ref = styled(Reference)`
  @media (max-width: 768px) {
    padding: 0 20px;
    text-align: left;
  }
`;

export default function BeloteroHyaluronicAcid() {
    const defaultInitalValue = 50;
    const [position1, setPosition1] = useState(defaultInitalValue);
    const [position2, setPosition2] = useState(defaultInitalValue);
    const [position3, setPosition3] = useState(defaultInitalValue);
    const [position4, setPosition4] = useState(defaultInitalValue);

    const [youtubeId, setYoutubeId] = useState(null);
    return (
        <>
            <H1>BELOTERO® REVIVE 水光針</H1>
            <BannerContainer src={newHero} {...slideUp} alt={"Belotero Revive Banner Image"} />
            <Section $colored>
                <Container>
                    <SectionHeader {...slideUp}>甚麽是BELOTERO® REVIVE？</SectionHeader>
                    <MoistureImage src={moisImage} alt={"Belotero Revive Ingredients"} {...slideUp}></MoistureImage>
                    <p {...slideUp}>BELOTERO® REVIVE 獨有保濕鎖水配方，結合<HighlightWord>透明質酸</HighlightWord>
                        和<HighlightWord>甘油</HighlightWord>，
                        加強肌膚的水潤和健康，讓您看起來更年輕、容光煥發<sup>#2,3</sup>。
                    </p>
                    <p {...slideUp}><HighlightWord>透明質酸</HighlightWord>是天然存在於人體的保濕因子，
                        能夠承載自身重量多達1000倍的水分，為肌膚提供保濕和支撐作用。
                        <HighlightWord>甘油</HighlightWord>是另一種強效保濕成分，有效鎖緊肌膚中的所有水分。
                    </p>
                    <p {...slideUp} style={{letterSpacing: 0}}>
                        <HighlightWord>透明質酸</HighlightWord>和<HighlightWord>甘油</HighlightWord>
                        兩種保濕因子的協同作用有助強效保濕鎖水，塑造持久光澤感<sup>3,4</sup>。
                    </p>
                    <br/>
                </Container>
            </Section>

            <Section>
                <Container>
                    <SectionHeader {...slideUp}>全方位提升膚質</SectionHeader>

                    <p {...slideUp}>BELOTERO® REVIVE 實現理想膚質<sup>1</sup>，讓肌膚煥發透亮光彩，提升您對自然裸肌的自信。</p>

                    <AlignCenterRow>
                        <Column $sm={4}>
                            <AllAspectProduct {...slideUp} src={allAspectProduct} alt="" />
                        </Column>

                        <Column $sm={8}>
                            <Container>
                                <Row>
                                    <Column $sm={6}>
                                        <BulletPoints
                                            items={[
                                                {text: <BulletItem>瞬間深層保濕<sup>5</sup>，長效達9個月<sup>2-4</sup></BulletItem>},
                                                {text: <BulletItem>即時釋放獨有甘油成分，煥發肌膚光澤<sup>#3-5</sup></BulletItem>},
                                                {text: <BulletItem>改善泛紅<sup>3</sup></BulletItem>},
                                            ]}
                                        />
                                    </Column>
                                    <Column $sm={6}>
                                        <BulletPoints
                                            items={[
                                                {text: <BulletItem>均匀膚色<sup>3</sup></BulletItem>},
                                                {text: <BulletItem>撫平粗糙肌膚<sup>2-4</sup></BulletItem>},
                                                {text: <BulletItem>改善疲憊暗啞肌<sup>6</sup></BulletItem>}
                                            ]}
                                        />
                                    </Column>
                                </Row>
                            </Container>
                        </Column>
                    </AlignCenterRow>



                    <MarginTop/>

                    <SectionHeader {...slideUp}>Bare Your Glow<br /> 輕鬆煥發光彩亮澤 塑造自然素顏裸肌</SectionHeader>

                    <div className="responsive-video" {...slideUp}>
                        <iframe
                            width="560" height="315"
                            src="https://www.youtube.com/embed/HhpPJnxuDcs?si=m_bmFZraLD7VEGSJ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </div>

                    <p {...slideUp}>
                        亞太區代言人金所泫作為「最美童星」，大方分享以BELOTERO® Revive 水光針作為護膚首步，有效擺脫瓶瓶罐罐秩的繁瑣程序，
                        節省不少護膚步驟及化妝時間，即使素顏都能輕鬆自在展現水潤自信光采，
                        上妝時自然更貼服精緻，即時實現Bare Your Glow 裸光喚膚的水潤美肌狀態！
                    </p>
                </Container>
            </Section>

            <Section $colored>
                <Container>
                    <SectionHeader {...slideUp}>科研數據認證</SectionHeader>

                    <p {...slideUp}>BELOTERO® REVIVE
                        採用獨有動態交聯技術(DCLT)形成多密度凝聚透明質酸(CPM-HA)，能自然無痕地融合肌膚組織，滲透至肌膚底層。</p>

                    <Row>
                        <MyPaddingColumn $sm={6} $noPadding>
                            <Graph src={newGraph} {...slideUp} alt={"Statistic Graph"} />

                            <SmallNoteContainer {...slideUp}>
                                <AlignLeftSmallNote>+28 weeks after last injection session</AlignLeftSmallNote>
                                <AlignLeftSmallNote>^20 weeks after last injection session</AlignLeftSmallNote>
                                <AlignLeftSmallNote>Individual results may vary.</AlignLeftSmallNote>
                            </SmallNoteContainer>
                        </MyPaddingColumn>
                        <MyColumn $sm={6} $noPadding>
                            <Center>
                                <MyRow $noPadding>
                                    <Column $col={6} $sm={12} $noPadding>
                                        <Statistics.Title {...slideUp}>
                                            「長效
                                            <CountUp start={100} end={9} duration={2}>{({countUpRef, start}) => (
                                                <>
                                                    <ScrollPoint elementType="span" onScrollIn={start}/>
                                                    <span ref={countUpRef}/>
                                                </>
                                            )}</CountUp>
                                            個月<sup>+</sup>」
                                        </Statistics.Title>
                                        <Statistics.Content {...slideUp}>提升肌膚水分，並減少泛紅<sup>3</sup></Statistics.Content>
                                    </Column>
                                    <Column $col={6} $sm={12} $noPadding>
                                        <Statistics.Title {...slideUp}>
                                            「超過
                                            <CountUp end={80} duration={2}>{({countUpRef, start}) => (
                                                <>
                                                    <ScrollPoint elementType="span" onScrollIn={start}/>
                                                    <span ref={countUpRef}/>
                                                </>
                                            )}</CountUp>
                                            %」
                                        </Statistics.Title>
                                        <Statistics.Content {...slideUp}>用家在28星期滿意療程效果<sup>3^</sup></Statistics.Content>
                                    </Column>
                                    <Column $col={6} $sm={12} $noPadding>
                                        <Statistics.Title {...slideUp}>
                                            「
                                            <CountUp end={90} duration={2}>{({countUpRef, start}) => (
                                                <>
                                                    <ScrollPoint elementType="span" onScrollIn={start}/>
                                                    <span ref={countUpRef}/>
                                                </>
                                            )}</CountUp>
                                            %」
                                        </Statistics.Title>
                                        <Statistics.Content {...slideUp}>
                                            用家樂意介紹BELOTERO® REVIVE水光針給朋友<sup>3</sup>
                                        </Statistics.Content>
                                    </Column>
                                    <Column $col={6} $sm={12} $noPadding>
                                        <Statistics.Title {...slideUp}>
                                            「
                                            <CountUp end={100} duration={2}>{({countUpRef, start}) => (
                                                <>
                                                    <ScrollPoint elementType="span" onScrollIn={start}/>
                                                    <span ref={countUpRef}/>
                                                </>
                                            )}</CountUp>
                                            %」
                                        </Statistics.Title>
                                        <Statistics.Content {...slideUp}>用家皮膚得到改善<sup>3</sup></Statistics.Content>
                                    </Column>
                                </MyRow>
                            </Center>
                        </MyColumn>
                    </Row>
                </Container>
            </Section>

            <Section>
                <Container>
                    <SectionHeader {...slideUp}>BELOTERO® REVIVE水光針療程效果</SectionHeader>

                    <MyContainer $maxWidth="80rem">
                        <Row {...slideUp}>
                            <Column $sm={6} $widthInColumn={"100%"}>
                                <SquareContainer>
                                    <CompareSlider
                                        left={case1Before}
                                        right={case1After}
                                        handle={reviewHandle}
                                        position={position1}
                                        onPositionChange={setPosition1}
                                        leftText="療程前"
                                        rightText="療程後"
                                        arrows
                                        width={400}
                                        height={400}
                                        aspectRatio={true}
                                    />
                                </SquareContainer>
                            </Column>

                            <Column $sm={6} $widthInColumn={"100%"}>
                                <SquareContainer>
                                    <CompareSlider
                                        left={case2Before}
                                        right={case2After}
                                        handle={reviewHandle}
                                        position={position2}
                                        onPositionChange={setPosition2}
                                        leftText="療程前"
                                        rightText="療程後"
                                        arrows
                                        width={400}
                                        height={400}
                                        aspectRatio={true}
                                    />
                                </SquareContainer>
                            </Column>
                        </Row>

                        <Row {...slideUp}>
                            <Column $sm={6} $widthInColumn={"100%"}>
                                <SquareContainer>
                                    <CompareSlider
                                        left={case3Before}
                                        right={case3After}
                                        handle={reviewHandle}
                                        position={position3}
                                        onPositionChange={setPosition3}
                                        leftText="療程前"
                                        rightText="療程後"
                                        arrows
                                        width={400}
                                        height={400}
                                        aspectRatio={true}
                                    />
                                </SquareContainer>
                            </Column>

                            <Column $sm={6} $widthInColumn={"100%"}>
                                <SquareContainer>
                                    <CompareSlider
                                        left={case4Before}
                                        right={case4After}
                                        handle={reviewHandle}
                                        position={position4}
                                        onPositionChange={setPosition4}
                                        leftText="療程前"
                                        rightText="療程後"
                                        arrows
                                        width={400}
                                        height={400}
                                        aspectRatio={true}
                                    />
                                </SquareContainer>
                            </Column>
                        </Row>

                        <Note {...slideUp}>療程效果因人而異</Note>

                        <div className="align-left-note-wrapper" {...slideUp}>
                            <AlignLeftNote>
                                Courtesy of (from left to right):
                            </AlignLeftNote>
                            <AlignLeftNote>
                                Sarawalai Rakchart, M.D.,Dissapong Panithaporn, M.D Surasawan Wongkietkachorn, M.D., Dr. Lau Wang Tak FRCSEd
                            </AlignLeftNote>
                        </div>

                        <br/><br/>
                    </MyContainer>

                </Container>
            </Section>

            <Section $colored>
                <Container $maxWidth="62rem">
                    <SectionHeader {...slideUp}>屢獲殊榮</SectionHeader>
                    <p {...slideUp}>
                        有別於其他透明質酸針劑品牌，瑞士製造的BELOTERO® REVIVE水光針，特別加入甘油成分，強化保濕效能，比大部
                        分護膚品更能深入肌底，直接將透明質酸和甘油成分滲透至 肌膚底層，增加肌膚水潤度及光澤度<sup>2-4</sup>，輕鬆煥發光彩亮澤，
                        塑造自然素顏裸肌。
                    </p>

                    <Row>

                        <Column $sm={6}>
                            <AwardsImage src={awards} {...slideUp} alt={"More Awards"} />
                            <br/>
                            <p {...slideUp}>連續兩年榮獲MORE美容產品大賞<br/><b>「編輯新品推介」<br/>強效補濕醫美療程</b></p>
                        </Column>

                        <Column $sm={6}>
                            <AwardsImage2 src={awards2} {...slideUp} alt={"AMWC Awards"} />
                            <br/>
                            <p {...slideUp}>AMWC世界美容與抗衰老醫學大會<br/><b>Best Injectables Skin Booster<br/>最佳補濕醫美療程</b>
                            </p>
                        </Column>
                    </Row>

                </Container>
            </Section>

            <Section>
                <Container>
                    <SectionHeader {...slideUp}>開啟護膚新基準</SectionHeader>

                    <RowWithGap>
                        <Column>
                            <StepZeroLogo src={stepZeroLogo} alt={"Step Zero™️"} {...slideUp} />

                            <p {...slideUp}>Merz Aesthetics® 引領醫美潮流並推出Step
                                Zero™️的護膚新基準，提倡「Skinimalism精簡護膚」及「Prejuvenation提前抗老活膚」，透過簡單高效的療程實現理想膚質，為肌膚打好基礎，簡化日常護膚程序。回歸簡約基本，配合醫美全方位改善膚質，省略護膚程序，減少對肌膚不必要的負擔。</p>

                            <AdvantageBlockContainer {...slideUp}>
                                <AdvantageBlock>
                                    <IconContainer><Icon src={Icon1} alt={"Icon decorator"}/></IconContainer>
                                    <AdvantageHeader>Skinimalism<br/>精簡護膚</AdvantageHeader>
                                    <AdvantageDescription>"Skin + Minimalism"<br/>維持簡單且高效率的護膚程序, 達到持久的美肌效果</AdvantageDescription>
                                </AdvantageBlock>

                                <AdvantageBlock>
                                    <IconContainer><Icon src={Icon2} alt={"Icon decorator"}/></IconContainer>
                                    <AdvantageHeader>Prejuvenation<br/>提前抗老活膚</AdvantageHeader>
                                    <AdvantageDescription>
                                        "Prevention + Rejuvenation"<br/>
                                        肌膚出現明顯老化前做好護膚準備,預防衰老並保持年輕肌膚狀態
                                    </AdvantageDescription>
                                </AdvantageBlock>
                            </AdvantageBlockContainer>
                        </Column>

                    </RowWithGap>

                </Container>
            </Section>

            <Section>
                <Container>
                    <SectionHeader {...slideUp}>真實用家分享</SectionHeader>
                    <Row>
                        <Column $sm={6}>
                            <UserReview.Item {...slideUp}>
                                <UserReview.Image src={userReview1} alt={"Review User Thumbnail"}/>
                                <UserReview.Text>
                                    <UserReview.Comment>我覺得皮膚質素改善了很多，水潤度提升，肌膚回復飽滿，毛孔明顯收細，光澤度很大改善。</UserReview.Comment>
                                    <UserReview.Title>Eko Yung ―― 物業銷售</UserReview.Title>
                                </UserReview.Text>
                            </UserReview.Item>
                        </Column>
                        <Column $sm={6}>
                            <UserReview.Item {...slideUp}>
                                <UserReview.Image src={userReview2} alt={"Review User Thumbnail"}/>
                                <UserReview.Text>
                                    <UserReview.Comment>以前皮膚顏色比較暗黃，現在更有光澤、更白皙，毛孔細緻了，皮膚更有彈性同飽漲。補濕度持久。整體皮膚質素明顯改善。非常好。</UserReview.Comment>
                                    <UserReview.Title>Katy Lau ―― 公關</UserReview.Title>
                                </UserReview.Text>
                            </UserReview.Item>
                        </Column>
                    </Row>
                    <br/><br/><br/>
                </Container>
            </Section>


            <ModalVideo isOpen={!!youtubeId} channel="youtube" autoplay videoId={youtubeId}
                        onClose={() => setYoutubeId(null)}
                        {...slideUp}
            />

            <Center {...slideUp}>
                <LinkButton to={routes.certifiedMedicalCenterReviveClinicCenter}>立即查詢BELOTERO® Revive的認證醫療中心</LinkButton>
            </Center>

            <MarginTop/>

            <Center {...slideUp}>
                <Ref>1. Goldie K, et al. Clin Cosmet Investig Dermatol. 2021 Jun 14;14:643-654</Ref>
                <Ref>2. BELOTERO Revive Instruction for use 2022</Ref>
                <Ref>3. Hertz-Kleptow D, et al. Clin Cosmet Investig Dermatol. 2019;12:563–572.</Ref>
                <Ref>4. Kerscher M, et al. Plast Reconstr Surg Glob Open. 2021 Dec 3;9(12):e3973.</Ref>
                <Ref>5. Marquardt K, et al. Hydration eect of a revitalizing CPM cross-linked hyaluronic acid gel containing glycerol. Poster presented at AWMC World Congress 2020 Monaco</Ref>
                <Ref>6. Kleine-Borger L, et al. Skin Res Technol. 2022;1-10</Ref>
                <Ref>7. Tran C et al. Dermatology 2014; 228:47-54</Ref>
            </Center>
        </>
    );
}
