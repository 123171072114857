import Container from "@components/Container";
import Center from "@components/Center";
import React from "react";
import SectionHeader from "@components/SectionHeader";
import LinkButton from "@components/LinkButton";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";

const Spacer = styled.div`
  margin-top: ${({$marginTop}) => $marginTop ? $marginTop + "px" : "20px"};
`;

export default function Benefits () {
    return (
        <Container $maxWidth="85rem">
            <br/>
            <Center>
                <p {...slideUp}>選擇BELOTERO® 無痕透明質酸系列，打造持久柔滑肌膚及重塑面部輪廓，重拾煥然一新的青春外觀，邁向Effortless Beauty，保持自信的態度展現真正的自己，無需隱藏，隨時近距離展現真實自我。立即查看以下BELOTERO®不同種類的透明質酸填充劑，按需要選擇最適合您的產品！</p>

                <LinkButton to={routes.certifiedMedicalCenterClinicCenter} {...slideUp}>立即體驗BELOTERO®，讓您輕鬆展現自信美！</LinkButton>

            </Center>
        </Container>
    );
}
