import React, {useCallback, useState} from "react";
import Container from "@components/Container";
import HeaderMenu from "./HeaderMenu";
import HeaderSubmenu from "./HeaderSubmenu";
import MerzSubmenu from "./MerzSubmenu";
import MobileHeaderMenu from "./MobileHeaderMenu";
import ProductColorsLine from "@components/ProductColorsLine";
import TransitionLink from "@components/TransitionLink";
import styled from "styled-components";
import mobileBurger from "@images/header-mobile-burger.svg";
import navBeloteroLogo from "@images/nav-belotero-logo.svg";
import navMerzLogo from "@images/nav-merz-logo.svg";
import routes from "@config/routes";


const Root = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.headerBackground};
  box-shadow: ${({theme}) => theme.boxShadow};
  z-index: ${({theme}) => theme.zIndexes.header};
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({theme}) => theme.headerHeight};

  ${({theme}) => theme.breakpoints.mdMax} {
    height: ${({theme}) => theme.headerHeightXsMax};
  }
`;

const BeloteroLogo = styled.img`
  width: 9.3rem;

  ${({theme}) => theme.breakpoints.lg} {
    width: 18.8rem;
  }
`;

const MerzLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:after {
    content: '';
    display: inline-block;
    width: .4em;
    height: .4em;
    border: 1px solid ${({theme}) => theme.black};
    border-top: none;
    border-left: none;
    transform: ${props => props.open ? "rotate(45deg)" : "rotate(-45deg)"};
    transition: transform ${({theme}) => theme.durations.basic + "  " + theme.easings.basic};
    margin-left: .3em;
    margin-bottom: .3em;
  }

  // Naver shows on mobile; it's another component for mobile
  ${({theme}) => theme.breakpoints.mdMax} { display: none; }
`;

const MerzLogo = styled.img`
  display: inline-block;
  width: 20rem;
  height: 1.8rem;
`;

const Burger = styled.img`
  width: 3.5rem;
  cursor: pointer;

  ${({theme}) => theme.breakpoints.lg} { display: none; }
`;

export default function Header () {
  const [mobileActive, setMobileActive] = useState(false);
  const [submenuItems, setSubmenuItems] = useState(null);
  const [merzSubmenuOpen, setMerzSubmenuOpen] = useState(false);
  const [hoverSubmenuIndex, setHoverSubmenuIndex] = useState(null);
  const emptySubmenuItems = useCallback(() => { setSubmenuItems(null); setHoverSubmenuIndex(null); }, []);
  const toggleMerzSubmenuOpen = useCallback(() => setMerzSubmenuOpen(prevMerzSubmenuOpen => !prevMerzSubmenuOpen), []);
  const openMerzSubmenu = useCallback(() => setMerzSubmenuOpen(true), []);
  const closeMerzSubmenu = useCallback(() => setMerzSubmenuOpen(false), []);
  const toggleMobileActive = useCallback(() => setMobileActive(prev => !prev), []);
  return (
    <>
      <Root>
        <ProductColorsLine />
        <Container.Large $normalPadding>
          <Inner>
            <TransitionLink to={routes.index}><BeloteroLogo src={navBeloteroLogo} alt="Belotero logo" /></TransitionLink>
            <HeaderMenu
              hoverSubmenuIndex={hoverSubmenuIndex}
              setHoverSubmenuIndex={setHoverSubmenuIndex}
              updateSubmenu={setSubmenuItems}
            />
            <MerzLogoWrap onMouseMove={openMerzSubmenu} onMouseLeave={closeMerzSubmenu} open={merzSubmenuOpen}>
              <MerzLogo src={navMerzLogo} alt="MERZ logo"/>
            </MerzLogoWrap>
            <Burger src={mobileBurger} onClick={toggleMobileActive} />
          </Inner>
        </Container.Large>
      </Root>
      <MerzSubmenu openMerzSubmenu={openMerzSubmenu} closeMerzSubmenu={closeMerzSubmenu} open={merzSubmenuOpen} />
      <MobileHeaderMenu
        onMerzLogoClick={toggleMerzSubmenuOpen}
        merzMenuOpen={merzSubmenuOpen}
        active={mobileActive}
        setActive={setMobileActive}
      />
      <HeaderSubmenu
        hoverSubmenuIndex={hoverSubmenuIndex}
        setHoverSubmenuIndex={setHoverSubmenuIndex}
        items={submenuItems}
        onMouseLeave={emptySubmenuItems}
      />
    </>
  );
}
