import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import LinkButton from "@components/LinkButton";
import styled, {css, useTheme} from "styled-components";
import circleInner from "@images/belotero-hyaluronic-acid-circle-inner.png";
import circleOuter from "@images/ico-circle-point.svg";
import grayCircleInner from "@images/belotero-hyaluronic-acid-gray-circle-inner.png";
import grayCircleOuter from "@images/belotero-hyaluronic-acid-gray-circle-outer.png";
import {linearGradient} from "polished";
import logoBalance from "@images/belotero-hyaluronic-acid-logo-balance.png";
import logoIntense from "@images/belotero-hyaluronic-acid-logo-intense.png";
import logoRevive from "@images/belotero-hyaluronic-acid-logo-revive.png";
import logoSoft from "@images/belotero-hyaluronic-acid-logo-soft.png";
import logoVolume from "@images/belotero-hyaluronic-acid-logo-volume.png";
import productBalance from "@images/belotero-hyaluronic-acid-product-balance.png";
import productIntense from "@images/belotero-hyaluronic-acid-product-intense.png";
import productRevive from "@images/belotero-hyaluronic-acid-product-revive.png";
import productSoft from "@images/belotero-hyaluronic-acid-product-soft.png";
import productVolume from "@images/belotero-hyaluronic-acid-product-volume.png";
import productShadow from "@images/belotero-hyaluronic-acid-product-shadow.png";
import routes from "@config/routes";


const productDetails = [
    {
        colorName: "cyan",
        image: productRevive,
        logo: logoRevive,
        descriptions: ["改善膚質", "提升肌膚水份", "減淡細紋"],
        learnMoreRevive: true,
    },
    {
        colorName: "yellow",
        gray: true,
        image: productSoft,
        logo: logoSoft,
        descriptions: ["改善輕微細紋"],
    },
    {
        colorName: "orange",
        image: productBalance,
        logo: logoBalance,
        descriptions: ["改善中度皺紋:", "中度法令紋", "嘴部細紋", "改善唇部輪廓"],
    },
    {
        colorName: "magenta",
        image: productIntense,
        logo: logoIntense,
        descriptions: ["改善深度皺紋:", "嚴重法令紋及木偶紋", "嘴唇豐盈度"],
    },
    {
        colorName: "purple",
        image: productVolume,
        logo: logoVolume,
        descriptions: ["重塑面部凹陷:", "太陽穴", "蘋果肌", "豐盈面頰", "下巴", "嚴重法令紋"],
    },
];


const Root = styled.div`
  position: relative;
  font-size: 1rem; // To let the child components to use em unit for relative measuring
  font-size: 0.52083vh; // To let the child components to use em unit for relative measuring
  height: 750px;
  
  @media (min-width: 1441px) {
    height: 1000px;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 55%;

    ${({theme}) => theme.breakpoints.xsMax} {
      padding-top: 169.74359%;
    }
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  background-color: ${({theme}) => theme.black};
  z-index: 5;

  ${({theme}) => theme.breakpoints.xsMax} {
    // Will make a background attribute
    ${({theme}) => linearGradient({
      colorStops: [
        `${theme.productColorsLinear[0]} 0%`,
        `${theme.productColorsLinear[0]} 50%`,
        `${theme.productColorsLinear[theme.productColorsLinear.length - 1]} 50%`,
        `${theme.productColorsLinear[theme.productColorsLinear.length - 1]} 100%`,
      ],
      fallback: theme.black,
      toDirection: "to right",
    })};
  }
`;

const expend = 1.2;
const expandXsMax = 4;

const Product = {
    Item: styled.div`
      position: relative;
      display: inline-block;
      width: ${({$active, $anotherActive}) => {
        if (!$active && !$anotherActive) {
          return 100 / productDetails.length;
        }
        return 100 / productDetails.length * ($active ? expend : (productDetails.length - expend) / (productDetails.length - 1));
      }}%;
      height: 100%;
      background-color: ${({$product, theme}) => theme.productColors[$product.colorName]};
      opacity: ${({$anotherSelected}) => $anotherSelected ? ".7" : "1"};
      transition-property: opacity, width, left;
      transition-duration: ${({theme}) => theme.durations.basic};
      transition-timing-function: ${({theme}) => theme.easings.basic};
      cursor: pointer;

      ${({theme}) => theme.breakpoints.xsMax} {
        opacity: 1;
        width: ${({$active, $anotherActive}) => {
          if (!$active && !$anotherActive) {
            return 100 / productDetails.length;
          }
          return 100 / productDetails.length * ($active ? expandXsMax : (productDetails.length - expandXsMax) / (productDetails.length - 1));
        }}%;
        z-index: ${({$active, $currentActive, $index}) => {
          if ($active) {
            return "5";
          }
          if (Math.abs($currentActive - $index) === 1) {
            return "9";
          }
          return "15";
        }}; // Because the non-active one's indicator should cover the active one

        &:before, &:after {
          content: ${({$currentActive, $index}) => Math.abs($currentActive - $index) === 1 ? "''" : "none"};
          position: absolute;
          top: 90%;
          left: ${({$currentActive, $index}) => $currentActive === $index - 1 ? "0" : "100%"};
          width: 0;
          height: 0;
        }

        &:before {
          border: 6em solid ${({$product, theme}) => theme.productColors[$product.colorName]};
          border-radius: 6em;
          transform: translate(-50%, -50%);
        }

        &:after {
          border-top: 3.2em solid transparent;
          border-bottom: 3.2em solid transparent;
          border-right: 3.2em solid ${({theme}) => theme.white};
          transform: ${({
                          $currentActive,
                          $index
                        }) => $currentActive === $index - 1 ? "translate(-100%, -50%)" : "translate(0, -50%) rotate(180deg)"};
        }
      }
    `,
    ImageWrap: styled.div`
      position: absolute;
      top: ${({$selected}) => $selected ? "35%" : "50%"};
      left: 50%;
      width: 30%;
      transform: translate(-50%, -50%);
      transition-property: top;
      transition-duration: ${({theme}) => theme.durations.basic};
      transition-timing-function: ${({theme}) => theme.easings.basic};
      
      @media (min-width: 1441px) {
        width: 25%;
        top: ${({$selected}) => $selected ? "25%" : "50%"};
      }

      ${({theme}) => theme.breakpoints.xsMax} {
        top: ${({$active}) => $active ? "29%" : "35%"};
        width: 24%;
        transition-property: opacity, top;
        opacity: ${({$active}) => $active ? "1" : "0"};
      }
    `,
    Image: styled.img`
      position: relative;
      width: 70%;
      left: ${({$selected}) => $selected ? "20%" : "0"};
      transition: left ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
      animation: ${({$selected, theme}) => $selected ? css`${theme.animations.floating} 20s infinite` : "none"};

      ${({theme}) => theme.breakpoints.xsMax} {
        left: 0;
        transition: left ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic}, opacity ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
        opacity: ${({$active}) => $active ? "1" : "0"};
      }
    `,
    Shadow: styled.img`
      position: absolute;
      width: 70%;
      top: ${({$selected}) => $selected ? "20%" : "0"};
      left: ${({$selected}) => $selected ? "-90%" : "0"};
      opacity: ${({$selected}) => $selected ? "1" : "0"};
      transition-property: opacity, top, left;
      transition-duration: ${({theme}) => theme.durations.basic};
      transition-timing-function: ${({theme}) => theme.easings.basic};
      animation: ${({$selected, theme}) => $selected ? css`${theme.animations.floating} 20s infinite` : "none"};

      ${({theme}) => theme.breakpoints.xsMax} {
        top: ${({$active}) => $active ? "16%" : "0"};
        left: ${({$active}) => $active ? "-67%" : "0"};
      }
    `,
    Detail: styled.div`
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      top: ${({$selected}) => $selected ? "58%" : "83%"};
      left: 50%;
      width: 100%;
      height: 27em;
      transform: translateX(-50%);
      transition: top ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
      pointer-events: none;

      @media (min-width: 1441px) {
        top: ${({$selected}) => $selected ? "50%" : "83%"};
      }

      ${({theme}) => theme.breakpoints.xsMax} {
        top: ${({$selected}) => $selected ? "58%" : "76%"};
      }
    `,
    Logo: styled.img`
      //height: 4.2em;
      margin: 0 auto;
      transition: top ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};

      ${({theme}) => theme.breakpoints.xsMax} {
        height: 5.7em;
        transition: top ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic}, opacity ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
        opacity: ${({$selected}) => $selected ? "1" : "0"};
      }
    `,
    Descriptions: styled.div`
      position: relative;
      display: inline-block;
      font-size: 3.5em;
      margin-top: 1em;
      color: ${({theme}) => theme.white};
      white-space: pre-wrap;
      word-break: keep-all;
      opacity: ${({$selected}) => $selected ? "1" : "0"};
      transition-property: opacity, top;
      transition-duration: ${({theme}) => theme.durations.basic};
      transition-timing-function: ${({theme}) => theme.easings.basic};

      ${({theme}) => theme.breakpoints.xsMax} {
        margin-top: 1em;
        font-size: 5em;
        opacity: ${({$active}) => $active ? "1" : "0"};
      }

      @media (min-width: 1441px) {
        //font-size: 30px;
        font-size: 3.4em;
      }
      
      > div {
        display: flex;
        align-items: center;
        line-height: 1;
        margin-bottom: 1em;
        color: ${({$product}) => $product.gray ? "#6F6F6F" : "inherit"};

        ${({theme}) => theme.breakpoints.xsMax} {
          margin-bottom: .3em;
        }

        &:before, &:after {
          content: '';
          display: block;
          height: 1.4em;
          width: 1.4em;
          /* height: 1em;
          width: 1em; */
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }

        &:before {
          margin-right: .5em;
          background-image: url(${({$product}) => $product.gray ? grayCircleOuter : circleOuter});
          background-size: contain;
          animation: 1s ${({theme}) => theme.animations.spinning} infinite;
        }

        &:after {
          position: absolute;
          left: 0;
          background-image: url(${({$product}) => $product.gray ? grayCircleInner : circleInner});
          background-size: 50% 50%;
        }

        &[data-no-point=true]:before, &[data-no-point=true]:after {
          content: none;
        }
        
        &[data-no-point=true] {
          justify-content: center;
        }
      }
    `,
    LearnMoreRevive: styled(LinkButton)`
      position: absolute;
      top: ${({$selected}) => $selected ? "90%" : "105%"};
      left: 50%;
      font-size: 3em;
      padding: .5em 1.4em .6em;
      background-color: ${({theme}) => theme.white};
      border-color: ${({theme}) => theme.white};
      color: ${({theme}) => theme.productColors.cyan};
      transform: translateX(-50%);
      opacity: ${({$selected}) => $selected ? "1" : "0"};
      transition-property: opacity, top;
      transition-duration: ${({theme}) => theme.durations.basic};
      transition-timing-function: ${({theme}) => theme.easings.basic};

      ${({theme}) => theme.breakpoints.xsMax} {
        top: ${({$selected}) => $selected ? "87%" : "102%"};
        font-size: 4.4em;
        padding: .5em 1.4em .6em;
      }

      &:before {
        background-color: ${({theme}) => theme.productColors.cyan};
      }

      &:visited, &:focus, &:hover {
        color: ${({theme}) => theme.productColors.cyan};
      }

      &:hover {
        color: ${({theme}) => theme.white};
      }
    `,
};

export default function ProductInteractive(props) {
    const rootRef = useRef();

    /**
     * The index of active item
     * @type {number}
     */
    const [active, setActive] = useState(null);

    /**
     * The index of the item which is hovered by cursor no
     * @type {number}
     */
    const [hover, setHover] = useState(null);
    const onProductItemClick = useMemo(() => productDetails.map((detail, index) => () => setActive(index)), []);
    const onProductItemMouseMove = useMemo(() => productDetails.map((detail, index) => () => setHover(index)), []);
    const onProductItemMouseLeave = useMemo(() => productDetails.map((detail, index) => () => setHover(prevHover => prevHover === index ? null : prevHover)), []);

    /**
     * Go next when left is swipped
     * @type {function}
     */
    const onSwipeLeft = useCallback(() => setActive(prev => Math.min(prev + 1, productDetails.length - 1)), []);
    const onSwipeRight = useCallback(() => setActive(prev => Math.max(prev - 1, 0)), []);

    const theme = useTheme();

    /**
     * A function to catch swipe left and right.
     * @see https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
     */
    useEffect(() => {
        const root = rootRef.current;
        let xDown = null;
        let yDown = null;

        function getTouches(event) {
            return event.touches || event.originalEvent.touches;
        }

        function handleTouchStart(event) {
            xDown = getTouches(event)[0].clientX;
            yDown = getTouches(event)[0].clientY;
        }

        function handleTouchMove(event) {
            if (!(xDown && yDown)) {
                return;
            }
            const xDiff = xDown - event.touches[0].clientX;
            const yDiff = yDown - event.touches[0].clientY;
            if (Math.abs(xDiff) >= Math.abs(yDiff)) {
                xDiff > 0 ? onSwipeLeft() : onSwipeRight();
                xDown = yDown = null;
            }
        }

        root.addEventListener('touchstart', handleTouchStart);
        root.addEventListener('touchmove', handleTouchMove);

        return () => {
            root.removeEventListener('touchstart', handleTouchStart);
            root.removeEventListener('touchmove', handleTouchMove);
        };
    }, [onSwipeLeft, onSwipeRight]);

    useEffect(() => {
        /**
         * Automatically selecting the first item when using mobile.
         */
        if (window.innerWidth <= theme.breakpointWidths.xsMax) {
            setActive(0);
        }
    }, [theme]);

    return (
        <Root ref={rootRef}>
            <Inner>
                {productDetails.map((product, index) => (
                    <Product.Item
                        key={index}
                        $selected={active === index || hover === index}
                        $active={active === index}
                        $hover={hover === index}
                        $anotherActive={typeof active === "number" && active !== index}
                        $anotherSelected={typeof active === "number" && active !== index && typeof hover === "number" && hover !== index}
                        $currentActive={active}
                        onClick={onProductItemClick[index]}
                        onMouseMove={onProductItemMouseMove[index]}
                        onMouseLeave={onProductItemMouseLeave[index]}
                        $index={index}
                        $product={product}
                        data-index={index}
                    >
                        <Product.ImageWrap $selected={active === index || hover === index} $active={active === index}>
                            <Product.Shadow $selected={active === index || hover === index} $active={active === index}
                                            src={productShadow} alt={"shadow"} />
                            <Product.Image $selected={active === index || hover === index} $active={active === index}
                                           src={product.image} alt={"image"} />
                        </Product.ImageWrap>
                        <Product.Detail $selected={active === index || hover === index}>
                            <Product.Logo $selected={active === index || hover === index} $active={active === index}
                                          src={product.logo} alt={"Logo"}/>
                            <Product.Descriptions $product={product} $selected={active === index || hover === index}
                                                  $active={active === index}>{product.descriptions.map((line, index) =>
                                <div key={index}
                                     data-no-point={line.substr(-1) === ":" ? "true" : "false"}>{line}</div>)}</Product.Descriptions>
                        </Product.Detail>
                        {product.learnMoreRevive ? <Product.LearnMoreRevive to={routes.beloteroRevive}
                                                                            $selected={active === index || hover === index}>了解更多</Product.LearnMoreRevive> : null}
                    </Product.Item>
                ))}
            </Inner>
        </Root>
    );
}
