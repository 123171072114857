import React from "react";
import styled from "styled-components";
import Container from "@components/Container";
import H1 from "@components/H1";

import {math} from "polished";
import SectionHeader from "@components/SectionHeader";
import Center from "@components/Center";
import {Column} from "../../components/Row";
import {Row} from "../../components/Row";

import blogThumbnail1 from "@images/Blog_thumbnail_1.png";
import TransitionLink from "../../components/TransitionLink";
import routes from "../../config/routes";

import fiveProducts from "@images/five-products.jpg";

import productBalance from "@images/Bel_Web Blog1_balance.jpg";
import productIntense from "@images/Bel_Web Blog1_intense.jpg";
import productRevive from "@images/Bel_Web Blog1_revive.jpg";
import productSoft from "@images/Bel_Web Blog1_soft.jpg";
import productVolume from "@images/Bel_Web Blog1_volume.jpg";
import {slideUp} from "../../config/sal";

import Reference from "@components/Reference";

const H3 = styled.h3`
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  font-family: ${({theme}) => theme.bodyFontFamily};
  color: ${({$black, theme}) => $black ? theme.black : theme.headerColor};
  margin-top: 2rem;
  margin-bottom: 3rem;

  &:after {
    content: '';
    display: block;
    max-width: 56.5rem;
    height: .3rem;
    margin: 1rem auto 0;
    ${({theme}) => theme.productColorLine}
  }

  ${({theme}) => theme.breakpoints.xsMax} {
    font-size: 2.4rem;
  }
`;

const ProductDescription = styled.p`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin: 1em auto;

  @media screen and (max-width: 575px) {
    line-height: 1.8;
  }
`;

const productInfoList = [
    {
        header: "Belotero® Revive 水光針 ︳裸光喚膚 塑造水潤肌膚",
        productImg: productRevive,
        imgAlt: "Belotero Revive",
        description: <ProductDescription {...slideUp}>男生女生都愛的「初戀臉」，素顏也要水嫩發光！全港唯一<sup>2</sup>結合透明質酸和甘油的水光針BELOTERO®
            REVIVE，其獨有的保濕鎖水配方能強化保濕效能，比大部分護膚品更能滲透至肌膚底層，增加肌膚光澤度<sup>3#</sup>及水潤度<sup>4</sup>，輕鬆打造水漾嫩肌，搖身一變素顏仙氣女生！</ProductDescription>,
    },
    {
        header: "Belotero® Soft ︳撫平細紋 實現無齡感顏值",
        productImg: productSoft,
        imgAlt: "Belotero Soft",
        description: <ProductDescription {...slideUp}>針對初熟女生因膠原開始流失而出現的細紋問題，BELOTERO® SOFT
            專攻初⽣細紋，同時可改善整體肌膚的細緻度，平滑膚質，為你定格年輕之餘，讓久未見面的朋友驚訝你的肌齡逆轉秘密。</ProductDescription>,
    },
    {
        header: "Belotero® Balance ︳撫平中度皺紋 甜美笑容融化男心",
        productImg: productBalance,
        imgAlt: "Belotero Balance",
        description: <ProductDescription {...slideUp}>清楚自己的需要，就要對準問題核心，透過高濃度及承托力的透明質酸撐起鬆弛膚質，利用BELOTERO®
            BALANCE擊退衰老跡象，如法令紋、嘴部細紋、或打造優雅唇峰曲線。讓你如乘一趟時光機般，令整體輪廓更顯自然飽滿，不著痕跡地逆轉肌齡。</ProductDescription>,
    },
    {
        header: "Belotero® Intense ︳撫平深度皺紋",
        productImg: productIntense,
        imgAlt: "Belotero Intense",
        description: <ProductDescription {...slideUp}>BELOTERO®
            INTENSE針對法令紋、木偶紋等深度皺紋，改善因皺紋而造成的疲倦愁顏，更同時豐盈唇部輪廓，立刻展現性感勾人嘟嘟唇，還原青春立體輪廓。</ProductDescription>,
    },
    {
        header: "Belotero® Volume ︳重塑面部輪廓",
        productImg: productVolume,
        imgAlt: "Belotero Volume",
        description: <ProductDescription {...slideUp}>東方人普遍臉型偏寬、下巴較短，加上兩邊蘋果肌扁平，容易出現法令紋。透過BELOTERO®
            VOLUME能夠重塑飽滿輪廓、改善面部凹陷問題，如太陽穴、蘋果肌和豐盈面頰及下巴等位置，使整體輪廓更精緻甜美，看起來更精神飽滿，彷如韓劇中有著小V臉型的女主角一樣神顏，桃花自然更旺!</ProductDescription>,
    },
];

const BlogFlex = styled(Row)`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 575px) {
    gap: 20px;
  }
`;

const ProductImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const NoteWrapper = styled.div`
  max-width: 800px;
  width: fit-content;
  margin: 0 auto;
`;

const Note = styled(Reference)`
  text-align: center;
  color: ${({theme}) => theme.gray700};
`;

const AlignLeftNote = styled(Note)`
  text-align: left;
`;

const ParaImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

export default function Blog1() {


    return (
        <Container $maxWidth="104rem">
            <H1>一文認識 Belotero®無痕透明質酸系列：<br/>5款產品分析全攻略</H1>

            <Center>
                <ParaImg {...slideUp} src={blogThumbnail1} alt="belotero model"/>

                <ProductDescription {...slideUp}>
                    女生們都夢想著擁有一張無需修飾，近距離也能展現完美無瑕的緊緻肌膚，時刻散發著年輕的活力光芒。
                    而要保持美麗，透明質酸幾乎是大多數人首先想到的醫美療程。Belotero®無痕透明質酸系列能迎合您肌膚的各種需要，無論是重拾水潤肌膚、改善膚質、填補皺紋還是重塑臉部輪廓，都能輕鬆一一應對，讓你變得自然美麗,
                    年輕無痕不費力！ 想知道Belotero®的5款透明質酸產品哪一種適合自己？馬上繼續看下去！
                </ProductDescription>
                <br/><br/>

                <SectionHeader {...slideUp}>認識 Belotero® 系列5款透明質酸 ︳探索更多「美」的可能</SectionHeader>
                <ParaImg {...slideUp} src={fiveProducts} alt="belotero products"/>
                <ProductDescription {...slideUp}>源自瑞士製造的Belotero®無痕透明質酸系列採用了獨有的「CPM技術」，能無瑕融合^到皮膚中，結合凝聚度、黏稠度、彈性及可塑性的四大特點，以展現出自然無違和的動態效果，美得自然簡單。Belotero®
                    無痕透明質酸系列通過美國FDA認可*及歐盟CE認証，更榮獲多個醫學美容獎項，深受用家滿意<sup>1</sup>，<TransitionLink
                        to={routes.beloteroHyaluronicAcid}>5款透明質酸產品</TransitionLink>包括：</ProductDescription>
                <br/><br/>
                {
                    productInfoList.map((item) => {
                        return (
                            <>
                                <H3 {...slideUp}>{item.header}</H3>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        <ProductImg {...slideUp} src={item.productImg} alt={item.imgAlt}/>
                                    </Column>

                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }

            </Center>

            <NoteWrapper>
                <AlignLeftNote>
                    * Comparing the hyaluronic acid fillers in Hong Kong as of November 2023.
                </AlignLeftNote>
                <AlignLeftNote>
                    1. BELOTERO Revive Instruction for use 2022<br/>
                    2. Hertz-Kleptow D, et al. Clin Cosmet Investig Dermatol. 2019;12:563–572.<br/>
                    3. Kerscher M, et al. Plast Reconstr Surg Glob Open. 2021 Dec 3;9(12):e3973.
                </AlignLeftNote>
                <AlignLeftNote>
                    Reference:
                    ^Tran C, et al. Dermatology. 2014;228(1):47-54.<br/>
                    Belotero Balance Lidocaine is approved by the US Food and Drug Administration (FDA). <br/>
                    1 Prager W et al. Plast Reconstr Surg Glob Open. 2017;5(10):e1412 <br/>
                    2 Comparing the hyaluronic acid fillers in Hong Kong as at November 2022. <br/>
                    3 Hertz-Kleptow D, et al. Clin Cosmet Investig Dermatol. 2019;12:563–572. <br/>
                    4 Marquardt K, et al. Hydration effect of a revitalizing CPM cross-linked hyaluronic acid gel
                    containing glycerol. Poster presented at AWMC World Congress 2020 Monaco <br/>
                    # Improvement in skin radiance defined by decrease in haemoglobin levels and increase in melanin
                    levels as measured by a mexameter <sup>3</sup>.
                </AlignLeftNote>
            </NoteWrapper>
        </Container>
    );
}
