import {ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage} from "react-compare-slider";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";


const Root = styled.div`
  position: relative;
  width: ${({rootWidth}) => !rootWidth ? "initial" : rootWidth + "px"};
  height: ${({height}) => !height ? "initial" : height + "px"};
  margin: ${({rootMargin}) => !rootMargin ? "initial" : rootMargin};

  @media (max-width: 576px) {
    aspect-ratio: ${({aspectRatio}) => !aspectRatio? "initial" : 1 };
    width: 100%;
    height: unset;

    div[data-rcs="root"] {
      height: unset !important;
    }
  }

  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  // To override the style by the library, because the library sucks
  .__rcs-handle-line {
    width: 50% !important;

    ${({theme}) => theme.breakpoints.xsMax} {
      width: 30% !important;
    }
  }

  .__rcs-handle-button {
    position: relative;
    display: block !important;
    background-image: url('${props => props.handle}') !important;
    background-size: contain !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    width: 670% !important;
    height: auto !important;
    margin-top: -5rem;
    margin-bottom: -5rem;
    margin-top: -100rem;
    margin-bottom: -100rem;
    z-index: 7;

    &:before {
      content: '';
      display: block;
      padding-bottom: 307%;
    }

    ${({theme}) => theme.breakpoints.xsMax} {
      width: 300% !important;
    }
  }

  div[data-rcs="handle-container"] > div:first-child:last-child {
    //

    &:before, &:after {
      content: ${({$arrows}) => $arrows ? "''" : "none"};
      position: absolute;
      top: 50%;
      right: 450%;
      width: 0;
      height: 0;
      margin-top: -.3rem;
      border-top: .6rem solid transparent;
      border-bottom: .6rem solid transparent;
    }

    &:before {
      right: 450%;
      border-right: 1rem solid ${({theme}) => theme.white};
    }

    &:after {
      left: 450%;
      border-left: 1rem solid ${({theme}) => theme.white};
    }
  }

  img {
    &:after {
      content: 'after';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 2rem;
      max-width: 100%;
    }
  }
`;

const LeftText = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  font-size: 3rem;
  z-index: 17;
  color: ${({theme}) => theme.white};
  text-shadow: 0 0 .3rem ${({theme}) => theme.black};

  ${({theme}) => theme.breakpoints.xsMax} {
    bottom: 1rem;
    left: 2rem;
    font-size: 1.4rem;
  }
`;

const RightText = styled(LeftText)`
  left: initial;
  right: 3rem;
  z-index: 0;

  ${({theme}) => theme.breakpoints.xsMax} {
    left: initial;
    bottom: 1rem;
    right: 2rem;
    font-size: 1.4rem;
  }
`;

export default function CompareSlider(props) {
    const {handle, leftText, left, onPositionChange, position, rightText, right, arrows, ...restProps} = props;

    // if (!restProps.width && !restProps.height) {
    //     restProps.width = "100%";
    //     restProps.height = "100%";
    // }

    return (
        <Root handle={handle} $arrows={arrows} {...restProps}>
            <ReactCompareSlider
                position={position}
                onPositionChange={onPositionChange}
                handle={(
                    <ReactCompareSliderHandle
                        buttonStyle={{
                            borderRadius: 0,
                            boxShadow: "none",
                            backdropFilter: undefined,
                            background: "transparent",
                            color: "transparent",
                            border: 0,
                        }}
                    />
                )}
                itemOne={(
                    <>
                        <ReactCompareSliderImage src={left} alt={"before image"}/>
                        <LeftText>{leftText}</LeftText>
                    </>
                )}
                itemTwo={(
                    <>
                        <ReactCompareSliderImage src={right} alt={"after image"}/>
                        <RightText>{rightText}</RightText>
                    </>
                )}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    maxWidth: restProps.maxWidth,
                    width: "100%",
                    height: restProps.height
                }}
            />
        </Root>
    );
}

CompareSlider.propTypes = {
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    position: PropTypes.func.isRequired,
    onPositionChange: PropTypes.func.isRequired,
    leftText: PropTypes.string.isRequired,
    rightText: PropTypes.string.isRequired,
};

CompareSlider.defaultProps = {
    leftText: "BEFORE",
    rightText: "AFTER",
};
