import {Column, Row} from "@components/Row";
import React, {useCallback, useState} from "react";
import BulletPoints from "@components/BulletPoints";
import Container from "@components/Container";
import LinkButton from "@components/LinkButton";
import ScrollPoint from "@components/ScrollPoint";
import Spacer from "@components/Spacer";
import bottle1 from "@images/index-introduction-bottle-1.png";
import bottle2 from "@images/index-introduction-bottle-2.png";
import bottle3 from "@images/index-introduction-bottle-3.png";
import bottle4 from "@images/index-introduction-bottle-4.png";
import bottle5 from "@images/index-introduction-bottle-5.png";
import {math} from "polished";
import model from "@images/index-introduction-model-michelle.jpg";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";
import SectionHeader from "@components/SectionHeader";
import H1Component from "@components/H1";

import "./belotero.css"
import Center from "../../components/Center";


const Root = styled.div`
  position: relative;
`;

const Inner = styled.div`
  position: relative;
`;

const Rainbow = styled.div`
  position: absolute;
  top: 0;
  left: 17%;
  width: 15%;
  height: 100%;
  white-space: nowrap;

  > div {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    transition: height ${({theme}) => theme.durations.slow} ${({theme}) => theme.easings.basic};

    &:nth-child(1) { height: ${({$show}) => $show ? "86%" : "0"}; transition-delay: ${math("4 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[0]}; }
    &:nth-child(2) { height: ${({$show}) => $show ? "96%" : "0"}; transition-delay: ${math("2 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[1]}; }
    &:nth-child(3) { height: ${({$show}) => $show ? "100%" : "0"}; transition-delay: ${math("1 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[2]}; }
    &:nth-child(4) { height: ${({$show}) => $show ? "92%" : "0"}; transition-delay: ${math("3 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[3]}; }
    &:nth-child(5) { height: ${({$show}) => $show ? "96%" : "0"}; transition-delay: ${math("2 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[4]}; }
  }

  ${({theme}) => theme.breakpoints.xsMax} {
    position: relative;
    left: 0;
    width: 40%;
    height: 42rem;
    margin-left: 10%;
  }
`;

const BottleWrap = styled.div`
  position: relative;
  margin-top: 14rem;

  ${({theme}) => theme.breakpoints.xsMax} {
    margin-top: -30rem;
  }
`;

const BottleImage = styled.img`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin-left: -12%;
  opacity: ${({$show}) => $show ? "1" : "0"};
  top: ${({$show}) => $show ? "0" : "3rem"};
  transition-property: opacity, top;
  transition-duration: ${({theme}) => theme.durations.basic};
  transition-timing-function: ${({theme}) => theme.easings.basic};

  &:nth-child(1) { margin-top: -10%; z-index: 5; animation: ${({theme}) => theme.animations.floating} 20s -4s infinite; }
  &:nth-child(2) { margin-top: 10%; z-index: 4; animation: ${({theme}) => theme.animations.floating} 20s -6s infinite; }
  &:nth-child(3) { margin-top: -20%; z-index: 3; animation: ${({theme}) => theme.animations.floating} 20s -12s infinite; }
  &:nth-child(4) { margin-top: -13%; z-index: 2; animation: ${({theme}) => theme.animations.floating} 20s -14s infinite; }
  &:nth-child(5) { margin-top: -5%; z-index: 1; animation: ${({theme}) => theme.animations.floating} 20s -8s infinite; }
`;

const ModelImage = styled.img`
  width: 100%;
`;

const H1 = styled(H1Component)`

  ${({theme}) => theme.breakpoints.sm} {
    margin-bottom: 0;
  }
`;

const Paragraph = styled.p`
  margin-top: 0;
`;

const SmallLinkButton = styled(LinkButton)`
  width: 100%;
  box-sizing: border-box;
`;

const CenterSectionHeader = styled(SectionHeader)`
  text-align: center;
  width: 100%;
`;

const CenterH3 = styled.h3`
  text-align: center;
`;

const CenterParagraph = styled.p`
  text-align: center;
`;

export default function Introduction () {
  const [show, setShow] = useState(false);
  const onScrollPointReach = useCallback(scrollPoint => { scrollPoint < .5 && setShow(true); }, []);

  return (
    <Root>
      <ScrollPoint onScrollPointChange={onScrollPointReach} />
      <Inner $noPadding>
        <Rainbow $show={show}>
          <div /><div /><div /><div /><div />
        </Rainbow>
        <Container>
          <Row>
            <Column $sm={6}>
              <BottleWrap>
                <BottleImage $show={show} src={bottle1} alt="Belotero Revive Bottle" />
                <BottleImage $show={show} src={bottle2} alt="Belotero Soft Bottle" />
                <BottleImage $show={show} src={bottle3} alt="Belotero Balance Bottle" />
                <BottleImage $show={show} src={bottle4} alt="Belotero Intense Bottle" />
                <BottleImage $show={show} src={bottle5} alt="Belotero Volume Bottle" />
              </BottleWrap>
            </Column>
            <Column $sm={1} />
            <Column $sm={5}>

              <H1 {...slideUp}>認識 BELOTERO®<br/>無痕透明質酸彩虹針</H1>

              <p {...slideUp}>
                {`
                            任何人都希望獲得輕鬆自然的美，流露真我而不著痕跡，時常散發自信光芒。
                            瑞士製造的BELOTERO® 無痕透明質酸彩虹針，正符合香港女性想變靚卻又不想動刀、不想被察覺、想悄悄變美的需要。
                            BELOTERO®一系列5款透明質酸填充劑，由改善膚質、填補不同程度皺紋至重塑面部輪廓，貼心迎合不同臉上部位和注射層面需要。
                        `}
              </p>

              <SmallLinkButton to={routes.beloteroHyaluronicAcid} {...slideUp}>
                立即認識BELOTERO®<br/>透明質酸彩虹針
              </SmallLinkButton>
            </Column>
          </Row>
        </Container>
        <Spacer $sm="3rem" />
        <Container>
          <Row>
            <Column $sm={4}>&nbsp;</Column>
            <Column $sm={8}>
              <CenterSectionHeader {...slideUp}>
                透明質酸補水填充功效多<br/>任何年齡都能擁有 MOCHI Q 彈肌
              </CenterSectionHeader>
              <CenterH3 {...slideUp}>BELOTERO®透明質酸彩虹針療程可以改善什麼肌膚問題？</CenterH3>
              <CenterParagraph {...slideUp}>
                BELOTERO® 一系列5款透明質酸彩虹針照顧不同年齡層的肌膚需要及理想塑顏效果。
                有效平滑膚質、保濕盈潤、提升輪廓及立體，療程效果自然持久<sup>1#</sup>，時刻展現最佳自信美態。
              </CenterParagraph>
            </Column>
          </Row>
          <Row>
            <Column $sm={4}>&nbsp;</Column>
            <Column $sm={8}><div className="responsive-video" {...slideUp}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/VwOZq8XR9PI"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div></Column>
          </Row>
          <Row>
            <Column $sm={4}>&nbsp;</Column>
            <Column $sm={5}>
              <CenterSectionHeader {...slideUp}>美得自然、簡單！打造與理想零距離貼近的無痕美態</CenterSectionHeader>

              <Paragraph {...slideUp}>
                BELOTERO®透明質酸彩虹針系列通過歐盟CE及美國FDA認可<sup>*</sup>。採用獨有CPM（多密度凝聚）技術，
                完美平衡透明質酸的凝聚度、黏稠度、彈性及可塑性四大特點，塑造出均衡比例，確保與肌膚良好融合<sup>1</sup>，配合特定皮膚層的不同需要。
                只要找到對的方法，無論任何年齡、任何狀態，BELOTERO®都可以令您擁有最美輪廓，時刻展現出最佳自信狀態，散發年輕活力光芒。
              </Paragraph>

              <BulletPoints
                items={[
                  {text: <h3>產品通過美國FDA認可*及歐盟CE認証</h3>},
                  {text: <h3>療程近乎無痛<sup>3</sup></h3>},
                  {text: <h3>可以減少藍光反應的機會<sup>4</sup></h3>},
                  {text: <h3>獲使用者高度滿意<sup>5</sup></h3>},
                ]}
              />
              <Spacer $xsMax="2rem" />
            </Column>
            <Column $sm={3}>
              <ModelImage src={model} alt="BELOTERO® 系列透明質酸採用獨有CPM(多密度凝聚)技術，令透明質酸凝聚度、彈性及可塑性達至理想的均衡比例，確保與肌膚良好融合1，可以配合特定皮膚層的不同需要。" {...slideUp} />
            </Column>
          </Row>
        </Container>
      </Inner>
      <br /><br /><br />
    </Root>
  );
}
