/**
 *
 * @example
 * import * as sal from "@config/sal";
 *
 * <div {...sal.slideUp}>hello, world</div>
 *
 *
 * @see https://www.gatsbyjs.com/plugins/gatsby-plugin-scroll-reveal/
 */
export const fade = {"data-sal": "fade", "data-sal-duration": "1000"};
export const slideUp = {"data-sal": "slide-up", "data-sal-duration": "1000"};
export const slideDown = {"data-sal": "slide-down", "data-sal-duration": "1000"};
export const slideLeft = {"data-sal": "slide-left", "data-sal-duration": "1000"};
export const slideRight = {"data-sal": "slide-right", "data-sal-duration": "1000"};
export const zoomIn = {"data-sal": "zoom-in", "data-sal-duration": "1000"};
export const zoomOut = {"data-sal": "zoom-out", "data-sal-duration": "1000"};
export const flipUp = {"data-sal": "flip-up", "data-sal-duration": "1000"};
export const flipDown = {"data-sal": "flip-down", "data-sal-duration": "1000"};
export const flipLeft = {"data-sal": "flip-left", "data-sal-duration": "1000"};
export const flipRight = {"data-sal": "flip-right", "data-sal-duration": "1000"};
