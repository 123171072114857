import React, {useMemo} from "react";
import TransitionLink from "@components/TransitionLink";
import PropTypes from "prop-types";
import menu from "@config/menu";
import styled from "styled-components";
import {useLocation} from "@reach/router";


const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  // Never shows in mobile
  ${({theme}) => theme.breakpoints.mdMax} { display: none; }
`;

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

const ItemLink = styled(TransitionLink)`
  display: inline-block;
  font-size: 1.6rem;
  color: ${({$active, theme}) => $active ? theme.navActive : theme.navColor};
  font-weight: 400;
  text-decoration: none;
  transition: color ${({theme}) => theme.durations.basic};

  &:hover { color: ${({theme}) => theme.navActive}; }

  &:after {
    content: ${props => props.$hasSubmenu ? "''" : "none"};
    display: inline-block;
    width: .4em;
    height: .4em;
    border: 1px solid ${({theme}) => theme.black};
    border-top: none;
    border-left: none;
    transform: ${({$active}) => $active ? "rotate(45deg)" : "rotate(-45deg)"};
    margin-left: .3em;
    margin-bottom: .2em;
    transition: transform ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
  }
`;

export default function HeaderMenu (props) {
  const {updateSubmenu, hoverSubmenuIndex, setHoverSubmenuIndex} = props;
  const onMouseMoves = useMemo(() => menu.map(({submenu}, index) => () => {
    updateSubmenu(submenu ?? null);
    setHoverSubmenuIndex(index);
  }), [updateSubmenu]);
  const {pathname} = useLocation();

  return (
    <Root>
      {menu.map(({url, urlRoot, title, submenu}, index) => (
        <Item key={index} onMouseMove={onMouseMoves[index]}>
          <ItemLink to={url} $hasSubmenu={!!submenu} $active={(url === "/" ? url === pathname : pathname.startsWith(urlRoot ?? url)) || hoverSubmenuIndex === index}>{title}</ItemLink>
        </Item>
      ))}
    </Root>
  );
}

HeaderMenu.propTypes = {
  updateSubmenu: PropTypes.func.isRequired,
  hoverSubmenuIndex: PropTypes.number.isRequired,
};
