import PropTypes from "prop-types";
import React from "react";


export default class ScrollPoint extends React.PureComponent {

  rootRef = React.createRef();
  unmounted = false;
  isInScreen = false;
  isHitBottom = false;
  lastScreenPoint = null;

  componentDidMount () {
    this.tick();
  }

  componentWillUnmount () {
    this.unmounted = true;
  }

  tick = () => {
    if (this.unmounted) { return; }
    window.requestAnimationFrame(this.tick);
    if (!(this.rootRef.current instanceof Element)) { return; }
    const {top} = this.rootRef.current.getBoundingClientRect();

    if (top < window.innerHeight && top >= 0) {
      if (!this.isInScreen) {
        this.isInScreen = true;
        this.props.onScrollIn();
      }
    } else {
      if (this.isInScreen) {
        this.isInScreen = false;
        this.props.onScrollOut();
      }
    }

    const screenPoint = top / window.innerHeight;
    if (screenPoint !== this.lastScreenPoint) {
      this.lastScreenPoint = screenPoint;
      this.props.onScrollPointChange(screenPoint);
    }

    if (document.documentElement.scrollTop + window.innerHeight >= document.documentElement.scrollHeight) {
      if (!this.isHitBottom) {
        this.isHitBottom = true;
        this.props.onScrollToPageBottom();
      }
    } else {
      if (this.isHitBottom) { this.isHitBottom = false; }
    }
  };

  render () {
    const {elementType, onScrollIn, onScrollOut, onScrollPointChange, onScrollToPageBottom, ...restProps} = this.props;
    return React.createElement(elementType, {ref: this.rootRef, ...restProps});
  }
}

ScrollPoint.propTypes = {
  elementType: PropTypes.elementType.isRequired,
  onScrollIn: PropTypes.func.isRequired,
  onScrollOut: PropTypes.func.isRequired,
  onScrollPointChange: PropTypes.func.isRequired,
  onScrollToPageBottom: PropTypes.func.isRequired,
};

ScrollPoint.defaultProps = {
  elementType: "div",
  onScrollIn: () => {},
  onScrollOut: () => {},
  onScrollPointChange: () => {},
  onScrollToPageBottom: () => {},
};
