import expertAdvice from "../pages/expert-advice";

const routes = {

  // Index
  index: "/",

  // About
  about: "/about",
  cpm: "/about/cpm",
  beloteroHyaluronicAcid: "/about/belotero-hyaluronic-acid",
  beloteroRevive: "/about/belotero-revive",

  // Treatment result
  treatmentResult: "/treatment-result",
  ageing: "/treatment-result/ageing",
  safety: "/treatment-result/safety",
  treatmentEffect: "/treatment-result/treatment-effect",
  faq: "/treatment-result/faq",

  // Expert advice
  expertAdvice: "/expert-advice",
  blog1: "/expert-advice/blog1",
  blog2: "/expert-advice/blog2",
  blog3: "/expert-advice/blog3",

  // Media reports
  mediasChoice: "/medias-choice",

  // Certified medical center
  certifiedMedicalCenter: "/certified-medical-center#clinic-center",
  certifiedMedicalCenterClinicCenter: "/certified-medical-center/clinic-center",
  certifiedMedicalCenterReviveClinicCenter: "/certified-medical-center/revive-clinic-center",
};

export default routes;
