import PropTypes from "prop-types";
import React from "react";
import TransitionLink from "@components/TransitionLink";
import arrowsImage from "@images/certified-medical-center-arrows-image.svg";
import {rgba} from "polished";
import routes from "@config/routes";
import styled from "styled-components";
import {slideUp} from "@config/sal";


const Root = styled.div`
  text-align: center;
`;

const Inner = styled.div`
  display: inline-block;
  margin: 0 auto;
  background-color: #E2E2E2;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: .5rem;
  border: none;

  ${({theme}) => theme.breakpoints.xsMax} {
    box-shadow: none;
    background-color: transparent;
  }
`;

const Link = styled(TransitionLink)`
  position: relative;
  display: inline-block;
  height: 100%;
  font-size: 1.6rem;
  line-height: 1.4;
  text-decoration: none;
  padding: 2rem 3rem;
  padding-right: 5.8rem;
  color: ${({$active, theme}) => $active ? theme.white : "inherit"};
  background-color: ${({$active, $revive, theme}) => {
    if ($active) {
      if (!$revive) { return theme.productColors.orange; }
      return theme.productColors.cyan;
    }
    return "transparent";
  }};
  border-radius: .5rem;
  transition-property: color, background-color;
  transition-duration: ${({theme}) => theme.durations.basic};
  transition-timing-function: ${({theme}) => theme.easings.basic};
  cursor: pointer;

  ${({theme}) => theme.breakpoints.xsMax} {
    display: block;
    text-align: left;
    padding: 1.6rem;
    padding-right: 4.3rem;
    box-shadow: ${({$active, theme}) => !$active ? `inset 0px 4px 4px ${rgba(theme.black, .25)}` : "none"};

    &:not(:last-child) { margin-bottom: 1.6rem; }
  }

  ${({theme}) => theme.breakpoints.sm} {
    font-size: 1.9rem;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 2.8rem;
    height: 3.3rem;
    margin-top: -1.6rem;
    background: ${({$arrowsImage}) => `url(${$arrowsImage}) 50% / contain no-repeat`};
    opacity: ${({$active}) => $active ? "1" : "0"};
    transition: opacity ${({theme}) => theme.durations.basic + " " + theme.easings.basic};
  };
`;

export default function CenterSwitch (props) {
  const {showType} = props;
  return (
    <Root {...slideUp}>
      <Inner>
        <Link $active={showType === "clinic-center"} $arrowsImage={arrowsImage} to={routes.certifiedMedicalCenterClinicCenter}>
          BELOTERO® 透明質酸<br />填充療程所認證的醫療中心
        </Link>
        <Link $revive $active={showType === "revive-clinic-center"} $arrowsImage={arrowsImage} to={routes.certifiedMedicalCenterReviveClinicCenter}>
          BELOTERO® Revive 活化肌底<br />透明質酸療程所認證的醫療中心
        </Link>
      </Inner>
    </Root>
  );
}

CenterSwitch.propTypes = {
  showType: PropTypes.oneOf(["clinic-center", "revive-clinic-center"]).isRequired,
};
