import {Column, Row} from "@components/Row";
import React, {useState} from "react";
import CompareSlider from "@components/CompareSlider";
import Container from "@components/Container";
import H1Component from "@components/H1";
import LinkButton from "@components/LinkButton";
import Reference from "@components/Reference";
import SectionHeader from "@components/SectionHeader";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";

import successBalanceBefore from "@images/success-balance-before.jpg";
import successBalanceAfter from "@images/success-balance-after.jpg";
import freshBefore from "@images/freshBefore.jpg";
import freshAfter from "@images/freshAfter.jpg";
import smoothBefore from "@images/smoothBefore.jpg";
import smoothAfter from "@images/smoothAfter.jpg";
import HeroMichelle from "./HeroMichelle";

const H1 = styled(H1Component)`

  ${({theme}) => theme.breakpoints.sm} {
    margin-bottom: 0;
  }
`;

const H3 = styled.h3`
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 8vw;
  }
`;

const Section = styled.div`
  background-color: ${({$colored}) => $colored ? "#C0EFEC" : "inherit"};
  padding-top: 1rem;
  padding-bottom: 3rem;
  text-align: center;

  &:before {
    content: '';
    display: block;
  }
`;

const Paragraph = styled.p`
  margin-top: 0;
`;

const Spacer = styled.div`
  margin-top: ${({$marginTop}) => $marginTop ? $marginTop + "px" : "20px"};
`;

const Ref = styled(Reference)`
  @media (max-width: 768px) {
    text-align: left;
  }
`;

export default function HomePageSection() {
    const [position1, setPosition1] = useState(50);
    const [position2, setPosition2] = useState(50);
    const [position3, setPosition3] = useState(50);

    return (
        <>
            <Section>
                <Container>
                    <H1 {...slideUp}>認識 BELOTERO®無痕透明質酸彩虹針</H1>

                    <p {...slideUp}>
                        {`
                            任何人都希望獲得輕鬆自然的美，流露真我而不著痕跡，時常散發自信光芒。
                            瑞士製造的BELOTERO® 無痕透明質酸彩虹針，正符合香港女性想變靚卻又不想動刀、不想被察覺、想悄悄變美的需要。
                            BELOTERO®一系列5款透明質酸填充劑，由改善膚質、填補不同程度皺紋至重塑面部輪廓，貼心迎合不同臉上部位和注射層面需要。
                        `}
                    </p>

                    <Spacer $marginTop={40}/>

                    <Row>
                        <Column $sm={6}>
                            <SectionHeader {...slideUp}>美得自然、簡單！打造與理想零距離貼近的無痕美態</SectionHeader>

                            <Paragraph {...slideUp}>
                                BELOTERO®透明質酸彩虹針系列通過歐盟CE及美國FDA認可<sup>*</sup>。採用獨有CPM（多密度凝聚）技術，
                                完美平衡透明質酸的凝聚度、黏稠度、彈性及可塑性四大特點，塑造出均衡比例，確保與肌膚良好融合<sup>1</sup>，配合特定皮膚層的不同需要。
                                只要找到對的方法，無論任何年齡、任何狀態，BELOTERO®都可以令您擁有最美輪廓，時刻展現出最佳自信狀態，散發年輕活力光芒。
                            </Paragraph>

                            <LinkButton to={routes.beloteroHyaluronicAcid} {...slideUp}>
                                立即認識BELOTERO® 透明質酸彩虹針
                            </LinkButton>
                        </Column>

                        <Column $sm={6}>
                            <SectionHeader {...slideUp}>
                                透明質酸補水填充功效多|任何年齡都能擁有 MOCHI Q 彈肌
                            </SectionHeader>
                            <h3 {...slideUp}>BELOTERO®透明質酸彩虹針療程可以改善什麼肌膚問題？</h3>
                            <p {...slideUp}>
                                BELOTERO® 一系列5款透明質酸彩虹針照顧不同年齡層的肌膚需要及理想塑顏效果。
                                有效平滑膚質、保濕盈潤、提升輪廓及立體，療程效果自然持久<sup>2⁻3^</sup>，時刻展現最佳自信美態。
                            </p>
                        </Column>
                    </Row>

                    <Spacer/>

                    <Ref {...slideUp}>{
                            `* BELOTERO Balance is approved by the US Food and Drug Administration (FDA).
1. Tran C, et al. Dermatology. 2014;228(1):47-54.
2. Fischer TC, et al. Facial Plast Surg. 2016 Jun;32(3):283-8.
3. Kerscher M, et al. Clin Cosmet Investig Dermatol. 2017;10:239-47.
^Treatment longevity depends on the BELOTERO® product used and individual results may vary.
Effect of BELOTERO Volume has been shown to last up to 18 months5.`}
                    </Ref>
                    <br/>

                    <LinkButton
                        to={routes.certifiedMedicalCenterClinicCenter}>立即了解BELOTERO®認證的醫療中心</LinkButton>
                </Container>

            </Section>

        </>
    );
};