import {Column, Row} from "@components/Row";
import BulletPoints from "@components/BulletPoints";
import Center from "@components/Center";
import Container from "@components/Container";
import H1 from "@components/H1";
import LinkButton from "@components/LinkButton";
import React from "react";
import Spacer from "@components/Spacer";
import bulletIcon1 from "@images/ageing-bullet-icon-1.png";
import bulletIcon2 from "@images/ageing-bullet-icon-2.png";
import bulletIcon3 from "@images/ageing-bullet-icon-3.png";
import bulletIcon4 from "@images/ageing-bullet-icon-4.png";
import bulletIcon5 from "@images/ageing-bullet-icon-5.png";
import bulletIcon6 from "@images/ageing-bullet-icon-6.png";
import model1Xs from "@images/ageing-model-3-xs.png";
import model1 from "@images/ageing-model-3.png";
import model2Xs from "@images/ageing-model-4-xs.png";
import model2 from "@images/ageing-model-4.png";
import routes from "@config/routes";
import {slideUp} from "@config/sal";


export default function Ageing () {
  return (
    <Container.Small>
      <H1 {...slideUp}>認識衰老</H1>
      <Row>
        <Column $sm={5}>
          <img src={model1Xs} srcSm={model1} alt="Model 1" {...slideUp} data-sm-display-none />
          <img src={model1} srcSm={model1} alt="Model 1" {...slideUp} data-xs-max-display-none />
        </Column>
        <Column $sm={.5} />
        <Column $sm={6.5}>
          <h2 {...slideUp}>認識肌膚衰老的三大因素</h2>
          <p {...slideUp}>透明質酸是我們肌膚內的天然保濕因子，它能夠鎖住水分，讓皮膚保持水潤緊緻。</p>
          <p {...slideUp}>隨著時間的推移，我們肌膚內的天然透明質酸就會逐步減少，從而出現細紋和皺紋。即使塗抹護膚品，當中透明質酸的成分亦難以滲透入皮膚底層。 </p>
          <p {...slideUp}>皮膚自然衰老的過程的三個因素：</p>
          <BulletPoints
            items={[
              {image: bulletIcon1, text: "皮膚缺水會影響膚質，使肌膚失去彈性和光澤"},
              {image: bulletIcon2, text: "面部容積減少會引致皮膚鬆弛及產生細紋及皺紋"},
              {image: bulletIcon3, text: "面部的骨骼變化會改變輪廓及影響結構的支撐力"},
            ]}
          />
        </Column>
      </Row>
      <Spacer $sm="4em" />
      <Row>
        <Column $sm={7}>
          <h2 {...slideUp}>BELOTERO® 系列透明質酸產品<br data-xs-max-display-none />就是滿足您肌膚需要的個人化方案</h2>
          <p {...slideUp}>BELOTERO® 透明質酸產品可以解決不同的肌膚問題，讓您重拾水嫩和青春的外觀 :</p>
          <BulletPoints
            items={[
              {image: bulletIcon4, text: "強效補濕，改善皮膚緊緻度，提升肌膚彈性，讓肌膚煥發光彩。"},
              {image: bulletIcon5, text: "撫平細紋及皺紋，使您輕鬆展現年輕容顏。"},
              {image: bulletIcon6, text: "改善面部凹陷，重塑立體輪廓，讓您擁有煥發活力的外觀。"},
            ]}
          />
        </Column>
        <Column $sm={5}>
          <img src={model2Xs} alt="Model 2" {...slideUp} data-sm-display-none />
          <img src={model2} alt="Model 2" {...slideUp} data-xs-max-display-none />
        </Column>
      </Row>

      <br /><br /><br /><br />
      <Center>
        <BulletPoints.Inline items={[{text: <h3>對於追求自然和減淡衰老的您， BELOTERO® 產品系列就是您的選擇！</h3>}]} />
        <br /><br /><br />
        <LinkButton {...slideUp} to={routes.safety}>立即了解 BELOTERO® 療程效果及安全性</LinkButton>
      </Center>
    </Container.Small>
  );
}
