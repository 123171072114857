import {useStaticQuery, graphql} from "gatsby";

export const AllList = () => {
    const data = useStaticQuery(graphql`
    query {
        allGoogleHongKongIslandSheet {
          nodes {
            clinicName
            address
            phoneNum
            altNum
          }
        }
        allGoogleKowloonSheet {
          nodes {
            clinicName
            address
            phoneNum
            altNum
          }
        }
        allGoogleNewTerritoriesSheet {
          nodes {
            clinicName
            address
            phoneNum
            altNum
          }
        }
        allGoogleReviveHongKongIslandSheet {
            nodes {
              clinicName
              address
              phoneNum
              altNum
            }
          }
        allGoogleReviveKowloonSheet {
          nodes {
            clinicName
            address
            phoneNum
            altNum
          }
        }
        allGoogleReviveNewTerritoriesSheet {
          nodes {
            clinicName
            address
            phoneNum
            altNum
          }
        }     
      }
  `)

    const clinicList = {
        hongkongIsland: data.allGoogleHongKongIslandSheet,
        kowloon: data.allGoogleKowloonSheet,
        newTerritories: data.allGoogleNewTerritoriesSheet,
    }

    const reviveList = {
        hongkongIsland: data.allGoogleReviveHongKongIslandSheet,
        kowloon: data.allGoogleReviveKowloonSheet,
        newTerritories: data.allGoogleReviveNewTerritoriesSheet,
    }

    const all = [clinicList, reviveList];

    return all;
}