import React, {useCallback, useEffect, useRef, useState} from "react";
import Container from "@components/Container";
import H1 from "@components/H1";
import PropTypes from "prop-types";
import styled from "styled-components";
import {slideUp} from "@config/sal";


const faqItems = [
  {
    question: "BELOTERO®透明質酸療程如何發揮作用？",
    answer: "人體自身的天然透明質酸能夠鎖著水份，讓皮膚保持水潤緊緻。但是隨著年齡增長，天然的透明質酸會漸漸流失，肌膚便愈見鬆弛。BELOTERO®透明質酸能夠填充及撫平細紋、皺紋、暗瘡疤痕及其他肌膚問題。完成療程後，效果一般可即時顯現。",
  },
  {
    question: "BELOTERO®透明質酸療程可以撫平什麼皺紋？",
    answer: "BELOTERO®透明質酸系列療程可以撫平多種皺紋。\n-BELOTERO®Soft：改善輕微細紋\n-BELOTERO® Balance: 改善中度皺紋，如中度法令紋、嘴部細紋、嘴部輪廓、人中\n-BELOTERO® Intense: 改善深度皺紋，如嚴重法令紋、嘴角紋木偶紋、豐盈嘴唇\n-BELOTERO® Volume: 重塑面部輪廓，如太陽穴、蘋果肌、面頰、下巴、面部凹陷",
  },
  {
    question: "療程後面部表情會不會顯得不自然？",
    answer: "療程後能使你看起來煥然一新，精神飽滿，並能夠如常展現自己表情，做回自己。",
  },
  {
    question: "療程需要多少時間？",
    answer: "在您首次與醫生會面時，會與醫生討論你的個人需要。視乎不同療程部位，療程一般只需15-45分鐘便可以。",
  },
  {
    question: "療程效果可以維持多久？",
    answer: "BELOTERO®透明質酸填充劑是各有不同的持效期的。BELOTERO®Soft 持效4-6個月、BELOTERO®Balance 持效6-9個月、BELOTERO®Intense 持效9-12個月、BELOTERO®Volume 持效12-18個月。\n此外，療程的持效時間長短亦取決於不同的個人因素，例如年齡、皮膚質素、個人生活習慣、肌肉活動等。",
  },
  {
    question: "BELOTERO®透明質酸療程會不會有任何副作用呢？",
    answer: "BELOTERO®透明質酸療程有可能引起短暫性的副作用，例如注射部位皮膚變色、輕微疼痛、紅腫、充血或瘀青。一般而言，這些較常見的不適感會於療程後幾天後消散。如對 BELOTERO®透明質酸療程有任何疑問，歡迎向醫生查詢。",
  },
];

const Item = styled.div`
  font-size: 1.8rem;
  line-height: 1.8;
  border-bottom: 1px solid ${({theme}) => theme.gray500};
  margin-bottom: 1rem;
`;

const Ask = styled.div`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: ${({theme}) => theme.gray700};
  font-size: ${({$open, theme}) => $open ? "2.8rem" : theme.bodyFontSize};
  transition: font-size ${({theme}) => theme.durations.basic + " " + theme.easings.basic};
  cursor: pointer;

  ${({theme}) => theme.breakpoints.xsMax} {
    padding-right: 2rem;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 2rem;
    height: .3rem;
    margin-top: .15rem;
    background-color: ${({$index, theme}) => {
      const colors = theme.productColorsLinear;
      return colors[$index % colors.length];
    }};
  }

  &:before {
    transition: transform ${({theme}) => theme.durations.basic + " " + theme.easings.basic};
    transform: ${({$open}) => $open ? "rotate(90deg)" : "none"};
  }
`;

const Answer = styled.div`
  overflow: hidden;
  transition: height ${({theme}) => theme.durations.basic + " " + theme.easings.basic};
`;

const AnswerInner = styled.div`
  padding-bottom: 1rem;
  white-space: pre-wrap;
`;

function Question (props) {
  const {question, answer, index} = props;
  const answerInnerRef = useRef();
  const [open, setOpen] = useState(false);
  const [answerHeight, setAnswerHeight] = useState(0);
  const toggleOpen = useCallback(() => setOpen(prevOpen => !prevOpen), []);

  useEffect(() => {
    setAnswerHeight(open ? answerInnerRef.current.getBoundingClientRect().height : 0);
  }, [open]);
  return (
    <Item $open={open} {...slideUp}>
      <Ask $open={open} $index={index} onClick={toggleOpen}>{question}</Ask>
      <Answer style={{height: answerHeight}}>
        <AnswerInner ref={answerInnerRef}>{answer}</AnswerInner>
      </Answer>
    </Item>
  );
}

Question.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default function Faq () {
  return (
    <>
      <H1>常見問題</H1>
      <Container>
        {faqItems.map(({question, answer}, index) => (
          <Question key={index} index={index} question={question} answer={answer} />
        ))}
      </Container>
    </>
  );
}
