import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import merzLogo from "@images/merz-menu-merz-logo.png";
import radiesseLogo from "@images/merz-menu-radiesse-logo.png";
import ultherapyLogo from "@images/merz-menu-ultherapy-logo.png";
import xeominLogo from "@images/merz-menu-xeomin-logo.png";


const Root = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 40rem;
  background-color: ${({theme}) => theme.black};
  padding: 2rem 0;
  transform: ${({$active}) => $active ? "none" : "translateY(-100%)"};
  opacity: ${({$active}) => $active ? "1" : "0"};
  transition: transform ${({theme}) => theme.durations.basic + " " + theme.easings.basic}, opacity ${({$active, theme}) => "0s " + theme.easings.basic + " " + ($active ? "0s" : theme.durations.basic)};
  z-index: ${({theme}) => theme.zIndexes.merzSubmenu};

  ${({theme}) => theme.breakpoints.lg} {
    justify-content: space-around;
  }

  ${({theme}) => theme.breakpoints.mdMax} {
    position: fixed;
    top: 0;
    left: ${({$active}) => $active ? "0%" : "100%"};
    width: 100%;
    height: 100vh;
    padding: 0;
    transform: none;
    transition: left ${({theme}) => theme.durations.basic + " " + theme.easings.basic}, left ${({theme}) => theme.durations.basic + " " + theme.easings.basic}, opacity ${({$active, theme}) => "0s " + theme.easings.basic + " " + ($active ? "0s" : theme.durations.basic)};
    z-index: ${({theme}) => theme.zIndexes.merzSubmenuXsMax};

    &:before {
      content: '';
      display: block;
      padding-top: 4rem;
    }
  }
`;

const Item = styled.a`
  display: inline-block;
  text-align: center;

  ${({theme}) => theme.breakpoints.mdMax} {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
  }
`;

const MobileCross = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 3.6rem;
  height: 3.6rem;

  &:before, &:after {
    content: '';
    position: absolute;
    width: .3rem;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -.1rem;
    background-color: ${({theme}) => theme.white};
  }

  &:before { transform: rotate(45deg); }
  &:after { transform: rotate(135deg); }

  // Only showing in mobile
  ${({theme}) => theme.breakpoints.lg} { display: none; }
`;

export default function MerzSubmenu (props) {
  const {open, openMerzSubmenu, closeMerzSubmenu} = props;
  return (
    <Root $active={open} onMouseMove={openMerzSubmenu} onMouseLeave={closeMerzSubmenu}>
      <MobileCross onClick={closeMerzSubmenu} />
      <Item href="https://www.merz.com.hk/"><img src={merzLogo} alt="MERZ logo" /></Item>
      <Item href="http://www.ultherapy.com.hk/"><img src={ultherapyLogo} alt="Ultherapy logo" /></Item>
      <Item href="https://www.xeominhk.com/"><img src={xeominLogo} alt="Xeomin logo" /></Item>
      <Item href="https://www.radiesse.com.hk/"><img src={radiesseLogo} alt="Radiesse logo" /></Item>
    </Root>
  );
}

MerzSubmenu.propTypes = {
  closeMerzSubmenu: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openMerzSubmenu: PropTypes.func.isRequired,
};
