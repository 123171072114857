import {math} from "polished";
import styled from "styled-components";


export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${({$noPadding, theme}) => !$noPadding ? math(theme.containerPadding + " * -1") : 0};
  margin-left: ${({$noPadding, theme}) => !$noPadding ? math(theme.containerPadding + " * -1") : 0};

  align-items: ${({$alignItems}) => !$alignItems ? "unset" : $alignItems};
  justify-content: ${({$justifyContent}) => !$justifyContent ? "unset" : $justifyContent};


  @media (max-width: 768px) {
    flex-direction: ${({$toColumn}) => !$toColumn ? "unset" : "column"};
    align-items: ${({$columnAlignItems}) => !$columnAlignItems ? "unset" : $columnAlignItems};
`;

export const Column = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: ${({$noPadding, theme}) => !$noPadding ? theme.containerPadding : 0};
  padding-left: ${({$noPadding, theme}) => !$noPadding ? theme.containerPadding : 0};

  display: ${({$display}) => !$display ? "initial" : $display};
  flex-direction: ${({$flexDirection}) => !$flexDirection ? "column" : $flexDirection};
  align-items: ${({$alignItems}) => !$alignItems ? "unset" : $alignItems};
  justify-content: ${({$justifyContent}) => !$justifyContent ? "unset" : $justifyContent};
  margin: ${({$margin}) => !$margin ? 0 : $margin};

  @media (max-width: 768px) {
    width: ${({$widthInColumn}) => $widthInColumn ? $widthInColumn : "unset"};
  }
  
  ${({$col}) => typeof $col === "number" ? `flex: 0 0 auto; width: ${100 * ($col / 12)}%;` : ""};

  ${({
       $sm,
       theme
     }) => typeof $sm === "number" ? `${theme.breakpoints.sm} { flex: 0 0 auto; width: ${100 * ($sm / 12)}%; }` : ""}
  ${({
       $md,
       theme
     }) => typeof $md === "number" ? `${theme.breakpoints.md} { flex: 0 0 auto; width: ${100 * ($md / 12)}%; }` : ""}
  ${({
       $lg,
       theme
     }) => typeof $lg === "number" ? `${theme.breakpoints.lg} { flex: 0 0 auto; width: ${100 * ($lg / 12)}%; }` : ""}
  ${({
       $xl,
       theme
     }) => typeof $xl === "number" ? `${theme.breakpoints.xl} { flex: 0 0 auto; width: ${100 * ($xl / 12)}%; }` : ""}
  ${({
       $xxl,
       theme
     }) => typeof $xxl === "number" ? `${theme.breakpoints.xxl} { flex: 0 0 auto; width: ${100 * ($xxl / 12)}%; }` : ""}

  ${({
       $xsMax,
       theme
     }) => typeof $xsMax === "number" ? `${theme.breakpoints.xsMax} { flex: 0 0 auto; width: ${100 * ($xsMax / 12)}%; }` : ""}
  ${({
       $smMax,
       theme
     }) => typeof $smMax === "number" ? `${theme.breakpoints.smMax} { flex: 0 0 auto; width: ${100 * ($smMax / 12)}%; }` : ""}
  ${({
       $mdMax,
       theme
     }) => typeof $mdMax === "number" ? `${theme.breakpoints.mdMax} { flex: 0 0 auto; width: ${100 * ($mdMax / 12)}%; }` : ""}
  ${({
       $lgMax,
       theme
     }) => typeof $lgMax === "number" ? `${theme.breakpoints.lgMax} { flex: 0 0 auto; width: ${100 * ($lgMax / 12)}%; }` : ""}
  ${({
       $xlMax,
       theme
     }) => typeof $xlMax === "number" ? `${theme.breakpoints.xlMax} { flex: 0 0 auto; width: ${100 * ($xlMax / 12)}%; }` : ""}
`;

Row.Column = Column;
export default Row;
