import PropTypes from "prop-types";
import React from "react";
import BasicTabs from "./ClinicListBuilder";
import {AllList} from "../../page-function/getClinicLists";

export default function ClinicTable (props) {
  const {district, showType} = props;
  console.log("showType: ", showType);

  let listIndex;
  if (showType === "clinic-center") {
    listIndex = 0;
  }
  if (showType === "revive-clinic-center") {
    listIndex = 1;
  }

  const allClinicList = AllList();

  return (
    <>
      <BasicTabs allClinicList={allClinicList[listIndex]} showType={showType}/>
    </>
  );
}

ClinicTable.propTypes = {
  showType: PropTypes.oneOf(["clinic-center", "revive-clinic-center"]).isRequired,
  district: PropTypes.oneOf(["香港", "九龍", "新界"]).isRequired,
};
