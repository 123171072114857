import {linearGradient, rgba} from "polished";
import {keyframes} from "styled-components";


// Font
export const fontFamily = "'Noto Sans TC', sans-serif";
export const secondaryFontFamily = "'Montserrat', sans-serif";

// Basic
export const black = "#000000";
export const white = "#FFFFFF";
export const gray = "#696868";

export const gray100 = "#F0EFEE";
export const gray200 = "#E9ECEF";
export const gray300 = "#DEE2E6";
export const gray400 = "#CED4DA";
export const gray500 = "#9E9E9E";
export const gray600 = gray;
export const gray700 = "#5E5E5E";
export const gray800 = "#343A40";
export const gray900 = "#212529";

// Header
export const headerColor = gray700;
export const headerOrange = "#FF7900";

// Product colors
export const productColors = {
  cyan: "#5AD0C8",
  yellow: "#FEE100",
  orange: "#EE7700",
  magenta: "#E50074",
  purple: "#802081",
};

export const productColorsLinear = [
  productColors.cyan,
  productColors.yellow,
  productColors.orange,
  productColors.magenta,
  productColors.purple,
];

export const productColorLine = linearGradient({
  colorStops: productColorsLinear.reduce((acc, curr, index, arr) => {
    const length = arr.length;
    const start = curr + " " + Math.max(0, (index * 100 / length) + 0) + "%";
    const end = curr + " " + Math.min(100, ((index + 1) * 100 / length) - 0) + "%";
    return [...acc, start, end];
  }, []),
  fallback: white,
  toDirection: "to right",
});

export const productColorLineBlury = linearGradient({
  colorStops: productColorsLinear.reduce((acc, curr, index, arr) => {
    const length = arr.length;
    const start = curr + " " + Math.max(0, (index * 100 / length) + 0.2) + "%";
    const end = curr + " " + Math.min(100, ((index + 1) * 100 / length) - 0.2) + "%";
    return [...acc, start, end];
  }, []),
  fallback: white,
  toDirection: "to right",
});

export const transitionAnimationBackgroundAttributes = linearGradient({
  colorStops: productColorsLinear.reduce((acc, curr, index, arr) => {
    const length = arr.length;
    const start = curr + " " + Math.max(0, (index * 100 / length) + 0) + "%";
    const end = curr + " " + Math.min(100, ((index + 1) * 100 / length) - 0) + "%";
    return [...acc, start, end];
  }, []),
  fallback: white,
  // toDirection: "to right",
});

export const easings = {
  basic: "ease",
};

export const durations = {
  basic: "0.4s",
  slow: "3s",
};

export const animations = {
  floating: keyframes`
    0%, 100% { transform: translateY(3%) scale(1.03); }
    30% { transform: translateY(-4%) scale(1.03); }
    50% { transform: translateY(2%); }
    60% { transform: translateY(3%) scale(0.97); }
    80% { transform: translateY(0%) scale(0.97); }
    90% { transform: translateY(-3%); }
  `,
  spinning: keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `,
  fadeIn: keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `,
};

/**
 * Breakpoints
 */
const sm = 576;
const md = 768;
const lg = 992;
const xl = 1200;
const xxl = 1400;
const xsMax = sm - 1;
const smMax = md - 1;
const mdMax = lg - 1;
const lgMax = xl - 1;
const xlMax = xxl - 1;


export const breakpointWidths = {sm, md, lg, xl, xxl, xsMax, smMax, mdMax, lgMax, xlMax};

/**
 * @example
 * const Nav = styled.div`
 * font-size: 10px;
 *
 *   ${breakpoint.lg} {
 *     font-size: 16px;
 *   }
 * `;
 */
export const breakpoints = {
  // As min width
  sm: `@media screen and (min-width: ${sm}px)`,
  md: `@media screen and (min-width: ${md}px)`,
  lg: `@media screen and (min-width: ${lg}px)`,
  xl: `@media screen and (min-width: ${xl}px)`,
  xxl: `@media screen and (min-width: ${xxl}px)`,
  xsMax: `@media screen and (max-width: ${xsMax}px)`,
  smMax: `@media screen and (max-width: ${smMax}px)`,
  mdMax: `@media screen and (max-width: ${mdMax}px)`,
  lgMax: `@media screen and (max-width: ${lgMax}px)`,
  xlMax: `@media screen and (max-width: ${xlMax}px)`,
};

// Nav
export const navColor = black;
export const navActive = "#FF7900";
export const navSubheaderColor = gray700;

const theme = {
  bodyFontSize: "1.7rem",
  bodyFontFamily: fontFamily,

  fontFamily,
  secondaryFontFamily,

  headerColor,
  headerOrange,

  black,
  white,
  gray,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray800,
  gray900,

  // boxShadow: `0 .4rem .4rem ${rgba(black, .25)}`,
  boxShadow: "rgb(0 0 0 / 12%) 0 0.4rem 2rem",

  headerBackground: white,
  headerHeight: "8.7rem",
  headerHeightXsMax: "5rem",

  productColors,
  productColorsLinear,
  productColorLine,
  productColorLineBlury,

  navColor,
  navActive,
  navSubheaderColor,

  container: "112rem",
  containerLarge: "160rem",
  containerSmall: "99rem",
  containerPadding: "1rem",

  // Different left and right padding is applied in mobile
  containerPaddingXsMax: "3rem",

  easings,
  durations,

  animations,

  breakpointWidths,
  breakpoints,

  transitionAnimationBackgroundAttributes,

  zIndexes: {
    header: 7777,
    merzSubmenu: 6666,
    headerSubmenu: 6666,
    findCertifiedMedicalCenter: 8888,
    headerXsMax: 9955,
    merzSubmenuXsMax: 9999, // In mobile, merz menu covers everything

    transitionAnimationCover: 9999999, // Over everything
  },
};

export default theme;
