import React from "react";
import styled from "styled-components";


const Root = styled.div`
  position: relative;
  height: 7px;
  ${({theme}) => theme.productColorLine}
`;

export default function ProductColorLine (props) {
  return (<Root {...props} />);
};
