import styled from "styled-components";


const Container = styled.div`
  position: relative;
  max-width: ${({$maxWidth, theme}) => typeof $maxWidth !== "undefined" ? $maxWidth : theme.container};
  margin: 0 auto;
  padding-left: ${({$normalPadding, theme}) => $normalPadding ? theme.containerPadding : theme.containerPaddingXsMax};
  padding-right: ${({$normalPadding, theme}) => $normalPadding ? theme.containerPadding : theme.containerPaddingXsMax};

  ${({theme}) => theme.breakpoints.sm} {
    padding-left: ${({theme}) => theme.containerPadding};
    padding-right: ${({theme}) => theme.containerPadding};
  }
`;

export const Small = styled(Container)`
  max-width: ${({theme}) => theme.containerSmall};
`;

export const Large = styled(Container)`
  max-width: ${({theme}) => theme.containerLarge};
`;

export const Fluid = styled(Container)`
  max-width: initial;
`;

Container.Small = Small;
Container.Large = Large;
Container.Fluid = Fluid;
export default Container;
