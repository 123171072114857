import "normalize.css";
import "react-modal-video/scss/modal-video.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {createGlobalStyle} from "styled-components";


export default createGlobalStyle`

html {
  font-size: .625rem;
}

body {
  font-size: ${({theme}) => theme.bodyFontSize};
  font-family: ${({theme}) => theme.bodyFontFamily};
}

h1 {
  font-weight: 700;
  font-size: 4.4rem;
  line-height: 1.4;
  letter-spacing: .7rem;
  font-family: ${({theme}) => theme.bodyFontFamily};
  text-align: center;
  color: ${({theme}) => theme.headerColor};
  margin-top: 4rem;
  margin-bottom: 7rem;

  &:after {
    content: '';
    display: block;
    max-width: 81rem;
    height: .3rem;
    margin: 1rem auto 0;
    margin-top: 2rem;
    ${({theme}) => theme.productColorLine}
  }

  ${({theme}) => theme.breakpoints.xsMax} {
    font-size: 2.8rem;
    letter-spacing: .3rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

h2 {
  font-weight: 700;
  font-size: 3.3rem;
  color: ${({theme}) => theme.headerOrange};

  ${({theme}) => theme.breakpoints.xsMax} {
    font-size: 2.8rem;
    letter-spacing: .3rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

h3 {
  margin: 0;
  font-size: 1.7rem;
  color: ${({theme}) => theme.gray700};
  line-height: 1.4;
  font-weight: 700;
}

p {
  font-size: 1.7rem;
  line-height: 1.8;

  ${({theme}) => theme.breakpoints.xsMax} {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}

img {
  display: block;
  max-width: 100%;
}

* {
  letter-spacing: .1em;
}

sup {
  transform: scale(3);
}

.modal-video-close-btn {
  cursor: pointer;
}

${({theme}) => theme.breakpoints.sm} { [data-sm-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.md} { [data-md-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.lg} { [data-lg-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.xl} { [data-xl-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.xxl} { [data-xxl-display-none] { display: none !important; } }

${({theme}) => theme.breakpoints.xsMax} { [data-xs-max-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.smMax} { [data-sm-max-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.mdMax} { [data-md-max-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.lgMax} { [data-lg-max-display-none] { display: none !important; } }
${({theme}) => theme.breakpoints.xlMax} { [data-xl-max-display-none] { display: none !important; } }

/**
 * Slick Slider
 */
.slick-slider {
  //

  .slick-arrow {
    top: 105%;
    width: 2rem;
    height: 2rem;
    margin-top: -.5rem;
    transform: none;
    z-index: 19;

    &.slick-prev { left: 20%; transform: rotate(180deg); }
    &.slick-next { left: initial; right: 20%; }

    &:before, &:after {
      content: '';
      display: block;
      margin-left: auto;
      margin-right: 0;
      background-color: ${({theme}) => theme.gray700};
      width: .1rem;
      height: 50%;
    }

    &:before {
      transform-origin: 100% 100%;
      transform: rotate(-45deg);
    }

    &:after {
      transform-origin: 100% 0;
      transform: rotate(45deg);
    }
  }

  .slick-dots {
    top: 105%;
    width: 100%;
    display: block;
    bottom: initial;
    height: 1rem;

    > li {
      width: initial;
      height: initial;
      font-size: 1rem;
      vertical-align: top;

      > button {
        padding: 0;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;

        &:before { content: none; }
      }

      ${({theme}) => theme.productColorsLinear.map((color, index, arr) => {
        const length = arr.length;
        return `
          &:nth-child(${length}n+${index + 1}) button { border: .2rem solid ${color}; }
          &.slick-active:nth-child(${length}n+${index + 1}) button { background-color: ${color}; }
        `;
      }).join("")}
    }
  }
}
`;
