import React from "react";

import reviveImg from "@images/success-stories-revive-handle.png";
import softImg from "@images/success-stories-soft-handle.png";
import balanceImg from "@images/success-stories-balance-handle.png";
import intenseImg from "@images/success-stories-intense-handle.png";
import volumeImg from "@images/success-stories-volume-handle.png";
import Reference from "../../components/Reference";
import styled from "styled-components";
import {slideUp} from "../../config/sal";

const Table = styled.table`
  border-collapse: collapse;
`;

const TableDataTop1 = styled.td`
  border-top: 2px solid rgb(222, 223, 223);
  border-right: 2px solid rgb(222, 223, 223);
  border-bottom: 2px solid rgb(222, 223, 223);
  font-size: 16px;
  max-width: 200px;
`;

const TableDataTop2 = styled.td`
  border-top: 2px solid rgb(222, 223, 223);
  border-right: 2px solid rgb(222, 223, 223);
  border-bottom: 2px solid rgb(222, 223, 223);
  font-size: 16px;
  padding: 0 5px;
`;

const TableDataTop3 = styled.td`
  border-top: 2px solid rgb(222, 223, 223);
  border-bottom: 2px solid rgb(222, 223, 223);
  font-size: 16px;
  padding: 0 5px;
`

const TableDataFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
`;

const HandleImg = styled.img`
  width: 35px;
  margin: 0 auto;
`;

const TableHeader = styled.th`
  text-align: center;
  background-color: rgb(223, 126, 47);
  color: white;

  padding: 10px 5px;
`;

const TableHeaderMiddle = styled(TableHeader)`
  border-left: 2px solid white;
  border-right: 2px solid white;
`;

const ProductName = styled.span`
  text-transform: uppercase;
  color: ${({$colored}) => $colored ? $colored : ""};
  font-size: 20px;
  font-weight: bold;
  width: 100px;
  letter-spacing: 0.04rem;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const Ref = styled(Reference)`
  color: gray;
`;

const MarginWrapper = styled.div`
  margin: 10px 0;

  @media (max-width: 768px) {
    margin: 10px;
  }
`;

const Spacer = styled.div`
  margin-top: ${({$marginTop}) => $marginTop ? $marginTop + "px" : "20px"};
`;

const BannerTable = () => {

    return (
        <div>
            <FlexWrapper>
                <Table {...slideUp}>
                    <thead>
                    <tr>
                        <TableHeader>BELOTERO® 產品</TableHeader>
                        <TableHeaderMiddle>針對部位</TableHeaderMiddle>
                        <TableHeader>注射皮膚層面</TableHeader>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <TableDataTop1>
                            <TableDataFlex>
                                <ProductName $colored="#00aeae">Revive</ProductName>
                                <HandleImg src={reviveImg} alt="Belotero Revive"/>
                            </TableDataFlex>

                        </TableDataTop1>
                        <TableDataTop2>
                            活化肌底
                            <ul>
                                <li>改善皮膚水潤</li>
                                <li>提升肌膚彈性及緊緻度<sup>1,2</sup></li>
                                <li>撫平細紋及表淺皺紋<sup>1</sup></li>
                            </ul>
                        </TableDataTop2>
                        <TableDataTop3>深層真皮層</TableDataTop3>
                    </tr>

                    <tr>
                        <TableDataTop1>
                            <TableDataFlex>
                                <ProductName $colored="#fad726">soft</ProductName>
                                <HandleImg src={softImg} alt="Belotero Soft"/>
                            </TableDataFlex>
                        </TableDataTop1>

                        <TableDataTop2>改善輕微細紋，如嘴周細紋</TableDataTop2>
                        <TableDataTop3>淺層皮膚</TableDataTop3>
                    </tr>

                    <tr>
                        <TableDataTop1>
                            <TableDataFlex>
                                <ProductName $colored="#e65921">balance</ProductName>
                                <HandleImg src={balanceImg} alt="Belotero Balance"/>
                            </TableDataFlex>
                        </TableDataTop1>
                        <TableDataTop2>
                            改善中度皺紋：
                            <ul>
                                <li>中度法令紋</li>
                                <li>中度木偶紋</li>
                                <li>嘴部細紋</li>
                                <li>塑造唇部輪廓</li>
                            </ul>
                        </TableDataTop2>
                        <TableDataTop3>淺至中層皮膚</TableDataTop3>
                    </tr>

                    <tr>
                        <TableDataTop1>
                            <TableDataFlex>
                                <ProductName $colored="#c81166">intense</ProductName>
                                <HandleImg src={intenseImg} alt="Belotero Intense"/>
                            </TableDataFlex>
                        </TableDataTop1>
                        <TableDataTop2>
                            改善深度皺紋：
                            <ul>
                                <li>嚴重法令紋</li>
                                <li>嚴重木偶紋</li>
                                <li>嘴部細紋</li>
                                <li>提升唇部豐盈度</li>
                            </ul>
                        </TableDataTop2>
                        <TableDataTop3>
                            中至深層皮膚<br/>
                            皮下注射
                        </TableDataTop3>
                    </tr>

                    <tr>
                        <TableDataTop1>
                            <TableDataFlex>
                                <ProductName $colored="#79257d">Volume</ProductName>
                                <HandleImg src={volumeImg} alt="Belotero Volume"/>
                            </TableDataFlex>
                        </TableDataTop1>
                        <TableDataTop2>
                            重塑面部輪廓：
                            <ul>
                                <li>臉頰</li>
                                <li>蘋果肌</li>
                                <li>太陽穴</li>
                                <li>下巴</li>
                            </ul>
                        </TableDataTop2>
                        <TableDataTop3>
                            皮下注射
                        </TableDataTop3>
                    </tr>
                    </tbody>
                </Table>
                <MarginWrapper>
                    <Ref {...slideUp}>
                        1 Hertz-Kleptow D et al. Facial Skin Revitalization with CPM-HA20G: An Effective and Safe Early
                        Intervention Treatment. Clin Cosmet Investig Dermatol. 2019:12 563-572.
                    </Ref>
                    <Ref {...slideUp}>
                        2 Kleine-Börger, L., et al. Microinjections with hyaluronic acid in combination with glycerol:
                        How
                        do
                        they influence biophysical viscoelastic skin properties? Skin Research And Technology. 2022;
                        1-10.
                    </Ref>
                </MarginWrapper>
            </FlexWrapper>
        </div>


    );
};

export default BannerTable;