import Container from "@components/Container";
import H1 from "@components/H1";
import React from "react";


export default function Error404 () {
  return (
    <Container>
      <H1>Error 404</H1>
      <center>
        <p>the page you are looking for cannot be found</p>
      </center>
      <br /><br /><br /><br /><br /><br /><br /><br />
    </Container>
  );
}
