import Container from "@components/Container";
import Center from "@components/Center";
import React from "react";
import ReferenceComponent from "@components/Reference";
import SectionHeader from "@components/SectionHeader";
import LinkButton from "@components/LinkButton";
import newAwards from "@images/all-logos.png";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";

const AwardImage = styled.img`
  margin: 0 auto;
`;

const Reference = styled(ReferenceComponent)`
  display: inline-block;
  text-align: left;
`;

const InlineBlock = styled.span`
  display: inline-block;
`;

const Padding = styled.div`
  padding: 0 20px;
`

const H3 = styled.h3`
  //font-size: 22px;
  font-size: 2.4rem;
`;

const Spacer = styled.div`
  margin-bottom: 30px;
`;

export default function Awards () {
  return (
    <Container $maxWidth="85rem">
      <Center>
        <SectionHeader {...slideUp}>屢獲殊榮</SectionHeader>
        <p {...slideUp}>自推出以來BELOTERO®透明質酸榮獲全球多個醫學權威認可及多個國際獎項，以讚揚這創新的填充技術和其優良的肌膚融合配方<sup>1</sup>，療程高效安全<sup>3-4</sup>，深受用家滿意<sup>5</sup>。</p>
        <AwardImage src={newAwards} alt="Awards of BELOTERO®" {...slideUp} />

        <SectionHeader {...slideUp}>
          了解透明質酸及注射後的注意事項：
        </SectionHeader>

        <H3 {...slideUp}>什麼是透明質酸？它主要的用法及功效是什麼？</H3>

        <p {...slideUp}>
          {`
                            透明質酸（Hyaluronic acid），又名玻尿酸，是我們肌膚內的天然保濕因子，其主要用法及功效在於能夠鎖住水分，讓皮膚保持水潤緊緻。不過隨著年齡增長，天然的透明質酸便會逐步流失，肌膚愈見鬆弛。即使塗抹護膚品，當中的透明質酸成分亦難以滲透入皮膚底層。
                            要獲得真正改善肌膚的效果，必須將透明質酸注射入皮膚的不同層面，才可達至即時、持久及明顯的效果。當注射BELOTERO®透明質酸至皮下時，其功效能夠填充及撫平細紋、皺紋及其他肌膚問題。完成透明質酸療程後，效果一般可即時顯現。                       
                        `}
        </p>

        <H3 {...slideUp}>打透明質酸注意事項？</H3>
        <p {...slideUp}>
          {`
                            注射BELOTERO®透明質酸的療程效果一般可即時顯現，大部分使用者在療程後能立即回復日常活動。部分使用者療程後可能會出現紅腫，一般很快便會消退。由於 BELOTERO®是皮下注射填充劑，因此可能會出現輕微的瘀青，一般化妝也能遮蓋。             
                        `}
        </p>

        <H3 {...slideUp}>什麼是透明質酸？它主要的用法及功效是什麼？</H3>
        <p {...slideUp}>
          {`
                            注射透明質酸有可能引起短暫性的副作用，例如注射部位皮膚變色、輕微疼痛、紅腫、充血或瘀青。一般而言，這些較常見的不適感會於療程後幾天後消散。如對 BELOTERO®透明質酸療程有任何疑問，歡迎向醫生查詢。
                        `}
        </p>


        <Spacer/>

        <LinkButton to={routes.safety} {...slideUp}>了解BELOTERO®透明質酸療程效果及安全性</LinkButton>

        <Spacer/>

        <Padding>
          <Reference {...slideUp}>
              {`* Award of BELOTERO® REVIVE
# Treatment longevity depends on the BELOTERO® product used. Effect of BELOTERO Volume has been shown to last up to 18 months3. Individual treatment results and
longevity may vary.
1. Tran C, et al. Dermatology. 2014;228(1):47-54
2. Fischer TC, et al. Facial Plast Surg. 2016 Jun;32(3):283-8.
3. Lorenc ZP, et al. Plast Reconstr Surg. 2013;132(4 suppl 2):33S-34S.
4. Prasetyo AD, et al. Clin Cosmet Investig Dermatol. 2016 Sep 8;9:257-80.
5. Micheels P et al. Plast Reconstr Surg. 2013 Oct; 132 (4 Suppl 2):59S-68S.
6. Belotero Balance Lidocaine is approved by the US Food and Drug Administration (FDA)`}
          </Reference>
        </Padding>

      </Center>
    </Container>
  );
}
