import React from "react";
import styled from "styled-components";
import Container from "@components/Container";
import H1 from "@components/H1";

import {math} from "polished";
import SectionHeader from "@components/SectionHeader";
import Center from "@components/Center";
import {Column} from "../../components/Row";
import {Row} from "../../components/Row";

import blogThumbnail1 from "@images/Blog_thumbnail_1.png";
import TransitionLink from "../../components/TransitionLink";
import routes from "../../config/routes";

import fiveProducts from "@images/five-products.jpg";

import productBalance from "@images/Bel_Web Blog1_balance.jpg";
import productIntense from "@images/Bel_Web Blog1_intense.jpg";
import productRevive from "@images/Bel_Web Blog1_revive.jpg";
import productSoft from "@images/Bel_Web Blog1_soft.jpg";
import productVolume from "@images/Bel_Web Blog1_volume.jpg";
import {slideUp} from "../../config/sal";

import blog3Img1 from "@images/Blog3-img1.jpg";
import blog3Img2 from "@images/Blog3-img2.jpg";
import blog3Img3 from "@images/Blog3-img3.jpg";
import blog3Img4 from "@images/Blog3-img4.jpg";
import blog3Img5 from "@images/Blog3-img5.jpg";
import blog3Img6 from "@images/Blog3-img6.jpg";
import blog3Img7 from "@images/Blog3-img7.jpg";
import blog3Img8 from "@images/Blog3-img8.jpg";

import Reference from "@components/Reference";
import LinkButton from "../../components/LinkButton";

const H3 = styled.h3`
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  font-family: ${({theme}) => theme.bodyFontFamily};
  color: ${({$black, theme}) => $black ? theme.black : theme.headerColor};
  margin-top: 2rem;

  &:after {
    content: '';
    display: block;
    max-width: 56.5rem;
  }
`;

const ProductDescription = styled.p`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin: 1em auto;

  @media screen and (max-width: 575px) {
    line-height: 1.8;
  }
`;

const paragraph = [
    {
        title: "甚麼是蘋果肌？",
        content: <ProductDescription {...slideUp}>正如其名，蘋果肌就像是一顆紅蘋果般飽滿圓潤，位於顴骨下方約兩厘米處，呈倒三角形，蘋果肌主要是由脂肪、肌肉和膠原蛋白組成，在微笑時會因面部肌肉的收縮而隆起，呈現出圓潤飽滿的狀態，因此蘋果肌又稱笑肌。蘋果肌不僅能讓臉部線條更加立體，還能增添笑容的甜美度與感染力。
        </ProductDescription>,
    },
    {
        title: "蘋果肌下垂原因",
        content: <ProductDescription {...slideUp}>蘋果肌下垂的主要原因可以分為先天因素及後天因素：</ProductDescription>,
    },
]

const reasonsList = [
    {
        header: "先天因素",
        description: <ProductDescription {...slideUp}>有些女生天生顴骨較為扁平或凹陷，支撐度不足，從而使蘋果肌顯得平坦。而且上頜骨前凸，也會影響臉部整體輪廓，導致蘋果肌不明顯。</ProductDescription>,
    },
    {
        header: "皮膚老化",
        description: <ProductDescription {...slideUp}>隨著年齡增長，皮膚內的膠原蛋白逐漸流失，導致蘋果肌和雙頰肌膚失去彈性而鬆弛下垂，形成嘴邊肉，更會擠壓出法令紋、木偶紋等問題，同時側臉也會變得扁平，讓整體臉部輪廓逐漸失去立體感與緊實度，視覺上更顯老態。
        </ProductDescription>,
    },
    {
        header: "不良的生活習慣",
        description: <ProductDescription {...slideUp}>過度曬太陽、不良的飲食習慣等，都可能加速皮膚老化，進而影響蘋果肌的肌膚彈性。而且許多女性時常透過「減重」來雕塑自己理想的身形，但如果方式不正確，或是體重快速下降，都會造成脂肪在短時間內快速流失，加速皮膚老化鬆弛，從而影響蘋果肌。</ProductDescription>,
    },
];

const lowerParagraph = [
    {
        title: "5大方法提升蘋果肌",
        content: <ProductDescription {...slideUp}>「3分天注定，7分靠後天」不論有沒有蘋果肌的你，其實也可以靠平日的臉部保養或醫美療程，輕鬆擁出飽滿蘋果肌，以下是一些可以改善蘋果肌下垂的方法：
        </ProductDescription>,
    },
]

const productInfoList = [
    {
        header: "補充膠原蛋白",
        productImg: blog3Img4,
        imgAlt: "Belotero Revive",
        description: <ProductDescription {...slideUp}>透過Radiesse®膠原彈力針可以激發自身膠原蛋白再生<sup>1</sup>，除了具有塑形效果外，亦可以幫助皮膚重回彈性，有助維持肌膚彈性，更能提升蘋果肌，變得飽滿，外表更顯年輕。
        </ProductDescription>,
    },
    {
        header: "施打透明質酸",
        productImg: blog3Img5,
        imgAlt: "Belotero Soft",
        description: <ProductDescription {...slideUp}>透明質酸（Hyaluronic acid）又稱為玻尿酸，是皮膚維持水分及彈性的重要成分。
            透過注射的方式，除了可以有效消除法令紋、優化下巴線條及改善輪廓比例，更可改善蘋果肌凹陷問題，令蘋果肌即時變得豐滿，同時可提升肌膚的鎖水能力，從內在散發自然水潤光感。</ProductDescription>,
    },
];

const midProductInfoList = [
    {
        productImg: blog3Img7,
        productImg2: blog3Img8,
        description: <div><br/>*療程效果因人而異<br/>
            <ProductDescription {...slideUp}>瑞士BELOTERO®無透明質酸產品系列，採用了獨有的CPM技術，具備凝聚度、黏稠度、彈性及可塑性的均衡比例，提供了極佳的肌膚融合配方<sup>2</sup>，效果自然<sup>3</sup>。而BELOTERO®VOLUME更可重塑面部輪廓，令蘋果肌飽滿立體，打造心型臉部輪廓，讓你即時重拾年輕樣貌。</ProductDescription>
        </div>,
    },
];

const lowerProductInfoList = [
    {
        header: "減少使用3C產品",
        productImg: blog3Img2,
        imgAlt: "Belotero Revive",
        description: <ProductDescription {...slideUp}>如上所述，眼睛疲勞時，眼周皮膚的血液循環容易不暢，導致眼周皮膚加速老化鬆弛，然後也會影響蘋果肌下垂，因此平日應減少使用手機或是電子產品，減輕眼部的負擔。</ProductDescription>,
    },
    {
        header: "簡易面部運動",
        productImg: blog3Img3,
        imgAlt: "Belotero Soft",
        description: <ProductDescription {...slideUp}>日常面部運動也可助你一步步改善蘋果肌，做法很簡單，只要張開嘴巴到最大，然後縮小，同時發出「AEIOU」的口型，重複5次，每天進行5組即可。
            目的是令面部肌肉有大範圍的活動，提升肌膚的緊緻度，促進臉部血液循環，改善膚色，強行蘋果肌。</ProductDescription>,
    },
];

const BlogFlex = styled(Row)`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 575px) {
    gap: 20px;
  }
`;

const ProductImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const NoteWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Note = styled(Reference)`
  text-align: center;
  color: ${({theme}) => theme.gray700};
`;

const AlignLeftNote = styled(Note)`
  text-align: left;
`;

const ParaImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

export default function Blog3() {


    return (
        <Container $maxWidth="104rem">
            <H1>甚麼是蘋果肌？為何會下垂？<br/> 教你 5大方法提升蘋果肌！</H1>

            <Center>
                <ParaImg {...slideUp} src={blog3Img1} alt="belotero model"/>

                <ProductDescription {...slideUp}>
                    很多女星，如孫藝珍、宋慧喬、金智媛、林心如、衛詩雅都好像吃了防腐劑般不會老，原因是她們都擁有飽滿立體的蘋果肌。
                    蘋果肌不但可以營造出了少女般的可愛氣息，令人看起來更年輕，而且更可讓臉部輪廓更加立體分明。
                    然而，隨著年齡增長，許多人都會發現蘋果肌開始下垂，失去了原本的飽滿感，只要蘋果肌下垂、扁平，不但讓人看起來憔悴，更會形成法令紋，頓時令人顯老10歲年。
                    究竟是甚麼原因導致蘋果肌下垂？本文將與大家一起來深入探討，並分享5個可以提升蘋果肌的方法，讓大家可以做美魔女。
                </ProductDescription>
                <br/><br/>

                {
                    paragraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }
                {
                    reasonsList.map((item) => {
                        return (
                            <>
                                <ProductDescription {...slideUp}><H3>{item.header}</H3></ProductDescription>
                                <BlogFlex>

                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }
                {
                    lowerParagraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }
                {
                    productInfoList.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}><H3 {...slideUp}>{item.header}</H3></SectionHeader>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        <ProductImg {...slideUp} src={item.productImg} alt={item.imgAlt}/>
                                    </Column>

                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }
                {
                    midProductInfoList.map((item) => {
                        return (
                            <>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        <ProductImg {...slideUp} src={item.productImg} alt={item.imgAlt}/>
                                    </Column><br/>
                                    <Column $sm={12}>
                                        <ProductImg {...slideUp} src={item.productImg2} alt={item.imgAlt}/>
                                    </Column>
                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }
                <br/>
                <Center>
                    <LinkButton to={routes.beloteroHyaluronicAcid}>立即體驗</LinkButton>
                </Center>
                <br/><br/>
                {
                    lowerProductInfoList.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}><H3 {...slideUp}>{item.header}</H3></SectionHeader>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        <ProductImg {...slideUp} src={item.productImg} alt={item.imgAlt}/>
                                    </Column>

                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }
            </Center>

            <NoteWrapper>
                <AlignLeftNote>
                    Reference:<br/>
                    1 Yutskovskaya Y, et al. J Drugs Dermatol. 2014;13(9):1047-1052. <br/>
                    2 Tran C, et al. Dermatology. 2014;228(1):47-54. <br/>
                    3 Fischer TC et al. Facial PlastSurg. 2016 Jun; 32(3): 283-8 <br/>
                </AlignLeftNote>
            </NoteWrapper>
        </Container>
    );
}
