import React, {useMemo, useState} from "react";
import CenterSwitch from "./CenterSwitch";
import ClinicTable from "./ClinicTable";
import Container from "@components/Container";
import H1 from "@components/H1";
import productImage from "@images/certified-medical-center-product-image.png";
import productImageXsMax from "@images/certified-medical-center-product-image-xs-max.png";
import {slideUp} from "@config/sal";
import styled from "styled-components";
import {useLocation} from "@reach/router";
import {AllList} from "../../page-function/getClinicLists";


const districts = ["香港", "九龍", "新界"];
const isValidHash = Array.prototype.includes.bind(["#clinic-center", "#revive-clinic-center"]);

const ProductImage = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const ProductImageXs = styled(ProductImage)`
  margin-bottom: -3rem;
`;

const DistrictPicker = styled.div`
  text-align: center;
`;

const DistrictPickerItem = styled.div`
  display: inline-block;
  font-size: 1.8rem;
  text-decoration: ${({$active}) => $active ? "underline" : "none"};
  cursor: pointer;
`;

export default function CertifiedMedicalCenter (props) {
  const {hash} = useLocation();

  /**
   * Depends on what value is in hash. By default "clinic-center"
   * @type {"clinic-center"|"revive-clinic-center"}
   */
  // const showType = isValidHash(hash) ? hash.substr(1) : "clinic-center";
  const showType = props.showType ? props.showType : "clinic-center";

  /**
   * @type {["香港"|"九龍"|"新界", function]}
   */
  const [district, setDistrict] = useState("香港");

  const onDistrictClicks = useMemo(() => {
    const callbacks = {};
    districts.forEach(district => { callbacks[district] = () => setDistrict(district); });
    return callbacks;
  }, [setDistrict]);

  return (
    <Container $maxWidth="126rem">
      <H1 {...slideUp}>獲認證的醫療中心</H1>
      <ProductImage src={productImage} alt="Belotero's product image" data-sal="slide-up" data-sal-duration="1000" data-xs-max-display-none />
      <ProductImageXs src={productImageXsMax} alt="Belotero's product image" data-sal="slide-up" data-sal-duration="1000" data-sm-display-none />
      <CenterSwitch showType={showType} />
      <br /><br />
      <ClinicTable showType={showType} district={district} />
    </Container>
  );
}
