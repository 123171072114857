import React, {useEffect, useRef, useState} from "react";
import TransitionLink from "@components/TransitionLink";
import styled from "styled-components";
import {useLocation} from "@reach/router";


const Root = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white};
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  transform: ${({$active}) => $active ? 'none' : 'translateY(-100%)'};
  transition: transform ${({theme}) => theme.durations.basic}, box-shadow 0s ${({$active, theme}) => $active ? "0s" : theme.durations.basic};
  z-index: ${({theme}) => theme.zIndexes.headerSubmenu};
`;

const Item = styled.div`
  display: inline-block;
  font-size: 1.6rem;

  &:not(:last-child):after {
    content: '|';
    margin: 0 2em;
  }
`;

const ItemLink = styled(TransitionLink)`
  text-decoration: none;
  color: ${({$active, theme}) => $active ? theme.navActive : theme.navSubheaderColor};
  font-weight: 400;
  transition: color ${({theme}) => theme.durations.basic};

  &:hover { color: ${({theme}) => theme.navActive}; }
`;

const Pusher = styled.div`
  height: ${({$active, $height}) => $active ? $height : 0}px;
  transition: height ${({theme}) => theme.durations.basic};
`;

export default function HeaderSubmenu (props) {
  const {onMouseLeave, hoverSubmenuIndex, setHoverSubmenuIndex, items, index} = props;
  const rootRef = useRef();
  const {pathname} = useLocation();
  const [displayItems, setDisplayItems] = useState([]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const newHeight = rootRef.current.getBoundingClientRect().height;
    if (newHeight !== height) { setHeight(newHeight); }
  }, [height]);

  useEffect(() => {
    if (Array.isArray(items)) { setDisplayItems(items); }
  }, [items]);

  return (
    <>
      <Root onMouseMove={() => setHoverSubmenuIndex(hoverSubmenuIndex)} onMouseLeave={onMouseLeave} $active={!!items} $height={height} ref={rootRef}>
        {displayItems.map(({title, url}, index) => (
          <Item key={index}>
            <ItemLink to={url} $active={pathname === "/" ? url === pathname : url.startsWith(pathname)}>{title}</ItemLink>
          </Item>
        ))}
      </Root>
      <Pusher $active={!!items} $height={height} />
    </>
  );
}
