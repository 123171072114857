import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import CardComponent from "@components/Card";
import Container from "@components/Container";
import H1 from "@components/H1";
import {math} from "polished";
import mediaThumbnail1 from "@images/medias-choice-media-thumbnail-1.jpg";
import mediaThumbnail2 from "@images/medias-choice-media-thumbnail-2.jpg";
import mediaThumbnail3 from "@images/medias-choice-media-thumbnail-3.jpg";
import mediaThumbnail4 from "@images/medias-choice-media-thumbnail-4.png";
import mediaThumbnail5 from "@images/medias-choice-media-thumbnail-5.png";

import moreNewsThumbnail from "@images/more_news_thumbnail.jpg";
import girlStyleThumbnail from "@images/girlstyle_news_thumbnail.jpg";
import topBeautyThumbnail from "@images/topbeauty_news_thumbnail.jpg";
import adayMagThumbnail from "@images/adaymag_news_thumbnail.jpg";


const Root = styled.div`
  position: relative;
  
`;

const Rainbow = styled.div`
  position: absolute;
  top: 0;
  width: 15%;
  white-space: nowrap;

  ${({theme}) => theme.breakpoints.xsMax} {
    top: initial;
    right: 2rem;
    bottom: 100%;
    height: 27rem;
    margin-bottom: -7rem;
  }

  ${({theme}) => theme.breakpoints.sm} {
    height: 95%;
    right: 14rem;
  }

  > div {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    transition: height ${({theme}) => theme.durations.slow} ${({theme}) => theme.easings.basic};

    &:nth-child(1) { height: ${({$show}) => $show ? "86%" : "0"}; transition-delay: ${math("4 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[0]}; }
    &:nth-child(2) { height: ${({$show}) => $show ? "96%" : "0"}; transition-delay: ${math("2 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[1]}; }
    &:nth-child(3) { height: ${({$show}) => $show ? "100%" : "0"}; transition-delay: ${math("1 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[2]}; }
    &:nth-child(4) { height: ${({$show}) => $show ? "92%" : "0"}; transition-delay: ${math("3 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[3]}; }
    &:nth-child(5) { height: ${({$show}) => $show ? "96%" : "0"}; transition-delay: ${math("2 * 0.2s")}; background-color: ${({theme}) => theme.productColorsLinear[4]}; }
  }
`;

const MediasChoiceContainer = styled(Container)`
  //
    column-width: 360px;
    column-gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
    max-width: 780px;
    width:100%;
  ${({theme}) => theme.breakpoints.xsMax} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CardWrap = styled.div`
  //
  width: 100%;
//overflow: hidden;
border-radius: 10px;
max-width: 360px;
margin: 0 auto 20px;
display: inline-block;
`;

const Card = styled(CardComponent)`
  text-align: center;

  ${({theme}) => theme.breakpoints.xsMax} {
    width: auto;
    margin-left: ${({theme}) => theme.containerPaddingXsMax};
    margin-right: ${({theme}) => theme.containerPaddingXsMax};
  }
`;

const MediaLink = styled.a`
  color: #EE8131;
  font-size: 2rem;
  font-weight: 700;
`;

export default function MediasChoice () {
  const [showRainbow, setShowRainbow] = useState(false);
  const theme = useTheme();
      //const options = useMemo(() => ({
      //  arrows: true,
      //  dots: true,
      //  slidesToShow: 2,
      //  responsive: [{
      //    breakpoint: theme.breakpointWidths.xsMax,
      //    settings: {slidesToShow: 1},
      //  }],
      //}), [theme.breakpointWidths.xsMax]);
  useEffect(() => { setShowRainbow(true); }, []);
  return (
    <Root>
      <Rainbow $show={showRainbow}><div /><div /><div /><div /><div /></Rainbow>
      <H1>媒體推薦</H1>
      <MediasChoiceContainer $maxWidth="92rem">
          <CardWrap>
              <Card thumbnail={moreNewsThumbnail}>
                  <p>MORE ︳人氣韓星金所泫擔任Belotero® Revive水光針亞太區代言人，開啟輕醫美護膚新基準！</p>
                  <MediaLink href="https://www.sundaymore.com/skin-care/belotero-revive-%e6%b0%b4%e5%85%89%e9%87%9d-1256675/" target="_blank">了解更多</MediaLink>
              </Card>
          </CardWrap>

          <CardWrap>
              <Card thumbnail={girlStyleThumbnail}>
                  <p>GIRLSTYLE ︳「最美童星」金所泫首任Belotero® Revive水光針代言人，大讚膚質水潤飽滿零難度！</p>
                  <MediaLink href="https://pretty.presslogic.com/article/695822/%E9%87%91%E6%89%80%E6%B3%AB-beloterorevive%E4%BA%9E%E5%A4%AA%E5%8D%80%E4%BB%A3%E8%A8%80%E4%BA%BA-%E5%8F%B0%E5%8C%97%E7%9B%B4%E6%93%8A?fbclid=IwAR19eFKdkivVW__OPbo4cladBGPUjU3ZXlOh-m6B2i0BEX2UoOoCuwu1QPU" target="_blank">了解更多</MediaLink>
              </Card>
          </CardWrap>

          <CardWrap>
              <Card thumbnail={topBeautyThumbnail}>
                  <p>Top Beauty ︳美編實測: 是最應投資的保濕「護膚程序」！</p>
                  <MediaLink href="https://www.facebook.com/share/CUfhyt6fsvpxnHNY/?mibextid=d6eG65" target="_blank">了解更多</MediaLink>
              </Card>
          </CardWrap>

          <CardWrap>
              <Card thumbnail={adayMagThumbnail}>
                  <p>A day magazine ︳學習金所泫的精簡保養法</p>
                  <MediaLink href="https://www.adaymag.com/2023/11/30/belotero-kim-so-hyun.html" target="_blank">了解更多</MediaLink>
              </Card>
          </CardWrap>

          <CardWrap>
            <Card thumbnail={mediaThumbnail3}>
              <p>美評家實試．收細毛孔+光澤感UP BELOTERO®Revive透明質酸肌底活化療程</p>
              <MediaLink href="https://www.sundaymore.com/skin-care/revive-%e9%80%8f%e6%98%8e%e8%b3%aa%e9%85%b8-919273/" target="_blank">了解更多</MediaLink>
            </Card>
          </CardWrap>

          <CardWrap>
            <Card thumbnail={mediaThumbnail4}>
              <p>美編親身分享保濕針心得BELOTERO®️Revive水光針一週後皮膚超水潤！</p>
              <MediaLink href="https://www.she.com/beauty/%E9%86%AB%E5%AD%B8%E7%BE%8E%E5%AE%B9%E7%BE%8E%E7%B7%A8%E8%A6%AA%E8%BA%AB%E5%88%86%E4%BA%AB%E4%BF%9D%E6%BF%95%E9%87%9D%E5%BF%83%E5%BE%97-belotero%EF%B8%8F-revive%E6%B0%B4/" target="_blank">了解更多</MediaLink>
            </Card>
          </CardWrap>
          <CardWrap>
            <Card thumbnail={mediaThumbnail5}>
              <p>水潤少女肌輕鬆Get?真實用家實測BELOTERO®️Revive 水光針</p>
              <MediaLink href="https://www.she.com/beauty/%e7%9c%9f%e5%af%a6%e7%94%a8%e5%ae%b6%e5%af%a6%e6%b8%ac-belotero%ef%b8%8f-revive%e6%b0%b4%e5%85%89%e9%87%9d%e6%b7%b1%e5%85%a5%e8%82%8c%e5%ba%95-%e5%85%a8%e9%9d%a2%e8%86%9a%e8%b3%aa%e5%81%a5/" target="_blank">了解更多</MediaLink>
            </Card>
          </CardWrap>
          <CardWrap>
            <Card thumbnail={mediaThumbnail1}>
              <p>踏入30歲後，更明顯的變化一定是因底層缺水，令眉心、眼尾細紋及法令紋等表情變愈見明顯，而且肌膚自我製造膠原蛋白的速度追不上流失的速度。要於肌膚底層作出「填補」的功夫，我就選擇...</p>
              <MediaLink href="https://www.sundaymore.com/skin-care/belotero-%e6%b0%b4%e5%85%89%e9%87%9d-865780/" target="_blank">了解更多</MediaLink>
            </Card>
          </CardWrap>
          <CardWrap>
            <Card thumbnail={mediaThumbnail2}>
              <p>還原年輕肌的秘密大公開 抹去「成長」的痕跡先要做好肌底保濕！</p>
              <p>市面上有多款透明質酸保濕療程，BELOTERO® 系列的療程一向都是信心的保證～這次新推出的BELOTERO...</p>
              <MediaLink href="https://pretty.presslogic.com/article/459089/%E6%AD%B2%E6%9C%88%E4%B8%8D%E9%A5%92%E4%BA%BA-%E8%BC%95%E7%86%9F%E5%A5%B3%E5%BF%85%E7%9C%8B-%E9%82%84%E5%8E%9F%E5%B9%B4%E8%BC%95%E8%82%8C%E7%9A%84%E7%A7%98%E5%AF%86%E5%A4%A7%E5%85%AC%E9%96%8B-%E6%8A%B9%E5%8E%BB-%E6%88%90%E9%95%B7-%E7%9A%84%E7%97%95%E8%B7%A1%E5%85%88%E8%A6%81%E5%81%9A%E5%A5%BD%E8%82%8C%E5%BA%95%E4%BF%9D%E6%BF%95" target="_blank">了解更多</MediaLink>
            </Card>
          </CardWrap>

      </MediasChoiceContainer>
    </Root>
  );
}
