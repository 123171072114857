import ContainerComponent from "@components/Container";
import LinkButton from "@components/LinkButton";
import React from "react";
import routes from "@config/routes";
import styled from "styled-components";
import {slideUp} from "../../config/sal";
import Reference from "../../components/Reference";


const Container = styled(ContainerComponent)`
  //

  ${({theme}) => theme.breakpoints.sm} {
    text-align: center;
  }
`;

const Ref = styled(Reference)`
  @media (max-width: 768px) {
    text-align: left;
  }
`;

export default function Ending() {
    return (
        <Container>
            <p {...slideUp}>對於追求自然和減淡衰老的您，BELOTERO® 產品系列就是您的選擇</p>
            <br/>
            <LinkButton to={routes.certifiedMedicalCenterClinicCenter} {...slideUp}>立即了解BELOTERO®認證的醫療中心</LinkButton>
            <br/><br/>
            <Ref {...slideUp}>{
                `* Belotero Balance Lidocaine is approved by the US Food and Drug Administration (FDA).
# Treatment longevity depends on the BELOTERO:registered: product used. Effect of Belotero Volume has been shown to last up to 18 months¹. Individual treatment results and longevity may vary.
1 Kerscher M, et al. Clin Cosmet Investig Dermatol. 2017;10:239-47
2 Tran C, et al. Dermatology. 2014;228(1):47-54
3 Fischer TC et al. Facial PlastSurg. 2016 Jun; 32(3): 283-8
4 Micheels P et al. Plast Reconstr Surg. 2013 Oct; 132 (4 Suppl 2):59S-68S.
5 Prager W et al. Plast Reconstr Surg Glob Open. 2017;5(10):e1412`}
            </Ref>
        </Container>
    );
}
