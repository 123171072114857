import {Column, Row} from "@components/Row";
import React, {useState} from "react";
import CompareSlider from "@components/CompareSlider";
import Container from "@components/Container";
import H1 from "@components/H1";
import Spacer from "@components/Spacer";
import balanceHandle from "@images/success-stories-balance-handle.png";
import balanceLogo from "@images/success-stories-balance-logo.png";
import intenseHandle from "@images/success-stories-intense-handle.png";
import intenseLogo from "@images/success-stories-intense-logo.png";
import softHandle from "@images/success-stories-soft-handle.png";
import softLogo from "@images/success-stories-soft-logo.png";
import styled from "styled-components";
import volumeHandle from "@images/success-stories-volume-handle.png";
import volumeLogo from "@images/success-stories-volume-logo.png";
import reviveHandle from "@images/success-stories-revive-handle.png";
import reviveLogo from "@images/success-stories-revive-logo.png";

import freshBefore from "@images/freshBefore.jpg";
import freshAfter from "@images/freshAfter.jpg";
import smoothBefore from "@images/smoothBefore.jpg";
import smoothAfter from "@images/smoothAfter.jpg";

import BigCompareSlider from "@components/BigCompareSlider";
import successBalanceBefore from "@images/success-balance-before.jpg";
import successBalanceAfter from "@images/success-balance-after.jpg";
import successVolumeBefore from "@images/success-volume-before.jpg";
import successVolumeAfter from "@images/success-volume-after.jpg";
import successIntenseBefore from "@images/success-intense-before.jpg";
import successIntenseAfter from "@images/success-intense-after.jpg";
import successSoftBefore from "@images/success-soft-before.jpg";
import successSoftAfter from "@images/success-soft-after.jpg";

import mixHandle from "@images/success-mix-handle2.png";
import successMixBefore from "@images/success-mix-before.jpg";
import successMixAfter from "@images/success-mix-after.jpg";
import Reference from "../components/Reference";
import Center from "../components/Center";

import reviewHandle from "@images/success-stories-revive-handle.png";
import case1Before from "@images/case1-before.jpg";
import case1After from "@images/case1-after.jpg";
import case2Before from "@images/case2-before.jpg";
import case2After from "@images/case2-after.jpg";
import case3Before from "@images/case3-before.jpg";
import case3After from "@images/case3-after.jpg";
import case4Before from "@images/case4-before.jpg";
import case4After from "@images/case4-after.jpg";

const Logo = styled.img`
  height: 2.2rem;
  margin: 0 auto 2rem;

  ${({theme}) => theme.breakpoints.sm} {
    height: 2.4rem;
  }
`;

const MobileMarginTop = styled.div`

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const MarginTop = styled.div`
  margin-top: 50px;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CompareSliderDescription = styled.p`
  text-align: center;
  margin-top: 4.5vw;
  color: rgb(238, 119, 0);
  font-size: 2rem;
  //margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 8vw;
  }

  @media (min-width: 1441px) {
    margin-top: 60px;
  }
`;

const H3 = styled.h3`
  font-size: 2.4rem;
  color: rgb(238, 119, 0);
`;

const Ref = styled(Reference)`
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const Container2 = styled(Container)`
  padding: 0 2rem;
`;

const Br = styled.br`
  @media (max-width: 768px) {
    display: none;
  }
`;

const MarginTopNoMobile = styled(MarginTop)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const SquareContainer = styled.div`
  margin: 25px auto;
`;

const Description = styled.p`
  text-align: center;
  margin-top: 1.2vw;
  color: rgb(238, 119, 0);
  font-size: 2rem;
  //margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 0vw;
  }

  @media (min-width: 1441px) {
    margin-top: 10px;
  }
`;

export default function SuccessStories() {
    const [softPosition, setSoftPosition] = useState(50);
    const [intensePosition, setIntensePosition] = useState(50);
    const [balancePosition, setBalancePosition] = useState(50);
    const [volumePosition, setVolumePosition] = useState(50);
    const [mixPosition, setMixPosition] = useState(50);

    const [smilePosition, setSmilePosition] = useState(50);
    const [freshPosition, setFreshPosition] = useState(50);
    const [smoothPosition, setSmoothPosition] = useState(50);

    const [position1, setPosition1] = useState(50);
    const [position2, setPosition2] = useState(50);
    const [position3, setPosition3] = useState(50);
    const [position4, setPosition4] = useState(50);

    return (
        <Container2>
            <H1>療程效果</H1>

            <Row $toColumn={true}>
                <Column $sm={6} $widthInColumn={"100% !important"}>
                    <CompareSlider
                        arrows
                        position={smilePosition}
                        onPositionChange={setSmilePosition}
                        left={successBalanceBefore}
                        right={successBalanceAfter}
                    />
                    {/*<Spacer $all="1em" $sm="2em"/>*/}
                    <Spacer $sm="2em"/>
                </Column>

                <Column $sm={6} $widthInColumn="100% !important">
                    <MobileMarginTop>
                        <H3>重拾自信笑容</H3>
                        <p>一般而言，最令女士們困擾的就是法令紋，因爲它會隨著時間而變得越來越明顯，而BELOTERO®產品系列能夠有效而自然地撫平皺紋。</p>
                    </MobileMarginTop>
                </Column>
            </Row>

            <MarginTop/>

            <Row $toColumn={true}>
                <Column $sm={6} $widthInColumn={"100% !important"}>
                    <CompareSlider
                        left={freshBefore}
                        right={freshAfter}
                        position={freshPosition}
                        onPositionChange={setFreshPosition}
                        arrows
                    />
                    <Spacer $sm="2em"/>
                </Column>

                <Column $sm={6} $widthInColumn="100% !important">
                    <MobileMarginTop>
                        <H3>看起來煥然一新，精神飽滿</H3>
                        <p>BELOTERO® 產品系列能夠減淡面部的衰老痕跡，例如重塑臉頰及下巴的輪廓，讓你時刻煥發自信光芒。</p>
                    </MobileMarginTop>
                </Column>
            </Row>

            <MarginTop/>

            <Row $toColumn={true}>
                <Column $sm={6} $widthInColumn={"100% !important"}>
                    <CompareSlider
                        arrows
                        position={smoothPosition}
                        onPositionChange={setSmoothPosition}
                        left={smoothBefore}
                        right={smoothAfter}
                    />
                    <Spacer $sm="2em"/>
                </Column>

                <Column $sm={6} $widthInColumn="100% !important">
                    <MobileMarginTop>
                        <H3>提升面部輪廓、撫平皺紋</H3>
                        <p>容貌衰老不只是單單一條皺紋造成。BELOTERO®
                            產品系列的各種組合療程可以有效減淡皺紋，提升輪廓線條，重拾年輕容貌。</p>
                    </MobileMarginTop>
                </Column>
            </Row>

            <MarginTopNoMobile/><br/><br/>

            <Row
                $alignItems="center"
                $justifyContent="center"
            >
                <Column $sm={8} $widthInColumn={"100% !important"}>
                    <FlexBox>
                        <Logo src={balanceLogo} alt={"Belotero Balance Logo"}/>
                        <Logo src={volumeLogo} alt={"Belotero Volume Logo"}/>
                    </FlexBox>

                    <BigCompareSlider
                        arrows
                        handle={mixHandle}
                        position={mixPosition}
                        onPositionChange={setMixPosition}
                        left={successMixBefore}
                        right={successMixAfter}
                    />
                    <CompareSliderDescription>淡化嚴重法令紋</CompareSliderDescription>
                </Column>
            </Row>
            <Br/><Br/><Br/>

            <Logo src={reviveLogo} alt={"Belotero Revive Logo"} />
            <MobileMarginTop>
                <Row
                    $alignItems="center"
                    $justifyContent="center"
                    $toColumn={true}
                    $columnAlignItems="center"
                >
                    <Column $sm={6} $widthInColumn={"100% !important"}>
                        <SquareContainer>
                            <CompareSlider
                                arrows
                                handle={reviveHandle}
                                position={position1}
                                onPositionChange={setPosition1}
                                left={case1Before}
                                right={case1After}
                                width={400}
                                height={400}
                                aspectRatio={true}
                            />
                        </SquareContainer>
                    </Column>


                    <Column $sm={6} $widthInColumn={"100% !important"}>
                        <MobileMarginTop>
                            <SquareContainer>
                                <CompareSlider
                                    arrows
                                    handle={reviveHandle}
                                    position={position2}
                                    onPositionChange={setPosition2}
                                    left={case2Before}
                                    right={case2After}
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>

                        </MobileMarginTop>
                    </Column>

                    <Column $sm={6} $widthInColumn={"100% !important"}>
                        <MobileMarginTop>
                            <SquareContainer>
                                <CompareSlider
                                    arrows
                                    handle={reviveHandle}
                                    position={position3}
                                    onPositionChange={setPosition3}
                                    left={case3Before}
                                    right={case3After}
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>

                        </MobileMarginTop>
                    </Column>

                    <Column $sm={6} $widthInColumn={"100% !important"}>
                        <MobileMarginTop>
                            <SquareContainer>
                                <CompareSlider
                                    arrows
                                    handle={reviveHandle}
                                    position={position4}
                                    onPositionChange={setPosition4}
                                    left={case4Before}
                                    right={case4After}
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>

                        </MobileMarginTop>
                    </Column>

                </Row>
            </MobileMarginTop>
            <Description>BELOTERO® REVIVE 全面提升肌膚水份，讓皮膚時刻保持水潤光澤。</Description>
            <Br/><Br/><Br/>

            <MobileMarginTop>
                <Row
                    $alignItems="center"
                    $justifyContent="center"
                    $toColumn={true}
                    $columnAlignItems="center"
                >
                    <Column $sm={6} $widthInColumn={"100% !important"}>
                        <Logo src={balanceLogo} alt={"Belotero Balance Logo"}/>
                        <CompareSlider
                            arrows
                            handle={balanceHandle}
                            position={balancePosition}
                            onPositionChange={setBalancePosition}
                            left={successBalanceBefore}
                            right={successBalanceAfter}
                        />
                        <CompareSliderDescription>撫平中度法令紋</CompareSliderDescription>
                    </Column>

                    <Column $sm={6} $widthInColumn={"100% !important"}>
                        <MobileMarginTop>
                            <Logo src={volumeLogo} alt={"Belotero Volume Logo"}/>
                            <CompareSlider
                                arrows
                                handle={volumeHandle}
                                position={volumePosition}
                                onPositionChange={setVolumePosition}
                                left={successVolumeBefore}
                                right={successVolumeAfter}
                            />
                            <CompareSliderDescription>豐盈蘋果肌及下巴，優化輪廓線</CompareSliderDescription>
                        </MobileMarginTop>

                    </Column>
                </Row>
            </MobileMarginTop>

            <br/><br/>
            <Row
                $alignItems="center"
                $justifyContent="center"
                $toColumn={true}
                $columnAlignItems="center"
            >
                <Column $sm={6} $widthInColumn={"100% !important"}>
                    <Logo src={intenseLogo} alt={"Belotero Intense Logo"}/>
                    <CompareSlider
                        arrows
                        handle={intenseHandle}
                        position={intensePosition}
                        onPositionChange={setIntensePosition}
                        left={successIntenseBefore}
                        right={successIntenseAfter}
                    />
                    <CompareSliderDescription>豐盈嘴唇**</CompareSliderDescription>
                </Column>
                <Column $sm={6} $widthInColumn={"100% !important"}>
                    <MobileMarginTop>
                        <Logo src={softLogo} alt={"Belotero Soft Logo"}/>
                        <CompareSlider
                            arrows
                            handle={softHandle}
                            position={softPosition}
                            onPositionChange={setSoftPosition}
                            left={successSoftBefore}
                            right={successSoftAfter}
                        />
                        <CompareSliderDescription>撫平嘴部細紋*<sup>1</sup></CompareSliderDescription>
                    </MobileMarginTop>
                </Column>
            </Row>

            <Center>
                <Ref>{`**Pictures: Courtesy of Dr Micheels
*Pictures: Courtesy of Dr Imhof and Dr Kühne`}
                </Ref>
            </Center>


        </Container2>

    );
}
