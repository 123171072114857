import routes from "@config/routes";


const menu = [
  {
    title: "主頁",
    url: routes.index,
  },
  {
    title: "認識 Belotero®",
    url: routes.cpm,
    urlRoot: routes.about,
    submenu: [
      {title: "獨有CPM技術", url: routes.cpm},
      {title: "認識 BELOTERO®無痕透明質酸系列", url: routes.beloteroHyaluronicAcid},
      {title: "BELOTERO®REVIVE水光針", url: routes.beloteroRevive},
    ],
  },
  {
    title: "療程效果",
    url: routes.ageing,
    urlRoot: routes.treatmentResult,
    submenu: [
      {title: "認識衰老", url: routes.ageing},
      {title: "療程效果及安全性", url: routes.safety},
      {title: "療程效果", url: routes.treatmentEffect},
      {title: "常見問題", url: routes.faq},
    ],
  },
  {
    title: "最新分享",
    url: routes.expertAdvice,
  },
  {
    title: "媒體推薦",
    url: routes.mediasChoice,
  },
  {
    title: "獲認證的醫療中心",
    url: routes.certifiedMedicalCenterClinicCenter,
  },
];

export default menu;
