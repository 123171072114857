import React, {useState} from "react";
import styled from "styled-components";
import Container from "@components/Container";
import H1 from "@components/H1";

import SectionHeader from "@components/SectionHeader";
import Center from "@components/Center";
import {Column} from "../../components/Row";
import {Row} from "../../components/Row";
import {slideUp} from "../../config/sal";

import case1Before from "@images/case1-before.jpg";
import case1After from "@images/case1-after.jpg";
import case2Before from "@images/case2-before.jpg";
import case2After from "@images/case2-after.jpg";
import case3Before from "@images/case3-before.jpg";
import case3After from "@images/case3-after.jpg";
import case4Before from "@images/case4-before.jpg";
import case4After from "@images/case4-after.jpg";
import CompareSlider from "@components/CompareSlider";
import reviewHandle from "@images/success-stories-revive-handle.png";
import Reference from "@components/Reference";
import routes from "../../config/routes";
import LinkButton from "../../components/LinkButton";
import BulletPoints from "@components/BulletPoints";

import blog2Img1 from "@images/Blog2-img1.jpg";
import blog2Img2 from "@images/Blog2-img2.png";
import blog2Img3 from "@images/Blog2-img3.jpg";

const Root = styled.div`
  position: relative;
  overflow: hidden;
`;

const H3 = styled.h3`
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  font-family: ${({theme}) => theme.bodyFontFamily};
  color: ${({$black, theme}) => $black ? theme.black : theme.headerColor};
  margin-top: 2rem;
  margin-bottom: 3rem;

  &:after {
    content: '';
    display: block;
    max-width: 56.5rem;
    height: .3rem;
    margin: 1rem auto 0;
    ${({theme}) => theme.productColorLine}
  }

  ${({theme}) => theme.breakpoints.xsMax} {
    font-size: 2.4rem;
  }
`;


const BlogRow = styled(Row)`
  margin: 20px auto;
  justify-content: center;

  @media screen and (max-width: 575px) {
    gap: 20px;
  }
`;

const ProductImg = styled.img`
  width: 90px;
  margin: 0 auto;
`;

const ParaImg = styled.img`
  display: block;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const ProductDescription = styled.p`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin: 1em auto;

  @media screen and (max-width: 575px) {
    line-height: 1.8;
  }
`;

const MyContainer = styled(Container)`
  @media (max-width: 576px) {
    padding: 0;
  }
`;

const SquareContainer = styled.div`
  margin: 25px auto;
`;

const Note = styled(Reference)`
  text-align: center;
  color: ${({theme}) => theme.gray700};
`;

const AlignLeftNote = styled(Note)`
  text-align: left;
`;

const BulletItem = styled.div`
  text-align: left;
`;

const BulletPointsContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  max-width: 800px;
  width: 100%;
  
  margin: 1em auto;
`;

const NoteWrapper = styled.div`
  max-width: 800px;
  width: fit-content;
  margin: 0 auto;
`;

const DesktopBr = styled.br`
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

const BulletPointsColumn = styled(Column)`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 575px) {
    align-items: initial;
    width: 100%;
  }
`;

const BulletPointsColumn2 = styled(Column)`

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export default function Blog2() {

    const paragraphs = [
        {
            title: "水光針原理是甚麼？",
            image: blog2Img2,
            content: "水光面膜、水光氣墊粉餅，「水光」一詞不只在護膚品與化妝品界別爆紅，「水光針」療程也在醫美界超受歡迎，受到許多IG KOL愛戴，不管是哪種水光產品，共通點都是離不開透明質酸。而我們的皮膚大致可分成3層，分別是表皮層、真皮層、皮下組織；表皮層也就是皮膚的最外層，是肌膚的屏障。我們日常塗抹的護膚品大多只能停留在表皮層，難以滲入真皮層被皮膚吸收，所以很多時候效果都會有所限制。而水光針是一種真皮注射技術，可以理解為「進階版的護膚品」，把透明質酸由表皮「深入」到真皮，使皮膚含水量增加、刺激膠原再生等，相比護膚品效果更快速見效及持久。",
        },
    ];

    const lowerParagraph = [
        {
            title: "水光針多久打一次？",
            content: <ProductDescription {...slideUp}>一般建議一個月打一次，連續三次為一個療程，而Belotero®
                Revive水光針的療程效果更可長效達9個月<sup>2-4</sup>，實際持效時間視乎各種因素，如療程位置、皮膚類型、皮膚狀況、生活習慣和年齡等。
            </ProductDescription>,
        },
        {
            title: "水光針和水光槍有何分別？",
            content: <ProductDescription {...slideUp}>水光針與水光槍原理都是採用皮下注射的方式，將透明質酸注入皮膚真皮層。水光槍是使用儀器，由電腦控制以真空負壓形式進行。水光針則是由醫生以人手針劑注射方式進行，醫生更能因應用家膚況而調整不同的治療方式，針對特定部位進行局部注射及調整劑量，效果更個人化。</ProductDescription>,
        },
        {
            title: "水光針的療程過程是怎樣？",
            content:
                <ProductDescription {...slideUp}>進行療程前，我們會建議先向醫生進行諮詢評估。在療程過程中醫生會利用針劑注射方式，將透明質酸平均地打進皮膚真皮層，當填充劑注射到皮膚時可能會出現輕微不適，但在療程前會使用麻膏將不適降至最低，過程需時約30分鐘左右，實際療程時間因人而異。</ProductDescription>,
        },
    ]


    const defaultInitialValue = 50;
    const [position1, setPosition1] = useState(defaultInitialValue);
    const [position2, setPosition2] = useState(defaultInitialValue);
    const [position3, setPosition3] = useState(defaultInitialValue);
    const [position4, setPosition4] = useState(defaultInitialValue);

    return (
        <Container $maxWidth="104rem">
            <H1>水光針有無用？多久打一次？<DesktopBr/>水光針懶人包一次教你選！</H1>

            <Center>
                <ParaImg src={blog2Img1} alt=""/>
                <ProductDescription {...slideUp}>
                    女人天生特別需要水分，除了因為眼淚特別多外，皮膚較脆弱亦容易流失水分，敏感及乾燥膚質尤甚。
                    隨著秋冬天氣的到來，肌膚乾燥問題或將會加劇，對25+愛美人士造成不小的困擾。即使使用護膚品，當中透明質酸的成分亦難以滲透入皮膚底層。輕熟肌尤其容易出現乾旱脫皮、暗沉、粗糙、以及細紋等問題，要保持時刻水潤光澤，可考慮打水光針，為肌膚注入滿滿水分，快速有效地重拾水嫩和彈性。這篇文章將帶你了解更多關於打水光針的原理、功效、術後保養等注意事項等。
                </ProductDescription>

                {
                    paragraphs.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.image ? <ParaImg src={item.image} alt=""/> : null}
                                <ProductDescription {...slideUp}>
                                    {item.content}
                                </ProductDescription>
                            </>
                        )
                    })
                }

                <SectionHeader {...slideUp}>水光針如何選？</SectionHeader>
                <ProductDescription {...slideUp}>
                    市面上的水光針品牌所含的透明質酸分子主要可分為有鏈結及無鏈結。無鏈結透明質酸容易被人體吸收及自然分解;
                    而有鏈結透明質酸的滲透度相較起來雖然會慢一點，但效果亦會較持久。
                </ProductDescription>
                <ParaImg src={blog2Img3} alt="Benefits of Belotero Revive"/>
                <ProductDescription {...slideUp}>
                    瑞士製造Belotero® Revive水光針的獨有保濕鎖水配方，結合透明質酸和甘油，加強肌膚的水潤和健康，
                    同時亦是目前市面上唯一結合甘油與鏈結透明質酸的水光針<sup>1</sup>，全方位發揮8大美肌功效，讓您看起來更年輕、容光煥發<sup>#1,2</sup>。
                    透明質酸是天然存在於人體的保濕因子，能夠承載自身重量多達1000倍的水分，為肌膚提供保濕和支撐作用。甘油是另一種強效保濕成分，
                    有效鎖緊肌膚中的所有水分。透明質酸和甘油兩種保濕因子的協同作用有助強效保濕鎖水，塑造持久光澤感<sup>2,3</sup>，
                    打造真素顏、零P圖、0粉底的女神水光肌！
                </ProductDescription>

                <BulletPointsContainer $justifyContent="center">
                    <BulletPointsColumn2 $sm={5}>
                        <BulletPoints
                            items={[
                                {text: <BulletItem>瞬間深層保濕，長效達9個月<sup>2-4</sup></BulletItem>},
                                {text: <BulletItem>即時釋放獨有甘油成分，煥發肌膚光澤<sup>#3-5</sup></BulletItem>},
                                {text: <BulletItem>提升柔滑度及彈性<sup>2-4</sup></BulletItem>},
                                {text: <BulletItem>改善泛紅<sup>3</sup></BulletItem>},
                            ]}
                        />
                    </BulletPointsColumn2>

                    <BulletPointsColumn $sm={5}>
                        <BulletPoints
                            items={[
                                {text: <BulletItem>均匀膚色<sup>3</sup></BulletItem>},
                                {text: <BulletItem>撫平粗糙肌膚<sup>2-4</sup></BulletItem>},
                                {text: <BulletItem>改善疲憊暗啞肌<sup>6</sup></BulletItem>},
                            ]}
                        />
                    </BulletPointsColumn>
                </BulletPointsContainer>

                <SectionHeader>BELOTERO® REVIVE水光針療程效果</SectionHeader>

                <MyContainer $maxWidth="80rem">
                    <Row {...slideUp}>
                        <Column $sm={6} $widthInColumn={"100%"}>
                            <SquareContainer>
                                <CompareSlider
                                    left={case1Before}
                                    right={case1After}
                                    handle={reviewHandle}
                                    position={position1}
                                    onPositionChange={setPosition1}
                                    arrows
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>
                        </Column>

                        <Column $sm={6} $widthInColumn={"100%"}>
                            <SquareContainer>
                                <CompareSlider
                                    left={case2Before}
                                    right={case2After}
                                    handle={reviewHandle}
                                    position={position2}
                                    onPositionChange={setPosition2}
                                    arrows
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>
                        </Column>
                    </Row>

                    <Row {...slideUp}>
                        <Column $sm={6} $widthInColumn={"100%"}>
                            <SquareContainer>
                                <CompareSlider
                                    left={case3Before}
                                    right={case3After}
                                    handle={reviewHandle}
                                    position={position3}
                                    onPositionChange={setPosition3}
                                    arrows
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>
                        </Column>

                        <Column $sm={6} $widthInColumn={"100%"}>
                            <SquareContainer>
                                <CompareSlider
                                    left={case4Before}
                                    right={case4After}
                                    handle={reviewHandle}
                                    position={position4}
                                    onPositionChange={setPosition4}
                                    arrows
                                    width={400}
                                    height={400}
                                    aspectRatio={true}
                                />
                            </SquareContainer>
                        </Column>
                        <Note>Courtesy of (from left to right):Sarawalai Rakchart, M.D.,Dissapong Panithaporn, M.D
                            Surasawan Wongkietkachorn, M.D., Dr. Lau Wang Tak FRCSEd</Note>
                    </Row>

                    <br/>
                    <Center>
                        <LinkButton to={routes.beloteroRevive}>了解更多Belotero® Revive水光針</LinkButton>
                    </Center>
                    <br/><br/>

                </MyContainer>

                {
                    lowerParagraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }

                <SectionHeader {...slideUp}>打完水光針的術後注意事項</SectionHeader>
                <ProductDescription {...slideUp}>
                    建議在療程後當日不要化妝和用手觸碰治療位置，以免傷口發炎和皮膚敏感。建議在療程後24小時內，避免劇烈運動及攝取酒精。一般而言，治療後常見的不適感例如注射部位皮膚變色、輕微疼痛、紅腫、充血或瘀青，均會於療程後幾天後消散。
                    若在過程中或之後有任何不適，請立即通知您的醫生。
                </ProductDescription>


                <NoteWrapper>
                    <AlignLeftNote>
                        Reference: <br/>
                        Individual results may vary. <br/>
                        1. Comparing the hyaluronic acid fillers in Hong Kong as of November 2023. <br/>
                        2. BELOTERO Revive Instruction for use 2022 <br/>
                        3. Hertz-Kleptow D, et al. Clin Cosmet Investig Dermatol. 2019;12:563–572. <br/>
                        4. Kerscher M, et al. Plast Reconstr Surg Glob Open. 2021 Dec 3;9(12):e3973. <br/>
                        5. Marquardt K, et al. Hydration eect of a revitalizing CPM cross-linked hyaluronic acid gel
                        containing glycerol. Poster presented at AWMC World Congress 2020 Monaco <br/>
                        6. Kleine-Borger L, et al. Skin Res Technol. 2022;1-10 <br/>
                        # Improvement in skin radiance defined by decrease in haemoglobin levels and increase in melanin
                        levels as measured by a mexameter<sup>3</sup>.
                    </AlignLeftNote>
                </NoteWrapper>

            </Center>
        </Container>
    );
}
