import PropTypes from "prop-types";
import React from "react";
import {rgba} from "polished";
import styled from "styled-components";


const Root = styled.div`
  display: block;
  max-width: 42rem;
  border-radius: 1.7rem;
  padding: 0 0 3.3rem;
  margin: 0 auto 1rem;
  box-shadow: 0 .4rem .4rem ${({theme}) => rgba(theme.black, .25)};
  background-color: ${({theme}) => theme.white};
`;

const Thumbnail = styled.div`
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: '';
    display: block;
    padding-top: 51.3344%;
  }
`;

const Content = styled.div`
  padding-left: 2.2rem;
  padding-right: 2.2rem;
`;


export default function Card (props) {
  const {children, thumbnail, ...restProps} = props;
  return (
      <Root {...restProps}>
        <Thumbnail style={{backgroundImage: `url(${thumbnail})`}} />
        <Content>{children}</Content>
      </Root>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  thumbnail: PropTypes.string.isRequired,
};
