import React from "react";
import TransitionLink from "@components/TransitionLink";
import inner from "@images/find-certified-medical-center-inner.svg";
import outer from "@images/find-certified-medical-center-outer.svg";
import {rgba} from "polished";
import routes from "@config/routes";
import styled from "styled-components";
import {useMatch} from "@reach/router";


const Root = styled(TransitionLink)`
  position: fixed;
  top: 50%;
  right: -3.6rem;
  width: 3.6rem;
  min-height: 10rem;
  padding: 3.5rem 3.6rem 1rem;
  padding-left: 0;
  border-radius: 1rem;
  background-color: ${({theme}) => theme.gray100};
  white-space: pre-wrap;
  color: ${({theme}) => theme.gray};
  text-align: center;
  text-decoration: none;
  line-height: 1.2;
  z-index: ${({theme}) => theme.zIndexes.findCertifiedMedicalCenter};
  box-shadow: 0px 4px 4px ${({theme}) => rgba(theme.black, .25)};
  transform: translateY(-50%);
  transition: opacity ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic} ${({theme}) => theme.durations.basic};

  &:visited, &:focus, &:hover {
    color: ${({theme}) => theme.gray};
    text-decoration: none;
    outline: none;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 1rem;
    left: 25%;
    margin-left: -1.1rem;
    display: inline-block;
    width: 2.1rem;
    height: 2.1rem;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &:before {
    animation: ${({theme}) => theme.animations.spinning} 1.2s infinite;
    background-image: url(${outer});
    background-size: contain;
  }
  &:after {
    background-image: url(${inner});
    background-size: auto;
  }
`;

const text = "尋找獲認證的醫療中心".split("\n");

export default function FindCertifiedMedicalCenter () {
  const match1 = useMatch(routes.certifiedMedicalCenter);
  const match2 = useMatch(routes.certifiedMedicalCenterClinicCenter);
  const match3 = useMatch(routes.certifiedMedicalCenterReviveClinicCenter);
  if (match1) { return null; }
  if (match2) { return null; }
  if (match3) { return null; }
  return (<Root to={routes.certifiedMedicalCenterClinicCenter}>{text}</Root>);
}
