import React from "react";
import styled, {css, keyframes} from "styled-components";


const animationSets = [
  {
    background: css`${({theme}) => theme.transitionAnimationBackgroundAttributes}`,
    exit: keyframes`
      0% { transform: translateX(-100%); }
      100% { transform: none; }
    `,
    entry: keyframes`
      0% { transform: none; }
      100% { transform: translateX(100%); }
    `,
  },
  {
    background: css`${({theme}) => theme.transitionAnimationBackgroundAttributes}`,
    exit: keyframes`
      0% { transform: translateX(100%); }
      100% { transform: none; }
    `,
    entry: keyframes`
      0% { transform: none; }
      100% { transform: translateX(-100%); }
    `,
  },
  {
    background: css`${({theme}) => theme.productColorLine}`,
    exit: keyframes`
      0% { transform: translateY(-100%); ${({theme}) => theme.productColorLine} }
      100% { transform: none; ${({theme}) => theme.productColorLine} }
    `,
    entry: keyframes`
      0% { transform: none; ${({theme}) => theme.productColorLine} }
      100% { transform: translateY(100%); ${({theme}) => theme.productColorLine} }
    `,
  },
  {
    background: css`${({theme}) => theme.productColorLine}`,
    exit: keyframes`
      0% { transform: translateY(100%); ${({theme}) => theme.productColorLine} }
      100% { transform: none; ${({theme}) => theme.productColorLine} }
    `,
    entry: keyframes`
      0% { transform: none; ${({theme}) => theme.productColorLine} }
      100% { transform: translateY(-100%); ${({theme}) => theme.productColorLine} }
    `,
  },
];

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100%);

  ${({$animationSet, $transitionState}) => {
    if ($transitionState === "exit") { return css`${$animationSet.background} animation: .4s ${$animationSet.exit} forwards;`; }
    if ($transitionState === "entry") { return css`${$animationSet.background} animation: .4s ${$animationSet.entry} forwards;`; }
  }}
  z-index: ${({theme}) => theme.zIndexes.transitionAnimationCover};
`;

export const Context = React.createContext();

export default class TransitionAnimation extends React.Component {

  state = {
    /**
     * @type {"entry"|"exit"|null}
     */
    transitionState: null,

    /**
     * @type {number}
     */
    animationSetIndex: Math.floor(Math.random() * animationSets.length),
  };

  exit = () => { this.setState({transitionState: "exit"}); };
  entry = () => { this.setState({transitionState: "entry"}); };
  initial = () => {
    this.setState(prevState => ({
      transitionState: null,
      animationSetIndex: (prevState.animationSetIndex + 1) % animationSets.length,
    }));
  };

  shouldComponentUpdate (nextProps, nextState) {
    if (nextState.transitionState !== this.state.transitionState) { return true; }
    return false;
  }

  componentDidUpdate () {
    const {transitionState} = this.state;
    if (transitionState === "entry") { document.body.style.overflow = "hidden"; }
    if (transitionState === null) { document.body.style.overflow = null; }
  }

  render () {
    const {children} = this.props;
    const {animationSetIndex, transitionState} = this.state;
    const {entry, exit, initial} = this;
    return (
      <>
        <Context.Provider value={{entry, exit, initial}}>
          {children}
        </Context.Provider>
        <Cover key="Cover" $animationSet={animationSets[animationSetIndex]} $transitionState={transitionState} />
      </>
    );
  }
}
