import PropTypes from "prop-types";
import {navigate} from "gatsby";
import {useEffect} from "react";


export default function Redirect (props) {
  const {to} = props;
  useEffect(() => { navigate(to); });
  return null;
}

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
};
