import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";
import TransitionLink from "@components/TransitionLink";
import menu from "@config/menu";
import navMerzLogo from "@images/nav-merz-logo.svg";
import {useLocation} from "@reach/router";


const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({theme}) => theme.headerBackground};
  transform: ${({$active}) => $active ? "none" : "translateX(100%)"};
  transition: transform ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
  z-index: ${({theme}) => theme.zIndexes.headerXsMax};

  // Never show in desktop / tablet
  ${({theme}) => theme.breakpoints.lg} { display: none; }

  &:before {
    content: '';
    display: block;
    padding-top: 5rem;
  }
`;

const Cross = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 3.6rem;
  height: 3.6rem;

  &:before, &:after {
    content: '';
    position: absolute;
    width: .3rem;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -.1rem;
    background-color: ${({theme}) => theme.black};
  }

  &:before { transform: rotate(45deg); }
  &:after { transform: rotate(135deg); }
`;

const itemStyle = css`
  display: block;
  text-decoration: none;
  text-align: center;
  color: ${({$active, theme}) => $active ? theme.navActive : theme.navColor};
  line-height: 1;
  margin-top: .6em;
  margin-bottom: .8em;

  &:visited, &:focus, &:hover {
    color: ${({$active, theme}) => $active ? theme.navActive : theme.navColor};
    text-decoration: none;
    outline: none;
  }

  &:after {
    content: ${props => props.$hasSubmenu ? "''" : "none"};
    display: inline-block;
    width: .6em;
    height: .6em;
    border: 1px solid ${({theme}) => theme.black};
    border-top: none;
    border-left: none;
    transform: rotate(${({$expand}) => $expand ? "45deg" : "-45deg"});
    margin-left: .3em;
    margin-bottom: .1em;
    transition: transform ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
  }
`;

/**
 * Use `ItemDiv` if it's a submenu;
 * Use `ItemLink` if it's a link (w/o submenu).
 */
const ItemDiv = styled.div`${itemStyle}`;
const ItemLink = styled(TransitionLink)`${itemStyle}`;

const SubmenuRoot = styled.div`
  background-color: ${({theme}) => theme.headerOrange};
  overflow: hidden;
  transition: height ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
`;

const SubmenuInner = styled.div`
  position: relative;
  padding: 1em 0;
`;

const SubmenuItem = styled(TransitionLink)`
  display: block;
  line-height: 1;
  margin-bottom: .8em;
  text-align: center;
  color: ${({theme}) => theme.white};
  text-decoration: none;

  &:visited, &:focus, &:hover {
    color: ${({theme}) => theme.white};
    text-decoration: none;
    outline: none;
  }

  &:last-child { margin-bottom: 0; }
`;

const SubmenuPlaceholder = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 1px;
  }
`;

const MerzLogoWrap = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 10rem;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);

  &:after {
    content: '';
    display: inline-block;
    width: .6em;
    height: .6em;
    border: 1px solid ${({theme}) => theme.black};
    border-top: none;
    border-left: none;
    transform: ${props => props.$merzMenuOpen ? "rotate(45deg)" : "rotate(-45deg)"};
    transition: transform ${({theme}) => theme.durations.basic + "  " + theme.easings.basic};
    margin-left: .3em;
    margin-bottom: .2em;
  }
`;

const MerzLogo = styled.img`
  display: inline-block;
  max-width: 17.5rem;
`;

function Submenu (props) {
  const {active, items} = props;
  const innerRef = useRef();
  const [rootHeight, setRootHeight] = useState(0);

  useEffect(() => {
    setRootHeight(active ? innerRef.current.getBoundingClientRect().height : 0);
  }, [active]);

  return (
    <SubmenuRoot style={{height: rootHeight}}>
      <SubmenuInner ref={innerRef}>
        {items.map(({title, url}, index) => (
          <SubmenuItem key={index} to={url}>{title}</SubmenuItem>
        ))}
      </SubmenuInner>
    </SubmenuRoot>
  );
}

Submenu.propTypes = {
  active: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default function MobileHeaderMenu (props) {
  const {active, setActive, onMerzLogoClick, merzMenuOpen} = props;
  /**
   * @type {!number} Either the index of `menu` item or neither (`null`)
   */
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);
  const {pathname} = useLocation();
  const onItemClicks = useMemo(() => menu.map((item, index) => () => setActiveSubmenuIndex(prev => prev !== index ? index : null)), []);
  const onCrossClick = useCallback(() => setActive(false), [setActive]);

  return (
    <Root $active={active}>
      <Cross onClick={onCrossClick} />
      {menu.map(({url, title, submenu}, index) => {
        const Item = Array.isArray(submenu) ? ItemDiv : ItemLink;
        return (
          <React.Fragment key={index}>
            <Item
              to={url}
              $hasSubmenu={!!submenu}
              $active={url === "/" ? url === pathname : pathname.startsWith(url)}
              $expand={activeSubmenuIndex === index}
              onClick={onItemClicks[index]}
            >
              {title}
            </Item>
            {typeof submenu !== "undefined" ? <Submenu items={submenu} active={activeSubmenuIndex === index} /> : <SubmenuPlaceholder />}
          </React.Fragment>
        );
      })}
      <MerzLogoWrap onClick={onMerzLogoClick} $merzMenuOpen={merzMenuOpen}>
        <MerzLogo src={navMerzLogo} alt="MERZ logo" />
      </MerzLogoWrap>
    </Root>
  );
}

MobileHeaderMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};
