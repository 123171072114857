import styled from "styled-components";


const Reference = styled.div`
  white-space: pre-wrap;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0; // To override global style
`;

export default Reference;
