import {Column, Row} from "@components/Row";
import BulletPoints from "@components/BulletPoints";
import Center from "@components/Center";
import Container from "@components/Container";
import H1 from "@components/H1";
import LinkButton from "@components/LinkButton";
import React from "react";
import ReferenceComponent from "@components/Reference";
import Spacer from "@components/Spacer";
import model from "@images/safety-model-1.png";
import modelXs from "@images/safety-model-1-xs.png";
import routes from "@config/routes";
import {slideUp} from "@config/sal";
import styled from "styled-components";
import SectionHeader from "../components/SectionHeader";
import Awards from "./BeloteroHyaluronicAcid/Awards";


const H2 = styled.h2`
  margin-top: 0;
`;

const Reference = styled(ReferenceComponent)`
  text-align: center;
`;

const OneWord = styled.div`
  display: inline-block;
`;

const Section = styled.div`
  background-color: ${({$colored}) => $colored ? "#C0EFEC" : "inherit"};
  padding-top: 1rem;
  padding-bottom: 3rem;
  text-align: center;

  &:before {
    content: '';
    display: block;
  }
`;

const H3 = styled.h3`
  //font-size: 22px;
  font-size: 2.4rem;
`;

const Ref = styled(Reference)`
  @media (max-width: 768px) {
    padding: 0 20px;
    text-align: left;
  }
`;

const MarginTop = styled.div`
  margin-top: 40px;
`;

const BulletH3 = styled.h3`
  text-align: left;
`;

const BubbleContainer = styled.div`
  position: relative;

  max-width: 525px;
  width: 100%;
  height: 305px;
  
  @media screen and (max-width: 750px) {
    height: 500px;
    max-width: 400px;
  }
`

const OrangeBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: ${({$left}) => $left ? $left + "px" : "unset"};
  right: ${({$right}) => $right ? $right + "px" : "unset"};
  top: ${({$top}) => $top + "px"};

  width: ${({$diameter}) => $diameter ? $diameter + "px" : 0};
  height: ${({$diameter}) => $diameter ? $diameter + "px" : 0};
  background-image: linear-gradient(328deg, #FE6F08 5.67%, #E76100 48.41%, #CA5A08 103.06%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;

  @media screen and (max-width: 750px) {
    top: ${({$mtop}) => $mtop ? $mtop + "%" : "unset"};
    left: ${({$mleft}) => $mleft ? $mleft + "%" : "unset"};
    right: ${({$mright}) => $mright ? $mright + "%" : "unset"};
  }
`;

const WhiteBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: ${({$left}) => $left ? $left + "px" : "unset"};
  right: ${({$right}) => $right ? $right + "px" : "unset"};
  top: ${({$top}) => $top + "px"};

  width: ${({$diameter}) => $diameter ? $diameter + "px" : 0};
  height: ${({$diameter}) => $diameter ? $diameter + "px" : 0};
  background-image: linear-gradient(144deg, #EDEDED 21.25%, rgba(255, 255, 255, 0.00) 98.37%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  
  @media screen and (max-width: 750px) {
    top: ${({$mtop}) => $mtop ? $mtop + "%" : "unset"};
    left: ${({$mleft}) => $mleft ? $mleft + "%" : "unset"};
    right: ${({$mright}) => $mright ? $mright + "%" : "unset"};
  }
`;

const TextWrapper = styled.div`
  width: 80%;
  color: ${({$textColor}) => $textColor === "orange" ? "#D43300" : "#FFF"};
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`;

const BubbleSup = styled.sup`
  display: inline;
  transform: none;
  font-size: 8px;
  top: -12px;
`;

export default function Safety() {
    return (
        <>
            <Section>
                <Container>
                    <H1 {...slideUp}>BELOTERO®透明質酸療程功效及安全性</H1>
                    <Row>
                        <Column $sm={4}>
                            <img src={model} alt="Model" {...slideUp} data-xs-max-display-none/>
                            <img src={modelXs} alt="Model" {...slideUp} data-sm-display-none/>
                            <Spacer $xsMax="2em"/>
                        </Column>
                        <Column $sm={8}>
                            <H2 {...slideUp}>療效顯著，安全快捷</H2>

                            <p {...slideUp}>
                                BELOTERO® 透明質酸產品系列擁有極佳的肌膚融合配方<sup>1</sup>，效果自然持久<sup>2#</sup>，一系列5款透明質酸填充劑，由改善膚質、填補不同程度皺紋至重塑面部輪廓，迎合不同部位和注射層面需要，提供多元又貼心的選擇。
                            </p>

                            <p {...slideUp}>
                                BELOTERO® 透明質酸療程效果即時可見，亦無須療程復原期，療程簡單快捷，立即讓您煥然一新，擺脫初老魔「皺」。對於追求自然和減淡衰老的您，
                                BELOTERO® 透明質酸產品系列就是您的理想選擇！
                            </p>
                            <p {...slideUp}>
                                BELOTERO®透明質酸療程簡單快捷，亦無須療程復原期，療程後即可回復日常活動<sup>3-4</sup>。
                            </p>
                            <div>
                                <Row $justifyContent="center">
                                    <BubbleContainer {...slideUp}>
                                        <WhiteBubble $diameter={120} top={0} $left={0} $mtop={70} $mleft={60}>
                                            <TextWrapper $textColor="orange">
                                                臨床證明<br/>安全有效<BubbleSup>1,2</BubbleSup>
                                            </TextWrapper>
                                        </WhiteBubble>

                                        <WhiteBubble $diameter={135} $top={0} $right={100} $mtop={1} $mright={5}>
                                            <TextWrapper $textColor="orange">
                                                減少藍光<br/>反應機會<BubbleSup>5</BubbleSup>
                                            </TextWrapper>
                                        </WhiteBubble>

                                        <WhiteBubble $diameter={140} $top={165} $right={150} $mtop={35}>
                                            <TextWrapper $textColor="orange">
                                                通過美國<br/>FDA認可*及<br/>歐盟CE認証
                                            </TextWrapper>
                                        </WhiteBubble>

                                        <OrangeBubble $diameter={140} $top={45} $left={140} $mtop={1} $mleft={1}>
                                            <TextWrapper $textColor="white">
                                                療程效果<br/>即可見<BubbleSup>1,3</BubbleSup><br/>效果自然<BubbleSup>4</BubbleSup>
                                            </TextWrapper>
                                        </OrangeBubble>

                                        <OrangeBubble $diameter={140} $top={145} $left={0} $mtop={35} $mleft={50}>
                                            <TextWrapper $textColor="white">
                                                幾乎無須<br/>復原期<BubbleSup>1,3</BubbleSup>
                                            </TextWrapper>
                                        </OrangeBubble>

                                        <OrangeBubble $diameter={125} $top={100} $right={1} $mtop={70} $mleft={12}>
                                            <TextWrapper $textColor="white">
                                                獲使用者<br/>高度滿意<BubbleSup>6</BubbleSup>
                                            </TextWrapper>
                                        </OrangeBubble>

                                    </BubbleContainer>
                                </Row>

                            </div>

                        </Column>
                    </Row>
                </Container>
            </Section>

            <Section>
                <Awards/>
            </Section>
        </>
    );
}
