import Container from "@components/Container";
import ProductColorsLine from "@components/ProductColorsLine";
import React from "react";
import styled, {css} from "styled-components";
import email from "@images/footer-contact-email.svg";
import facebook from "@images/footer-contact-facebook.svg";
import instagram from "@images/footer-contact-instagram.svg";
import privacyPolicy from "@documents/Merz-Privacy-Policy-May-2024.pdf";
import termsOfUsePolicy from "@documents/terms-of-use-policy.pdf";
import weibo from "@images/footer-contact-weibo.svg";
import youtube from "@images/footer-contact-youtube.svg";
import merz from "@images/footer-merz-logo.svg";


const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36rem;
  background-color: ${({theme}) => theme.gray100};
  color: ${({theme}) => theme.black};
`;

const Inner = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 400;

  ${({theme}) => theme.breakpoints.sm} {
    margin-bottom: 3rem;
  }
`;

const Contact = {
  Wrap: styled.div`
    //

    ${({theme}) => theme.breakpoints.xsMax} {
      text-align: center;
    }
  `,
  List: styled.div`
    font-family: ${({theme}) => theme.secondaryFontFamily};
    display: inline-block;

    ${({theme}) => theme.breakpoints.sm} {
      display: flex;
      align-items: center;
      flex-direction: columns;
      justify-content: space-between;
    }
  `,
  Item: styled.div`
    display: flex;
    flex-direction: columns;
    align-items: center;
    margin-bottom: 2rem;

    ${({theme}) => theme.breakpoints.sm} {
      justify-content: center;
    }
  `,
  ExternalLinkItem: styled.div`
    display: flex;
    flex-direction: columns;
    justify-content: center;
    align-items: center;

    ${({theme}) => theme.breakpoints.sm} { margin-bottom: 2rem; }
  `,
  Icon: styled.img`
    width: 3.2rem;
  `,
  Text: styled.a`
    color: inherit;
    text-decoration: none;
    margin-left: 1.4rem;

    &:visited, &:focus, &:hover {
      color: inherit;
      text-decoration: none;
      outline: none;
    }
  `,
  ExternalLink: styled.a`
    //

    &:not(:last-child) {
      margin-right: 1.4rem;
    }
  `,
};

const MerzLogo = styled.img`
  margin: 0 auto;
`;

const termsItemStyle = css`
  display: inline-block;
  padding: 0 !important;
  margin: 0;
  color: inherit !important;
  text-decoration: none;
  line-height: 1.5 !important;
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;

  &:visited, &:focus, &:hover {
    color: inherit;
    text-decoration: none;
    outline: none;
  }
`;

const Terms = {
  List: styled.div`
    font-size: 1rem;
    text-align: center;

    ${({theme}) => theme.breakpoints.sm} { font-size: 1.2rem; }
  `,
  Item: styled.a`${termsItemStyle}`,
  CookieItemButton: styled.button`${termsItemStyle}`,
};

const CopyrightStatement = styled.div`
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.5;
`;

export default function Footer () {
  return (
    <>
      <br /><br /><br /><br />
      <ProductColorsLine />
      <Root>
        <Inner>
          <br />
          <MerzLogo src={merz} alt="merz logo"/>
          <br />

          <Title>聯絡我們</Title>

          <Container.Small>
            <Contact.Wrap>
              <Contact.List>

                {/* Phone */}
                {/*<Contact.Item>*/}
                {/*  <Contact.Icon src={phone} />*/}
                {/*  <Contact.Text href="tel:+85221192250" target="_blank">+852 2119 2250</Contact.Text>*/}
                {/*</Contact.Item>*/}

                {/* Email */}
                <Contact.Item>
                  <Contact.Icon src={email} alt="email logo"/>
                  <Contact.Text href="mailto:hkenquiry@merz.com" target="_blank">hkenquiry@merz.com</Contact.Text>
                </Contact.Item>

                {/* Facebook */}
                <Contact.Item>
                  <Contact.Icon src={facebook} alt="facebook logo"/>
                  <Contact.Text href="https://www.facebook.com/BeloteroHK/" target="_blank">Belotero.hk</Contact.Text>
                </Contact.Item>

                {/* Others */}
                <Contact.ExternalLinkItem>
                  <Contact.ExternalLink href="https://www.instagram.com/merzaesthetics_hk/" target="_blank"><Contact.Icon src={instagram} alt="instagram of Beletero"/></Contact.ExternalLink>
                  <Contact.ExternalLink href="https://www.youtube.com/channel/UCjg2HhJECKuEZ0zZuNC1CxA" target="_blank"><Contact.Icon src={youtube} alt="youtube of Beletero"/></Contact.ExternalLink>
                  <Contact.ExternalLink href="https://www.weibo.com/u/6962232147" target="_blank"><Contact.Icon src={weibo} alt="Weibo of Beletero"/></Contact.ExternalLink>
                </Contact.ExternalLinkItem>
              </Contact.List>
            </Contact.Wrap>
          </Container.Small>

          <br />

          <Terms.List>
            <Terms.Item href={termsOfUsePolicy} target="_blank">TERMS OF USE POLICY</Terms.Item>
            ｜
            <Terms.Item href={privacyPolicy} target="_blank">PRIVACY POLICY</Terms.Item>
            {/*｜*/}
            {/* TODO */}
            {/*<Terms.CookieItemButton id="ot-sdk-btn" className="ot-sdk-show-settings">COOKIE設定</Terms.CookieItemButton>*/}
          </Terms.List>

          <CopyrightStatement>COPYRIGHT © {(new Date()).getFullYear()} MERZ HONG KONG LIMITED. ALL RIGHTS RESERVED.</CopyrightStatement>

          <br /><br />
        </Inner>
      </Root>
    </>
  );
}
