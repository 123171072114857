import React, {useEffect, useRef, useState} from "react";
import {ThemeProvider} from "styled-components";
import FindCertifiedMedicalCenter from "@partials/FindCertifiedMedicalCenter";
import Footer from "@partials/Footer";
import GlobalStyle from "@partials/GlobalStyle";
import Header from "@partials/Header";
import Helmet from "@components/Helmet";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import SalHandler from "@partials/SalHandler";
import TransitionAnimation from "@partials/TransitionAnimation";
import TransitionHandler from "gatsby-plugin-transition-link/components/TransitionHandler"
import theme from "@config/theme";
import {useLocation} from "@reach/router";
import styled from "styled-components";

import "../scss/noMichelle.scss";

const RedirectLink = styled(Link)`
  position: absolute;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 1px;
  opacity: 0;
`;

function getPageComponent(uri) {
    try {
        const Component = require(__dirname + "/../pages" + uri).default;
        return Component;
    } catch (ex) {
        console.error(ex);
        console.error("page not found");
    }
    return require(__dirname + "/../pages/404").default;
}

export default function Layout(props) {
    const {uri} = props;
    const redirectLinkRef = useRef();
    const Component = getPageComponent(uri);
    const [mounted, setMounted] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const location = useLocation();
    const {pathname, search} = location;

    const [className, setClassName] = useState("");

    useEffect(() => {
        const previousPageUrl = sessionStorage.getItem("previousPageUrl");
        if (previousPageUrl === null || previousPageUrl === uri) {
            setClassName(uri);
        }
        if (uri === "/") {
            setClassName("homepage");
        }

    }, [uri]);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (pathname.substr(-1) === "/") {
            const newPathname = pathname.substr(0, pathname.length - 1);
            setRedirect({from: pathname, to: newPathname + search});
        }
    }, [pathname, search]);

    useEffect(() => {
        if (redirect && pathname === redirect.from && redirect.to && redirectLinkRef.current) {
            redirectLinkRef.current.click();
            setRedirect(null);
        }
    }, [pathname, search, redirect, redirectLinkRef]);

    return (
        <div className={className}>
            <ThemeProvider theme={theme}>
                <TransitionAnimation>
                    <TransitionHandler>
                        <>
                            <Helmet/>
                            <GlobalStyle/>
                            <Header/>
                            <SalHandler>
                                <Component {...props} mounted={mounted}/>
                            </SalHandler>
                            <FindCertifiedMedicalCenter/>
                            <Footer/>
                        </>
                    </TransitionHandler>
                </TransitionAnimation>
            </ThemeProvider>
            <RedirectLink
                ref={redirectLinkRef}
                to={redirect ? redirect.to : "/"}
            >&nbsp;</RedirectLink>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
