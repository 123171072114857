import React from "react";
import TransitionLink from "@components/TransitionLink";
import styled, {css, keyframes} from "styled-components";


const fadeIn = keyframes`
  0% { left: -100%; }
  100% { left: 0; }
`;
const fadeOut = keyframes`
  0% { left: 0; }
  100% { left: 100%; }
`;

const Root = styled(TransitionLink)`
  position: relative;
  display: inline-block;
  background-color: ${({theme}) => theme.white};
  padding: 1.4rem 3rem 1.6rem;
  font-size: 1.6rem;

  padding: 2.2rem 5rem 2.3rem;
  font-size: 1.9rem;

  font-weight: 400;
  text-align: center;
  text-decoration: none;
  line-height: 1.4;
  border: .1rem solid ${({theme}) => theme.productColors.orange};
  color: ${({theme}) => theme.productColors.orange};
  transition: color ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic};
  overflow: hidden;

  > span {
    position: relative;
    z-index: 9;
  }

  &:visited, &:focus, &:hover {
    color: ${({theme}) => theme.productColors.orange};
    text-decoration: none;
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.productColors.orange};
    animation: ${fadeOut} ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic} 1 forwards;
  }

  &:hover {
    color: ${({theme}) => theme.white};

    &:before {
      left: 0;
      animation: ${fadeIn} ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic} 1 forwards;
    }
  }
`;

export default function LinkButton (props) {
  const {children, ...restProps} = props;
  return (
    <Root {...restProps}>
      <span>{children}</span>
    </Root>
  );
}
