import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import CardComponent from "@components/Card";
import Center from "@components/Center";
import Container from "@components/Container";
import H1 from "@components/H1";
import ModalVideo from "react-modal-video";
import {math} from "polished";

import SectionHeader from "../../components/SectionHeader";
import blogThumbnail1 from "@images/Blog 1_cover.jpg";
import blogThumbnail2 from "@images/Blog 2_cover.jpg";
import blogThumbnail3 from "@images/Blog 3_cover.jpg";
import routes from "../../config/routes";
import TransitionLink from "../../components/TransitionLink";


const Root = styled.div`
  position: relative;
  overflow: hidden;
`;

const Rainbow = styled.div`
  position: absolute;
  top: 0;
  width: 15%;
  white-space: nowrap;

  ${({theme}) => theme.breakpoints.xsMax} {
    top: initial;
    right: 2rem;
    bottom: 100%;
    height: 27rem;
    margin-bottom: -7rem;
  }

  ${({theme}) => theme.breakpoints.sm} {
    height: 95%;
    right: 14rem;
  }

  > div {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    transition: height ${({theme}) => theme.durations.slow} ${({theme}) => theme.easings.basic};

    &:nth-child(1) {
      height: ${({$show}) => $show ? "86%" : "0"};
      transition-delay: ${math("4 * 0.2s")};
      background-color: ${({theme}) => theme.productColorsLinear[0]};
    }

    &:nth-child(2) {
      height: ${({$show}) => $show ? "96%" : "0"};
      transition-delay: ${math("2 * 0.2s")};
      background-color: ${({theme}) => theme.productColorsLinear[1]};
    }

    &:nth-child(3) {
      height: ${({$show}) => $show ? "100%" : "0"};
      transition-delay: ${math("1 * 0.2s")};
      background-color: ${({theme}) => theme.productColorsLinear[2]};
    }

    &:nth-child(4) {
      height: ${({$show}) => $show ? "92%" : "0"};
      transition-delay: ${math("3 * 0.2s")};
      background-color: ${({theme}) => theme.productColorsLinear[3]};
    }

    &:nth-child(5) {
      height: ${({$show}) => $show ? "96%" : "0"};
      transition-delay: ${math("2 * 0.2s")};
      background-color: ${({theme}) => theme.productColorsLinear[4]};
    }
  }
`;

const ExpertAdviceContainer = styled(Container)`
  //
  //column-width: 30px;
  //  column-gap: 10px;
  //  -webkit-box-pack: center;
  //  -ms-flex-pack: center;
  //  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  text-align: left;
  max-width: 780px;
  width: 100%;

  ${({theme}) => theme.breakpoints.xsMax} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CardWrap = styled.div`
  //
  width: 100%;
  //overflow: hidden;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 10px 20px;
  display: inline-block;
  vertical-align: top;
`;

const Card = styled(CardComponent)`
  vertical-align: top;
  cursor: pointer;

  ${({theme}) => theme.breakpoints.xsMax} {
    width: auto;
    margin-left: ${({theme}) => theme.containerPaddingXsMax};
    margin-right: ${({theme}) => theme.containerPaddingXsMax};
  }
`;

const OneWord = styled.div`
  display: inline-block;
`;

const BlogWrap = styled.div`
  display: inline-block;
  position: relative;
  max-width: 360px;
  margin: 0 10px 20px;

  background-color: white;
  box-shadow: 0 .4rem .4rem rgba(0, 0, 0, .25);
  border-radius: 1.7rem;
`;

const BlogFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlogThumbnail = styled.img`
  width: 360px;
  aspect-ratio: 1/1;
  object-fit: cover;
`;

const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlogTitle = styled(SectionHeader)`
  padding: 0 1.2rem;

  font-size: 1.8rem;
`;

export default function ExpertAdvice() {
    const [showRainbow, setShowRainbow] = useState(false);
    const [youtubeId, setYoutubeId] = useState(null);
    const theme = useTheme();

    const blogList = [
        {
            link: routes.blog3,
            title: <BlogTitle>甚麼是蘋果肌?為何會下垂？教你 5大方法提升蘋果肌！</BlogTitle>,
            thumbnail: blogThumbnail3,
        },
        {
            link: routes.blog2,
            title: <BlogTitle>水光針有無用？多久打一次？水光針懶人包一次教你選！</BlogTitle>,
            thumbnail: blogThumbnail2,
        },
        {
            link: routes.blog1,
            title: <BlogTitle>一文認識 Belotero®透明質酸系列：<br/>5款產品分析全攻略</BlogTitle>,
            thumbnail: blogThumbnail1,
        },
    ];


    //const options = useMemo(() => ({
    //  arrows: true,
    //  dots: true,
    //  slidesToShow: 2,
    //  responsive: [{
    //    breakpoint: theme.breakpointWidths.xsMax,
    //    settings: {slidesToShow: 1},
    //  }],
    //}), [theme.breakpointWidths.xsMax]);

    useEffect(() => {
        setShowRainbow(true);
    }, []);

    return (
        <Root>
            <Rainbow $show={showRainbow}>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </Rainbow>
            <H1>最新分享</H1>
            <ExpertAdviceContainer $maxWidth="92rem">

                {
                    blogList.map((blogItem) => {
                        return (
                            <TransitionLink to={blogItem.link}>
                                <BlogWrap>
                                    <BlogFlex>
                                        <BlogThumbnail src={blogItem.thumbnail} alt="blog thumbnail" />
                                        <BlogContent>
                                            {blogItem.title}
                                        </BlogContent>
                                    </BlogFlex>
                                </BlogWrap>
                            </TransitionLink>

                        );
                    })
                }

            </ExpertAdviceContainer>
        </Root>
    );
}
