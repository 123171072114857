import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import styled from "styled-components";


const Root = styled.h1`
  position: relative;
  overflow: hidden;
`;

const Text = styled.span`
  display: inline-block;
  transform: ${({$show}) => $show ? "none" : "translateY(200%)"};
  transition: transform ${({theme}) => theme.durations.basic} ${({theme}) => theme.easings.basic} ${({theme}) => theme.durations.basic};
`;

export default function H1 (props) {
  const {children, ...restProps} = props;
  const [show, setShow] = useState(false);
  useEffect(() => { setShow(true); }, []);
  return (
    <Root {...restProps}>
      <Text $show={show}>{children}</Text>
    </Root>
  );
}

H1.propTypes = {
  children: PropTypes.node.isRequired,
};
