import React, {useContext} from "react";
import {Context as TransitionContext} from "@partials/TransitionAnimation";
import TransitionLinkComponent from "gatsby-plugin-transition-link";


export default function TransitionLink (props) {
  const {entry, exit, initial} = useContext(TransitionContext);
  return (
    <TransitionLinkComponent
      {...props}
      exit={{length: .4, trigger: exit}}
      entry={{delay: .8, length: .4, trigger: () => { entry(); window.setTimeout(initial, 400); }}}
    />
  );
}
