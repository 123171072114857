import styled, {css} from "styled-components";

export const Spacer = styled.div`
  display: ${({$display}) => typeof $display === "string" ? $display : "block"};

  ${({$all, theme}) => typeof $all !== "undefined" ? `height: ${$all};` : ""}

  ${({$sm, theme}) => typeof $sm !== "undefined" ? css`${theme.breakpoints.sm} { height: ${$sm}; }` : ""}
  ${({$md, theme}) => typeof $md !== "undefined" ? css`${theme.breakpoints.md} { height: ${$md}; }` : ""}
  ${({$lg, theme}) => typeof $lg !== "undefined" ? css`${theme.breakpoints.lg} { height: ${$lg}; }` : ""}
  ${({$xl, theme}) => typeof $xl !== "undefined" ? css`${theme.breakpoints.xl} { height: ${$xl}; }` : ""}
  ${({$xxl, theme}) => typeof $xxl !== "undefined" ? css`${theme.breakpoints.xxl} { height: ${$xxl}; }` : ""}

  ${({$xsMax, theme}) => typeof $xsMax !== "undefined" ? css`${theme.breakpoints.xsMax} { height: ${$xsMax}; }` : ""}
  ${({$smMax, theme}) => typeof $smMax !== "undefined" ? css`${theme.breakpoints.smMax} { height: ${$smMax}; }` : ""}
  ${({$mdMax, theme}) => typeof $mdMax !== "undefined" ? css`${theme.breakpoints.mdMax} { height: ${$mdMax}; }` : ""}
  ${({$lgMax, theme}) => typeof $lgMax !== "undefined" ? css`${theme.breakpoints.lgMax} { height: ${$lgMax}; }` : ""}
  ${({$xlMax, theme}) => typeof $xlMax !== "undefined" ? css`${theme.breakpoints.xlMax} { height: ${$xlMax}; }` : ""}
`;

export default Spacer;
