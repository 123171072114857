import * as React from "react";
import {useEffect, useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Autocomplete, TextField} from "@mui/material";

import "../../scss/clinic-page.scss";

const tabList = {
    0: "港島",
    1: "九龍",
    2: "新界",
    3: "澳門",
};

function CustomTabPanel(props) {
    const {clinicList, value, index, ...other} = props;
    const [acValue, setAcValue] = useState(null);
    const [selected, setSelected] = useState(false);
    let list = clinicList.nodes;

    useEffect(() => {
        setAcValue(null);
        setSelected(false);
    }, [value]);

    const handleInputChange = (event, params) => {
        if (params.inputProps.onChange) {
            return params.inputProps.onChange(event);
        }
    };

    const filterOptions = (
        options,
        {inputValue}
    ) => {
        // Filter the options based on clinicName, address, and phoneNum
        return options.filter((option) => {
                const {clinicName, address, phoneNum} = option;

                if (!phoneNum) {
                    return (
                        clinicName.toLowerCase().includes(inputValue.toLowerCase()) ||
                        address.toLowerCase().includes(inputValue.toLowerCase())
                    );
                }

                let phone = phoneNum.toString();

                // If phoneNum is not null, search based on clinicName, address, and phoneNum
                return (
                    clinicName.toLowerCase().includes(inputValue.toLowerCase()) ||
                    address.toLowerCase().includes(inputValue.toLowerCase()) ||
                    phone.includes(inputValue.toLowerCase())
                );
            }
        );
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <div className="autocomplete-wrapper">
                <Autocomplete
                    value={acValue}
                    onChange={(event, newValue) => {
                        setAcValue(newValue);
                        if (!newValue) {
                            setSelected(false);
                        } else {
                            setSelected(true);
                        }
                    }}
                    options={list}
                    getOptionLabel={(option) => {
                        return option.clinicName;
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label={"搜尋" + tabList[index] + "醫療中心"}
                                variant="outlined"
                                onChange={(event) => {
                                    handleInputChange(event, params);
                                }}
                            />
                        );
                    }}
                    filterOptions={filterOptions}
                    componentsProps={{
                        popper: {
                            modifiers: [{
                                name: 'flip',
                                enabled: false
                            }]
                        }
                    }}
                />
            </div>

            {
                value === index ?
                    <div className="clinic-container">
                        {selected ? list.filter((clinic) => {
                                if (clinic.clinicName === acValue?.clinicName) {
                                    return clinic;
                                }
                            }).map((clinic) => {
                                return (
                                    <div className="clinic-row">
                                        <div className="clinic-name">{clinic.clinicName}</div>
                                        <a className="clinic-address" href={`https://maps.google.com/?q=${clinic.address}`}
                                           target={"_blank"}>
                                            {clinic.address}
                                        </a>
                                        <div className="clinic-phoneNum">
                                            <a href={`tel:${clinic.phoneNum}`}>
                                                {clinic.phoneNum}
                                            </a>
                                            {
                                                clinic.altNum !== " " && clinic.altNum !== null ?
                                                    <a href={`tel:${clinic.altNum}`}>
                                                        {clinic.altNum}
                                                    </a> :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                )
                            }) :
                            list.map((clinic) => {
                                return (
                                    <div className="clinic-row">
                                        <div className="clinic-name">{clinic.clinicName}</div>
                                        <a className="clinic-address"
                                           href={`https://maps.google.com/?q=${clinic.address}`} target={"_blank"}>
                                            {clinic.address}
                                        </a>
                                        <div className="clinic-phoneNum">
                                            <a href={`tel:${clinic.phoneNum}`}>
                                                {clinic.phoneNum}
                                            </a>
                                            {
                                                clinic.altNum !== " " && clinic.altNum !== null ?
                                                    <>
                                                        {" / "}
                                                        <a href={`tel:${clinic.altNum}`}>
                                                            {clinic.altNum}
                                                        </a>
                                                    </> : <></>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> :
                    <></>

            }
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const allClinicList = props.allClinicList;
    const showType = props.showType;

    const [value, setValue] = React.useState(0);
    //
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("all list: ", allClinicList);

    return (
        <div className="clinic-list-page">
            <Box sx={{width: '100%'}} id="clinic-info-box">
                <Box sx={{borderBottom: 1, borderColor: 'divider'}} id="tabs-container">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        class={`tab-container ${showType}`}
                    >
                        <Tab label="港島" {...a11yProps(0)} />
                        <Tab label="九龍" {...a11yProps(1)} />
                        <Tab label="新界" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} clinicList={allClinicList.hongkongIsland}>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} clinicList={allClinicList.kowloon}>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} clinicList={allClinicList.newTerritories}>
                </CustomTabPanel>
            </Box>
        </div>

    );
}