import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import ScrollPointComponent from "@components/ScrollPoint";
import {math} from "polished";
import styled from "styled-components";
import animationCyan from "@images/bullet-points-animation-cyan.png";
import animationYellow from "@images/bullet-points-animation-yellow.png";
import animationOrange from "@images/bullet-points-animation-orange.png";
import animationMagenta from "@images/bullet-points-animation-magenta.png";
import animationPurple from "@images/bullet-points-animation-purple.png";
import tick1 from "@images/bullet-points-animation-tick-1.png";
import tick2 from "@images/bullet-points-animation-tick-2.png";


const Root = styled.div`
  position: relative;
`;

const ScrollPoint = styled(ScrollPointComponent)`
  position: absolute;
  top: 50%;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  opacity: ${({$show}) => $show ? "1" : "0"};
  top: ${({$show}) => $show ? "0" : "3rem"};
  transition-property: opacity, top;
  transition-duration: ${({theme}) => theme.durations.basic};
  transition-timing-function: ${({theme}) => theme.easings.basic};
  transition-delay: ${({$index}) => math($index + " * 0.2s")};

`;

const ImageWrap = styled.div`
  position: relative;
  display: inline-block;
  width: ${({$bigIcons, $smallIcons}) => {
    // if ($bigIcons) { return "10rem"; }
    // if ($smallIcons) { return "5.7rem"; }
    return "7.6rem";
  }};
  height: ${({$bigIcons, $smallIcons}) => {
    // if ($bigIcons) { return "10rem"; }
    // if ($smallIcons) { return "5.7rem"; }
    return "7.6rem";
  }};
`;

const InnerImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: ${({$bigIcons, $smallIcons}) => {
    // if ($bigIcons) { return "6rem"; }
    // if ($smallIcons) { return "3rem"; }
    return "4rem";
  }};
  transform: translate(-50%, -50%);
  opacity: ${({$show}) => $show ? "1" : "0"};
  transition: opacity .6s ${({$step}) => $step * .2 + .5}s;
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({$src}) => $src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: ${({$show}) => $show ? "1" : "0"};
  transition: opacity .6s ${({$step}) => $step * .2 + .5}s;
`;

const Text = styled.div`
  flex: 1;
  margin-left: 2rem;
`;

export default function BulletPoints (props) {
  const {bigIcons, items, smallIcons, ...restProps} = props;
  const [show, setShow] = useState(false);
  const onScrollPointChange = useCallback(scrollPoint => { !show && scrollPoint < 1 && setShow(true); }, [show]);
  const onScrollToPageBottom = useCallback(() => { !show && window.setTimeout(() => setShow(true), 100); }, [show]);

  return (
    <Root {...restProps}>
      <ScrollPoint onScrollPointChange={onScrollPointChange} onScrollToPageBottom={onScrollToPageBottom} />
      {items.map(({image, text}, index) => (
        <Item key={index} $index={index} $show={show}>
          <ImageWrap $bigIcons={bigIcons} $smallIcons={smallIcons}>
            <Image $src={animationCyan} $show={show} $step={1} />
            <Image $src={animationYellow} $show={show} $step={2} />
            <Image $src={animationOrange} $show={show} $step={3} />
            <Image $src={animationMagenta} $show={show} $step={4} />
            <Image $src={animationPurple} $show={show} $step={5} />
            {!image ? <Image $src={tick1} $show={show} $step={6} /> : null}
            {!image ? <Image $src={tick2} $show={show} $step={7} /> : null}
            {image ? <InnerImage src={image} $show={show} $step={6} $bigIcons={bigIcons} $smallIcons={smallIcons} alt="image decorator" /> : null}
          </ImageWrap>
          <Text>{text}</Text>
        </Item>
      ))}
    </Root>
  );
}

const Inline = styled(BulletPoints)`
  display: inline-flex;
`;

BulletPoints.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    text: PropTypes.node.isRequired,
  })).isRequired,
  smallIcons: PropTypes.bool.isRequired,
  bigIcons: PropTypes.bool.isRequired,
};

BulletPoints.defaultProps = {
  smallIcons: false,
  bigIcons: false,
};

BulletPoints.Inline = Inline;
